import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { SessionDomain, SessionDomainTopics, TDMSWebSocketMessage, WebSocketCommunication } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import {
  DomainComponent,
  DomainDialogProperties,
} from "@tdms/frontend/modules/session-domain/components/domain/domain.component";
import { DomainActions } from "@tdms/frontend/modules/session-domain/store/action";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { DomainState } from "../store/state";

/** This service provides session domain capabilities */
@Injectable({ providedIn: "root" })
export class SessionDomainService extends Service {
  constructor(private wsService: WebSocketService, private store: Store<DomainState>, private dialog: MatDialog) {
    super();
  }

  override async onUserChanged(): Promise<void> {
    const response = await this.wsService.sendAndReceive(new TDMSWebSocketMessage(SessionDomainTopics.getAll));
    const data = SessionDomain.fromPlainArray(response.payload as any);
    this.store.dispatch(DomainActions.empty({}));
    this.store.dispatch(DomainActions.add({ data }));
  }

  /**
   * Opens a dialog that allows customizing available domains as well as customizing keywords for each domain.
   *
   * @param props Any properties to pass to the opening dialog.
   */
  openDomainDialog(props?: DomainDialogProperties) {
    this.dialog.open(DomainComponent, {
      data: props,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Opens a dialog that checks if the user wants to delete the given domain */
  openDomainDeleteDialog(domain: SessionDomain, cb?: Function) {
    const defaultDomain = SessionDomain.defaults.medical;
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Delete Session Domain",
        description: `Are you sure you want to delete this session domain? This cannot be undone! Any sessions that use this domain will be defaulted to the ${defaultDomain.name} domain.`,
        confirmButtonText: "Confirm",
        confirmButtonColor: "warn",
        confirmClickCallback: () => {
          this.delete(domain);
          cb?.call(this);
        },
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Creates a new session domain via the websocket */
  async create(name = "New Domain") {
    const domain = new SessionDomain(undefined as any, name);
    const result = await this.wsService.sendAndReceive<SessionDomain>(
      new TDMSWebSocketMessage(SessionDomainTopics.add, undefined, domain)
    );
    return SessionDomain.fromPlain(result.payload);
  }

  /** Listens for added session domains */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<SessionDomain>>(SessionDomainTopics.add)
  async domainAddReceived(data: TDMSWebSocketMessage<SessionDomain>) {
    this.store.dispatch(DomainActions.add({ data: [SessionDomain.fromPlain(data.payload)] }));
  }

  /** Updates a session domain via the websocket */
  async update(domain: SessionDomain) {
    const result = await this.wsService.sendAndReceive<SessionDomain>(
      new TDMSWebSocketMessage(SessionDomainTopics.update, undefined, domain)
    );
    return SessionDomain.fromPlain(result.payload);
  }

  /** Listens for updated session domains */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<SessionDomain>>(SessionDomainTopics.update)
  async domainUpdateReceived(data: TDMSWebSocketMessage<SessionDomain>) {
    this.store.dispatch(DomainActions.update({ data: SessionDomain.fromPlain(data.payload) }));
  }

  /** Deletes a session domain via the websocket */
  async delete(domain: SessionDomain) {
    const result = await this.wsService.sendAndReceive<SessionDomain>(
      new TDMSWebSocketMessage(SessionDomainTopics.remove, undefined, domain)
    );
    return SessionDomain.fromPlain(result.payload);
  }

  /** Listens for deleted session domains */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<SessionDomain>>(SessionDomainTopics.remove)
  async domainDeleteReceived(data: TDMSWebSocketMessage<SessionDomain>) {
    this.store.dispatch(DomainActions.delete({ data: SessionDomain.fromPlain(data.payload) }));
  }
}

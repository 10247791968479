import { Component, EventEmitter, Input, Output } from "@angular/core";

/** Defines the type for each upload step we may take */
export type UploadStep = {
  /** A pretty name for this step */
  name: string;
  /** An icon to help make this step easily identifiable */
  icon: string;
  /** Text to instead display when complete */
  completeText?: string;
  /** If you should be allowed to navigate back to the previous page during this step */
  allowPrevious?: boolean;
};

/** This component defines the stepper that can help display what we need to do for upload processing */
@Component({
  selector: "data-store-upload-stepper[steps]",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  /** The steps to render in our upload stepper */
  @Input() steps!: UploadStep[];

  /** The current step we are on */
  @Input() currentStep: number = 0;

  /** Called when next step fires */
  @Output() onNextStep = new EventEmitter<number>();

  /** Called when we decrement the step */
  @Output() onPrevStep = new EventEmitter<number>();

  /** If a back button should be displayed to navigate back to the main upload page */
  @Input() displayBack = true;

  get activeStep() {
    return this.steps[this.currentStep];
  }

  /** Backs up the current steps by 1 */
  prevStep() {
    if (this.currentStep !== 0) {
      this.currentStep--;
      this.onPrevStep.next(this.currentStep);
    }
  }

  /** Increments the current step by 1 */
  nextStep() {
    this.currentStep++;
    this.onNextStep.next(this.currentStep);
  }
}

import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { StoreModule } from "@ngrx/store";
import { ChartsModule } from "@tdms/frontend/modules/charts/charts.module";
import { KeywordModule } from "@tdms/frontend/modules/keyword/keyword.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SessionDomainModule } from "@tdms/frontend/modules/session-domain/session-domain.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { BulkDownloadProgressComponent } from "./components/bulk-download-progress/bulk-download-progress.component";
import { DataStoreDashboardComponent } from "./components/dashboard/dashboard.component";
import { AggregateFileComponent } from "./components/shared/aggregate-file-splitter/aggregate-file-component";
import { FileEditorDialogComponent } from "./components/shared/file-editor-dialog/file-editor-dialog.component";
import { QueryModule } from "./components/shared/query/query.module";
import { RoleMappingComponent } from "./components/shared/role-mapping/role-mapping.component";
import { StringSearchHelpDialogComponent } from "./components/shared/string-search-help/string-search-help.component";
import { AccessLogComponent } from "./components/tabs/access-log/access-log.component";
import { NewAccessDialogComponent } from "./components/tabs/access-log/new-access-dialog/new-access-dialog.component";
import { HomeComponent } from "./components/tabs/home/home.component";
import { RecycleBinComponent } from "./components/tabs/recycle-bin/recycle-bin.component";
import { SearchComponent } from "./components/tabs/search/search.component";
import { UploadMetadataComponent } from "./components/upload-metadata/upload-metadata.component";
import { UploaderProgressComponent } from "./components/upload-progress/uploader-progress.component";
import { BackgroundUploadComponent } from "./components/upload/background/background.component";
import { NodeComponent } from "./components/upload/file/tree/node/node.component";
import { FileTreeComponent } from "./components/upload/file/tree/tree.component";
import { FileUploaderComponent } from "./components/upload/file/upload/upload.component";
import { CheckComponent } from "./components/upload/generic/check/check.component";
import { UploadOptionComponent } from "./components/upload/generic/option/option.component";
import { StepperComponent } from "./components/upload/generic/stepper/stepper.component";
import {
  GenericUploaderComponent,
  GenericUploadStepDirective,
} from "./components/upload/generic/uploader/uploader.component";
import { UploadCreateSessionComponent } from "./components/upload/pipeline/create/create.component";
import { UploadCurrentSessionComponent } from "./components/upload/pipeline/current/current.component";
import { OneOffUploadComponent } from "./components/upload/pipeline/one-off/one-off.component";
import { UploadSelectionComponent } from "./components/upload/selection/selection.component";
import { dataStoreReducer } from "./models/store/data.store.reducer";
import { AccessLogService } from "./services/access-log.service";
import DataStoreMetricService from "./services/data.store.metrics.service";
import DataStoreService from "./services/data.store.service";
import { DownloadService } from "./services/download.service";
import { FileEditService } from "./services/edit.service";
import RecycleBinService from "./services/recycle-bin.service";
import { SearchService } from "./services/search.service";
import { UploadService } from "./services/upload.service";

/**
 * Components provided by this module
 */
const COMPONENTS = [
  AggregateFileComponent,
  FileEditorDialogComponent,
  DataStoreDashboardComponent,
  HomeComponent,
  RecycleBinComponent,
  AccessLogComponent,
  SearchComponent,
  NewAccessDialogComponent,
  RoleMappingComponent,
  UploadMetadataComponent,
  StringSearchHelpDialogComponent,
  UploaderProgressComponent,
  UploadCreateSessionComponent,
  UploadSelectionComponent,
  UploadOptionComponent,
  StepperComponent,
  FileUploaderComponent,
  FileTreeComponent,
  NodeComponent,
  GenericUploaderComponent,
  UploadCurrentSessionComponent,
  OneOffUploadComponent,
  GenericUploadStepDirective,
  CheckComponent,
  BackgroundUploadComponent,
  BulkDownloadProgressComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MetricsModule,
    ChartsModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    QueryModule,
    TextFieldModule,
    KeywordModule,
    // Apply the data store module
    StoreModule.forFeature("dataStore", dataStoreReducer),
    SessionDomainModule,
  ],
  providers: [
    DownloadService,
    UploadService,
    DataStoreService,
    DataStoreMetricService,
    RecycleBinService,
    SearchService,
    AccessLogService,
    FileEditService,
  ],
  exports: COMPONENTS,
})
export class DataStoreModule {}

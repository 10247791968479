import { createActionGroup, props } from "@ngrx/store";
import { Keyword } from "@tdms/common";

/** Supported NGRX store keyword actions */
export const KeywordActions = createActionGroup({
  source: "Keyword",
  events: {
    Add: props<{ keywords: Keyword[] }>(),
    Update: props<{ data: Keyword }>(),
    Delete: props<{ data: Keyword }>(),
    Empty: props<any>(),
  },
});

<div
  class="option mat-card"
  [ngClass]="{ disabled: !(option.enabled ?? true), clickable: clickable }"
  (click)="onClick()"
>
  <div class="info" *ngVar="iconMode(option) as iconMode">
    <!-- Icon display -->
    <mat-icon class="icon" *ngIf="iconMode === 'icon'">{{ option.icon }}</mat-icon>
    <span *ngIf="iconMode === 'number'" class="number icon">{{ option.icon }}</span>
    <!-- Actual content -->
    <div class="text">
      <span class="name">{{ option.name }}</span>
      <span class="description">{{ option.description }}</span>
      <span class="reason" *ngIf="!option.enabled && option.disabledReason">{{ option.disabledReason }}</span>
      <span class="details" *ngIf="option.details">{{ option.details }}</span>
    </div>
  </div>
  <mat-icon *ngIf="clickable">chevron_right</mat-icon>
  <ng-content></ng-content>
</div>

import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Session, UploadOption, User } from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { SupportedRouteTyping, TDMS_ROUTES } from "@tdms/frontend/modules/routing/models/config";
import { RouteHelper } from "@tdms/frontend/modules/routing/models/helper";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { SettingsDialogComponent } from "@tdms/frontend/modules/settings/components/dialog/settings.dialog.component";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { ChangelogComponent, DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import {
  ChangePasswordComponent,
  ChangePasswordDialogProps,
} from "@tdms/frontend/modules/user/components/change-password/change-password.component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { selectCurrentUser } from "@tdms/frontend/modules/user/store/user.selector";
import { RouterButtonComponent } from "./router-button/router.button.component";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent extends SubscribingComponent {
  /**
   * Our currently logged in user
   */
  currentUser!: User | undefined;

  /**
   * The current session selected
   */
  currentSession!: Session | undefined;

  /**
   * Available upload types for data store upload
   */
  uploadTypes: UploadOption[] = [];

  /** Menus for the sidebar supported by the routes */
  menus = RouteHelper.getSidenavGroups();

  constructor(
    private store: Store<SessionState>,
    private dialog: MatDialog,
    // Accessed by the template
    public userService: UserService,
    // The below are public to support access by the route configuration
    public configService: ConfigService,
    public routerService: RouterService,
    public uploadService: UploadService,
    public audioService: AudioService,
    public serviceManager: ServiceManager,
    public sessionService: SessionService,
    public notificationService: NotificationService
  ) {
    super();
    this.addSubscription(
      this.store.subscribe((state) => {
        this.currentSession = selectCurrentSession(state);
        this.uploadTypes = selectDataStoreUploadOptions(state);
      })
    );
    this.addSubscription(this.store.select(selectCurrentUser).subscribe((x) => (this.currentUser = x)));
  }

  /** Returns the router config for the upload pipeline for use with the separated upload button */
  get uploadPipelineConfig() {
    return TDMS_ROUTES.find((x) => "path" in x && x.path === Route_URLs.uploadPipeline)!;
  }

  /**
   * Opens the user settings dialog
   */
  openSettings() {
    this.dialog.open(SettingsDialogComponent, DialogWrapperComponent.getDefaultOptions());
  }

  /*
   * Opens the changelog dialog
   */
  openChangelog() {
    this.dialog.open(ChangelogComponent, DialogWrapperComponent.getDefaultOptions());
  }

  /**
   * Opens the dialog to change the current users password
   */
  openChangePassword() {
    this.dialog.open(ChangePasswordComponent, {
      data: {
        user: this.userService.currentAuthenticatedUser,
        adminMode: false,
      } as ChangePasswordDialogProps,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Requests a logout for this user
   */
  logout() {
    this.userService.logout();
  }

  /**
   * Functionality to execute when the session selection button is clicked
   */
  async sessionSelectionClick() {
    const redirected = await this.routerService.redirectTo(Route_URLs.sessionSelection);
    if (redirected) this.store.dispatch(SessionActions.select({ session: undefined }));
  }

  /**
   * Given some routes, returns if at-least one is enabled
   */
  hasAvailableRoutes(route: SupportedRouteTyping) {
    if ("configs" in route)
      return (
        route.configs.length > 0 &&
        route.configs.find(
          (x) => !(RouterButtonComponent.menuButtonIsDisabled(x, this as any) && x.sidenavOptions!.hideIfDisabled)
        ) != null
      );
    else return !RouterButtonComponent.menuButtonIsDisabled(route, this as any);
  }

  /** Returns true if this config is a section config and false if it's a singular */
  configIsSection(config: SupportedRouteTyping) {
    return "configs" in config;
  }
}

<ng-container *ngIf="!config.sidenavOptions?.hide || overrideHidden">
  <ng-container *ngVar="menuButtonIsDisabled() as buttonDisabled">
    <ng-container *ngVar="spinnerContent as spinner">
      <span
        *ngIf="!(config.sidenavOptions!.hideIfDisabled && buttonDisabled)"
        [matTooltip]="buttonDisabled ? getDisabledTooltip() : ''"
        matTooltipPosition="right"
        class="router-button"
      >
        <button
          mat-button
          class="button"
          [routerLink]="config.path != null ? config.path : undefined"
          queryParamsHandling="merge"
          [ngClass]="{ active: config.path != null ? routerService.isActiveRoute(config.path, true) : false }"
          (click)="handleClick($event)"
          [disabled]="buttonDisabled"
        >
          <mat-icon>{{ config.sidenavOptions!.icon }}</mat-icon>
          <span>{{ config.sidenavOptions!.title }}</span>
          <div>
            <mat-spinner
              *ngIf="spinner?.shouldSpin"
              [matTooltip]="spinner?.text ?? ''"
              color="accent"
              [diameter]="25"
              [mode]="'indeterminate'"
            ></mat-spinner>
          </div>
        </button>
      </span>
    </ng-container>
  </ng-container>
</ng-container>

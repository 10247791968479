import { DataStoreFile, TDMSBase, UploadOption } from "@tdms/common";
import { Type } from "class-transformer";

/**
 * A type to help clarify when we upload a file, what kind of information we will need
 */
export class TrackedFile extends TDMSBase {
  file!: File;
  @Type(() => UploadOption)
  fileType!: UploadOption;
  removable: boolean = true;
  /**
   * If this file should be counted as a new upload. Used to track the current session uploader
   *  for when it displays files that already exist.
   */
  countsAsNew = true;
  /** If this file created this session */
  createdSession: boolean = false;

  get fileDisplayName() {
    return DataStoreFile.getPreferredFileName(this.file.name);
  }
}

import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  AggregateFileRequest,
  AggregateFileResponse,
  AvailablePluginTypes,
  DataStoreFile,
  DataStoreFileUpdate,
  DataStoreTopics,
  DataStoreUploadMetadata,
  RoleMappingRequest,
  RoleMappingResponse,
  Session,
  TDMSWebSocketMessage,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import {
  AggregateFileComponent,
  AggregateFileDialogProps,
} from "@tdms/frontend/modules/data-store/components/shared/aggregate-file-splitter/aggregate-file-component";
import {
  RoleMappingComponent,
  RoleMappingDialogProps,
} from "@tdms/frontend/modules/data-store/components/shared/role-mapping/role-mapping.component";
import {
  UploadMetadataComponent,
  UploadMetadataProps,
} from "@tdms/frontend/modules/data-store/components/upload-metadata/upload-metadata.component";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { DataStoreActions } from "@tdms/frontend/modules/data-store/models/store/data.store.action";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";

/**
 * Centralized functionality for capability related to multiple data store services.
 */
@Injectable({ providedIn: "root" })
export default class DataStoreService extends Service {
  /**
   * The base path to the data download request root for the data store.
   */
  static DATA_REQUEST_ROOT = "/api/data-store/data";

  /**
   * The base path to use to upload files to the data store.
   */
  static UPLOAD_ROOT = "/api/data-store/upload";

  constructor(
    private store: Store<DataStoreState>,
    private wsService: WebSocketService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private configService: ConfigService
  ) {
    super();
  }

  override async onSessionChanged(session?: Session) {
    if (this.configService.pluginIsEnabled("DataStore")) {
      // Handle grabbing files tracked by our current session
      if (session) {
        const files = await this.getFilesForSession(session.id);
        this.store.dispatch(DataStoreActions.addFiles({ files }));
      } else this.store.dispatch(DataStoreActions.emptyFiles({}));
    }
  }

  /**
   * Attempts to guess the data store location based on current context for data store upload/download capabilities
   */
  getDataStoreEndpoint(root: "upload" | "download" | "root") {
    // No port means we should assume we are using a reverse proxy. If we are 4200, we should be in dev mode, else use the port direct.
    const guessedPort =
      window.location.port === "" ? "" : window.location.port === "4200" ? `:9000` : `:${window.location.port}`;
    const guessedHostName = window.location.hostname; // We assume the backend is on the same server as the frontend
    return `${window.location.protocol}//${guessedHostName}${guessedPort}${
      root === "upload" ? DataStoreService.UPLOAD_ROOT : root === "download" ? DataStoreService.DATA_REQUEST_ROOT : "/"
    }`;
  }

  /**
   * Listens for role mapping requests to provide the backend with role information
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<RoleMappingRequest>>(DataStoreTopics.getRoleMapping)
  protected async roleMappingRequestReceived(data: TDMSWebSocketMessage<RoleMappingRequest>) {
    // Callback function to always complete, even on cancel
    const sendResponse = (complete: boolean) => {
      // Tell the backend of the new mappings
      this.wsService.send(
        TDMSWebSocketMessage.fromRequest(
          data,
          RoleMappingResponse.fromPlain({ mappings: data.payload.mappings, complete })
        ),
        false
      );
      dialog.close();
    };
    // Open the modal for role mapping
    const dialog = this.dialog.open(RoleMappingComponent, {
      data: {
        request: data.payload,
        onComplete: sendResponse.bind(this, true),
        onCancel: sendResponse.bind(this, false),
        sessionId: data.sessionId,
      } as RoleMappingDialogProps,
    });
  }

  /**
   * Listens for role mapping requests to provide the backend with role information
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<AggregateFileRequest>>(DataStoreTopics.splitAggregateFile)
  protected async aggregateFileRequestReceived(data: TDMSWebSocketMessage<AggregateFileRequest>) {
    const sendResponse = async (complete: boolean) => {
      // Tell the backend of the selected configuration and wait for a response containing the download URL
      this.wsService.send(
        TDMSWebSocketMessage.fromRequest(
          data,
          AggregateFileResponse.fromPlain({
            selectedSheet: data.payload.selectedSheet,
            headerRow: data.payload.headerRow,
            selectedHeader: data.payload.selectedHeader,
            processAllSheets: data.payload.processAllSheets,
            complete,
          })
        ),
        false
      );
      dialog.close();
    };
    const dialog = this.dialog.open(AggregateFileComponent, {
      data: {
        request: data.payload,
        onComplete: sendResponse.bind(this, true),
        onCancel: sendResponse.bind(this, false),
      } as AggregateFileDialogProps,
    });
  }

  /** Listens for requests for role mappings and handles opening a dialog to handle it */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreUploadMetadata>>(DataStoreTopics.getMetadata)
  protected async metadataRequestReceived(data: TDMSWebSocketMessage<DataStoreUploadMetadata>) {
    const payload = DataStoreUploadMetadata.fromPlain(data.payload);
    let dialog: MatDialogRef<any>;
    // Callback function to always complete, even on cancel
    const sendResponse = (complete: boolean) => {
      this.wsService.send(
        TDMSWebSocketMessage.fromRequest(data, DataStoreUploadMetadata.fromPlain({ ...payload, complete })),
        false
      );
      dialog.close();
    };
    // If we have one option and it's a boolean, use the confirmation dialog instead
    if (
      payload.groups.length === 1 &&
      payload.groups[0].options.length === 1 &&
      payload.groups[0].options[0].type === "boolean"
    ) {
      const option = payload.groups[0].options[0];
      dialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: option.name,
          description: option.help,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "accent",
          cancelButtonColor: "warn",
          confirmClickCallback: () => {
            option.value = true;
            sendResponse(true);
          },
          cancelClickCallback: () => {
            option.value = false;
            sendResponse(true);
          },
        } as Partial<ConfirmationDialogProperties>,
        ...DialogWrapperComponent.getDefaultOptions(),
      });
    } else {
      dialog = this.dialog.open(UploadMetadataComponent, {
        data: {
          request: payload,
          onComplete: sendResponse.bind(this, true),
          onCancel: sendResponse.bind(this, false),
        } as UploadMetadataProps,
      });
    }
  }

  /**
   * Asks the backend for files related to the given session id
   */
  private async getFilesForSession(sessionId: number) {
    return DataStoreFile.fromPlainArray(
      (
        await this.wsService.sendAndReceive<DataStoreFile[]>(
          new TDMSWebSocketMessage(DataStoreTopics.getFilesForSession, sessionId)
        )
      ).payload
    );
  }

  /** Listens for new data store files so we can tell if a new audio file has been added */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreFileUpdate[]>>(DataStoreTopics.update)
  async newDataStoreFileReceived(data: TDMSWebSocketMessage<DataStoreFileUpdate[]>) {
    const currentSession = this.sessionService.currentSession;
    if (currentSession != null) {
      const files = data.payload.filter((x) => x.file.session.id === currentSession.id);
      const addFiles = DataStoreFile.fromPlainArray(files.filter((x) => x.type === "add").map((x) => x.file));
      const deleteFiles = DataStoreFile.fromPlainArray(files.filter((x) => x.type === "delete").map((x) => x.file));
      if (addFiles.length > 0) this.store.dispatch(DataStoreActions.addFiles({ files: addFiles }));
      if (deleteFiles.length > 0) this.store.dispatch(DataStoreActions.removeFiles({ files: deleteFiles }));
    }
  }

  /**
   * Ask the backend for a list of all plugins that support the data store.
   */
  async lookupAvailablePlugins(): Promise<string[]> {
    const response = await this.wsService.sendAndReceive<string[]>(
      new TDMSWebSocketMessage(DataStoreTopics.getDataStorePlugins)
    );
    return response.payload;
  }

  /**
   * Ask the backend for a map of plugins => available plugin types that exist.
   */
  async lookupAvailablePluginTypes(): Promise<{ [plugin: string]: string[] }> {
    const response = await this.wsService.sendAndReceive<AvailablePluginTypes>(
      new TDMSWebSocketMessage(DataStoreTopics.getPluginTypes)
    );
    return response.payload.pluginTypes;
  }
}

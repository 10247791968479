import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";
import { keywordAdapter } from "./keyword.reducer";
import { KeywordState } from "./keyword.state";

/** Given a state selects the keyword object */
export const selectKeywordState = createFeatureSelector<KeywordState>("keyword");

/** Returns all keywords */
export const selectAllKeywords = (state: KeywordState) => keywordAdapter.getSelectors().selectAll(state);
/** Selects all the keywords from our current state */
export const selectAllKeywordsFromState = createSelector(selectKeywordState, selectAllKeywords);

/** Selects all the keywords from state that match the given domain */
export const selectAllKeywordsForDomain = (domain: SessionDomain) =>
  createSelector(selectAllKeywordsFromState, (keywords) => keywords.filter((x) => x.domain.id === domain.id));

<div class="container">
  <shared-generic-table
    class="keywords-table"
    tableName="Keywords"
    [data]="selectedKeywords"
    [defaultSortHeader]="'group'"
    [displayedColumns]="displayedColumns"
    defaultHeaderSortDirection="asc"
    [shouldGuessPaginatorPageSize]="true"
  >
    <cell-display [columns]="['actions']">
      <ng-template #display let-element="element" let-column="column" let-value="value">
        <!-- Edit -->
        <button
          mat-button
          class="edit-button"
          matTooltip="Edit this keyword"
          matTooltipPosition="left"
          (click)="openKeywordInfoDialog(element)"
          [configDisable]="'keyword.allowEditing'"
          configDisabledStateMessage="Keyword editing is currently disabled"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <!-- Delete -->
        <button
          mat-button
          class="delete-button"
          matTooltip="Delete this keyword"
          matTooltipPosition="right"
          (click)="deleteKeyword(element)"
          [configDisable]="'keyword.allowDeleting'"
          configDisabledStateMessage="Keyword deletion is currently disabled"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
    </cell-display>

    <ng-template #buttonRow>
      <!-- Add Keyword -->
      <button
        mat-raised-button
        color="accent"
        (click)="openKeywordInfoDialog()"
        matTooltip="Adds a new keyword to this domain"
        matTooltipPosition="below"
        [configDisable]="'keyword.allowCreation'"
        configDisabledStateMessage="Keyword creation is disabled"
      >
        Add Keyword
      </button>
    </ng-template>
  </shared-generic-table>
</div>

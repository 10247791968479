import { animate, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";

/** This component is used to provide a nice animation when completing an upload with an animated check */
@Component({
  selector: "data-store-upload-check",
  templateUrl: "./check.component.html",
  styleUrls: ["./check.component.scss"],
  animations: [
    trigger("checkAnimation", [
      transition("initial => checked", [
        animate(
          "300ms ease-out",
          style({
            "stroke-dashoffset": "0px",
            "stroke-opacity": 1,
          })
        ),
      ]),
      transition("checked => initial", [
        animate(
          "300ms ease-out",
          style({
            "stroke-dashoffset": "{{ pathLength }}px",
            "stroke-opacity": 0,
          })
        ),
      ]),
    ]),
  ],
})
export class CheckComponent implements AfterViewInit {
  /** The path of the check so we can determine it's sizing for the animation */
  @ViewChild("checkPath") checkPath!: ElementRef;
  pathLength: number = 0;
  dashOffset: number = 0;
  strokeOpacity = 0; // Initially hidden

  /** How many ms before starting the animation */
  @Input() delay = 100;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.pathLength = this.checkPath.nativeElement.getTotalLength();
    this.dashOffset = this.pathLength;
    this.cdr.detectChanges();
    setTimeout(() => this.animateCheck(), this.delay);
  }

  animateCheck() {
    let currentOffset = this.pathLength;
    const interval = setInterval(() => {
      currentOffset -= 5;
      this.dashOffset = currentOffset;
      this.strokeOpacity = 1 - currentOffset / this.pathLength;

      if (currentOffset <= 0) {
        clearInterval(interval);
        this.dashOffset = 0;
        this.strokeOpacity = 1;
      }
    }, 40);
  }
}

import { AvailableMetricNamesType } from "../../plugins";

export namespace KeywordSettings {
  export const PLUGIN_NAME: AvailableMetricNamesType = "Keyword";

  /** Helper enum for keyword setting names */
  export enum Names {
    bookmarksDisplayed = "keyword_bookmarks_displayed",
  }

  /** Helper enum for keyword setting titles */
  export enum HelpfulTitles {
    bookmarksDisplayed = "Display Keyword Bookmarks",
  }

  /** Helper enum for keyword setting descriptions */
  export enum HelpfulDescriptions {
    bookmarksDisplayed = "Controls if keyword bookmarks should be rendered on time series charts on the dashboard.",
  }
}

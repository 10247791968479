import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { Observable } from "rxjs";

export interface BulkDownloadProgressProperties {
  progress: Observable<number>;
  statusMessage: string;
}

@Component({
  selector: "app-bulk-download-progress",
  templateUrl: "./bulk-download-progress.component.html",
  styleUrls: ["./bulk-download-progress.component.scss"],
})
export class BulkDownloadProgressComponent extends SubscribingComponent implements OnInit {
  currentProgress: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public properties: BulkDownloadProgressProperties) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.properties.progress.subscribe((progress) => {
        this.currentProgress = progress * 100;
      })
    );
  }

  disableDialogClosing(): void {}
}

import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";
import { SessionDomainService } from "@tdms/frontend/modules/session-domain/services/session.domain.service";
import { selectAllDomainsFromState } from "@tdms/frontend/modules/session-domain/store/selector";
import { DomainState } from "@tdms/frontend/modules/session-domain/store/state";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This component provides a generalized dropdown for selecting a session domain */
@Component({
  selector: "session-domain-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.scss"],
})
export class DomainSelectionComponent extends SubscribingComponent implements AfterViewInit {
  /** Available domains for selection of the session */
  domainOptions: SessionDomain[] = [];

  /** This form control is used for the dropdown to select available session domain */
  @Input() control = DomainSelectionComponent.getControl();

  /** Called whenever the selected session domain changes */
  @Output() onDomainChange = new EventEmitter<SessionDomain>();

  constructor(private store: Store<DomainState>, private domainService: SessionDomainService) {
    super();
    this.addSubscription(
      this.store.select(selectAllDomainsFromState).subscribe((x) => {
        this.domainOptions = x;
        this.fixNullControl();
      })
    );
  }

  ngAfterViewInit(): void {
    this.onDomainChange.next(this.control.value);
  }

  /** Resets the domain control back to the default option, if available */
  reset() {
    if (this.domainOptions.length > 0) this.control?.setValue(this.domainOptions[0] ?? SessionDomain.defaults.medical);
  }

  /** Fixes the {@link control} from containing a null value */
  fixNullControl(control = this.control) {
    if (control?.value == null) this.reset();
  }

  /** This function opens a dialog to allow you to customize session domain information */
  onCustomKeywordClick() {
    this.domainService.openDomainDialog({
      selected: (domain) => {
        if (domain) {
          this.control.setValue(domain);
          this.onDomainChange.next(this.control.value);
        }
      },
      defaultSelectedDomain: this.control.value,
    });
  }

  /** Returns a controller for session domain selection */
  static getControl(value = SessionDomain.defaults.medical, disabled = false) {
    return new FormControl<any>(
      { value, disabled },
      {
        validators: [Validators.required],
      }
    );
  }
}

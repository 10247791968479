import { TDMSBase } from "../base";

/**
 * This class specifies session domains as available to TDMS. This allows users to create their own domain references with
 *  default keywords. As domains are applied to sessions, a mapping to the supported keywords is created. This allows
 *  each session to update only the relevant session as selected.
 */
export class SessionDomain extends TDMSBase {
  /** Default available domains */
  static readonly defaults = {
    medical: new SessionDomain(1, "Medical"),
    pilot: new SessionDomain(2, "Pilot"),
  };
  /** A unique identifier for this value */
  id: number;
  /** The name of this domain */
  name: string;

  constructor(id: number, name: string) {
    super();
    this.id = id;
    this.name = name;
  }
}

import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Keyword } from "@tdms/common";
import { KeywordActions } from "./keyword.action";
import { KeywordState } from "./keyword.state";

export const keywordAdapter: EntityAdapter<Keyword> = createEntityAdapter<Keyword>({
  selectId: (type) => type.id,
});

/**
 * Reducer for **keyword** data from the NGRX Store
 */
export const keywordReducer = createReducer(
  keywordAdapter.getInitialState({} as KeywordState),
  on(KeywordActions.add, (state, data) => keywordAdapter.addMany(data.keywords, state)),
  on(KeywordActions.update, (state, data) => keywordAdapter.updateOne({ id: data.data.id, changes: data.data }, state)),
  on(KeywordActions.delete, (state, data) => keywordAdapter.removeOne(data.data.id, state)),
  on(KeywordActions.empty, (state, _data) => keywordAdapter.removeAll(state))
);

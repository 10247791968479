import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Keyword, KeywordGroup, SessionDomain } from "@tdms/common";
import { selectAllKeywordsForDomain } from "@tdms/frontend/modules/keyword/model/store/keyword.selector";
import { KeywordState } from "@tdms/frontend/modules/keyword/model/store/keyword.state";
import { KeywordService } from "@tdms/frontend/modules/keyword/services/keyword.service";
import { DomainInfoComponent } from "@tdms/frontend/modules/session-domain/components/info/info.component";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { forbiddenValuesValidator } from "@tdms/frontend/modules/shared/validator";

/** Properties for the keyword info dialog */
export interface KeywordInfoDialogProperties {
  /** If given, the dialog will act as an edit. Else it will act as a new keyword creation. */
  keyword?: Keyword;
  /** The domain this keyword info will be related to */
  domain: SessionDomain;
}

/** This component provides creation and editing capabilities for keywords in a separate dialog */
@Component({
  selector: "keyword-info",
  templateUrl: "./keyword-info.component.html",
  styleUrls: ["./keyword-info.component.scss"],
})
export class KeywordInfoComponent extends SubscribingComponent {
  /** All currently available keywords from the store */
  keywords: Keyword[] = [];
  /** This group options is the names related to the {@link KeywordGroup} */
  groupOptions: string[] = this.keywordGroups;
  /** Form for controlling inputs within the keyword info component */
  readonly form = new FormGroup({
    text: new FormControl("", [Validators.required]),
    group: new FormControl(KeywordGroup[KeywordGroup["Team Management"]], [Validators.required]),
  });

  constructor(
    private store: Store<KeywordState>,
    private keywordService: KeywordService,
    @Inject(MAT_DIALOG_DATA) public properties: KeywordInfoDialogProperties,
    public dialogRef: MatDialogRef<DomainInfoComponent>
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllKeywordsForDomain(this.properties.domain)).subscribe((x) => {
        this.keywords = x;
        const keywordsText = this.keywords.map((x) => x.text);
        this.form.controls.text.setValidators([Validators.required, forbiddenValuesValidator(keywordsText)]);
      })
    );
    if (this.properties.keyword) {
      this.form.controls.text.setValue(this.properties.keyword.text);
      this.form.controls.group.setValue(KeywordGroup[this.properties.keyword.group]);
    }
  }

  /** Returns an array of string representation for the keyword group enum */
  get keywordGroups() {
    return Object.values(KeywordGroup).filter((x) => typeof x === "string") as string[];
  }

  /** Opens a delete dialog to allow the user to delete this keyword, assuming {@link properties} as keyword defined. */
  delete() {
    this.keywordService.openKeywordDeleteDialog(this.properties.keyword!, () => this.dialogRef.close());
  }

  /** Handles determining if we need to create or update a keywords information */
  async complete() {
    if (this.form.valid) {
      const text = this.form.controls.text.value!.toLowerCase();
      const group = KeywordGroup[this.form.controls.group.value! as any] as any as KeywordGroup;
      if (this.properties.keyword) {
        // Edit
        const keyword = this.properties.keyword.clone();
        // Update with new data
        keyword.text = text;
        keyword.group = group;
        keyword.domain = this.properties.domain;
        // Send the update
        await this.keywordService.update(keyword);
      } else {
        // Create
        const keyword = Keyword.fromPlain({
          text,
          group,
          domain: this.properties.domain,
        });
        // Send the creation
        await this.keywordService.create(keyword);
      }
      this.dialogRef.close(); // close when done
    }
  }
}

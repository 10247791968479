import { Component, Input, OnInit } from "@angular/core";
import { TrackedFile } from "../../../model/tracked.file";
import { FileNode } from "./file.node";

/**
 * This component is used to display the node content of a file tree row based on given node content
 */
@Component({
  selector: "data-store-file-tree-node[node][nodeLevel]",
  templateUrl: "./node.component.html",
  styleUrls: ["./node.component.scss"],
})
export class NodeComponent implements OnInit {
  /** The current node we are rendering */
  @Input() node!: FileNode;

  /** The current node level depth for customizing displays */
  @Input() nodeLevel!: number;

  /**
   * A callback that will be fired if you try to delete a node on the tree. The
   *  delete button will only be shown if this is given.
   */
  @Input() deleteCallback: { (node: TrackedFile): void } | undefined;

  constructor() {}

  ngOnInit(): void {}
}

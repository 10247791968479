import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";
import { DomainActions } from "./action";
import { DomainState } from "./state";

export const domainAdapter: EntityAdapter<SessionDomain> = createEntityAdapter<SessionDomain>({
  selectId: (type) => type.id,
});

/**
 * Reducer for **SessionDomain** data from the NGRX Store
 */
export const domainReducer = createReducer(
  domainAdapter.getInitialState({} as DomainState),
  on(DomainActions.add, (state, data) => domainAdapter.addMany(data.data, state)),
  on(DomainActions.update, (state, data) => domainAdapter.updateOne({ id: data.data.id, changes: data.data }, state)),
  on(DomainActions.delete, (state, data) => domainAdapter.removeOne(data.data.id, state)),
  on(DomainActions.empty, (state, _data) => domainAdapter.removeAll(state))
);

import { Component, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Session, UploadResponse, Utility } from "@tdms/common";
import { UploaderOption } from "@tdms/frontend/modules/data-store/components/upload/generic/option/option.component";
import { UploadStep } from "@tdms/frontend/modules/data-store/components/upload/generic/stepper/stepper.component";
import { GenericUploaderComponent } from "@tdms/frontend/modules/data-store/components/upload/generic/uploader/uploader.component";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/upload/model/tracked.file";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { DomainSelectionComponent } from "@tdms/frontend/modules/session-domain/components/selection/selection.component";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import {
  selectAllSessionNames,
  selectAllSessionsFromState,
} from "@tdms/frontend/modules/session/store/session.selector";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { forbiddenValuesValidator, similarValueValidator } from "@tdms/frontend/modules/shared/validator";
import { formatDistance } from "date-fns";

/** This component handles creating a new session within TDMS and tracking the steps */
@Component({
  selector: "data-store-pipeline-create-session",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class UploadCreateSessionComponent extends SubscribingComponent {
  /** The uploaded component that should be rendered during the upload step */
  @ViewChild(GenericUploaderComponent) uploader: GenericUploaderComponent | undefined;
  /** This component allows us to see the currently selected domain*/
  @ViewChild(DomainSelectionComponent) domainSelection: DomainSelectionComponent | undefined;

  /** All sessions available to the frontend. Used to prevent duplicate session naming. */
  sessionNames: string[] = [];

  /** This array defines the steps we'll take in our upload process. These should be in order that they occur. */
  steps: UploadStep[] = [
    { name: "Intro", icon: "start" },
    ...GenericUploaderComponent.STEPS, // Grab steps from external option
    { name: "Complete", icon: "done_outline", allowPrevious: false },
  ];

  /** The session name to call the uploaded file, or the prefix in the event the file contains multiple sessions. */
  sessionNameControl: FormControl;

  /** Sessions that were created from the upload */
  createdSessions: { session: Session; opt: UploaderOption }[] = [];
  /** Info for how long it took to create the {@link createdSessions} */
  createdSessionDuration: string | undefined;

  /** Used to keep a reference of old selected file so if we switch it we can determine if the name needs adjusted */
  selectedFile: TrackedFile | undefined;

  constructor(private store: Store, private routerService: RouterService, private uploadService: UploadService) {
    super();
    this.addSubscription(
      this.store.select(selectAllSessionNames).subscribe((names) => {
        this.sessionNames.length = 0; // Javascript fun to empty the array
        this.sessionNames.push(...names.flatMap((x) => x.toLowerCase()));
      })
    );
    // Initialize form control for session name
    this.sessionNameControl = new FormControl("", {
      validators: [
        Validators.required,
        forbiddenValuesValidator(this.sessionNames),
        similarValueValidator(this.sessionNames, "leading"),
      ],
    });
  }

  /** Handles some functionality for when files are selected for upload. */
  onFilesSelected(files: TrackedFile[]) {
    const oldName = this.uploadService.getSessionNameFromFile(this.selectedFile);
    const newName = this.uploadService.getSessionNameFromFile(files[0]);
    const setName = this.sessionNameControl.value;
    this.selectedFile = files[0];
    // If the current name is the last file name, or empty, feel free to update
    if (setName === oldName || !setName) {
      this.sessionNameControl.setValue(newName);
      this.sessionNameControl.markAllAsTouched();
    }
  }

  /** Sets the given session and redirects to the dashboard */
  sessionClick(session: Session) {
    // This seems kind of round about, but it's best to do it this way for user interaction flow
    this.routerService.redirectTo(Route_URLs.sessionSelection);
    this.store.dispatch(SessionActions.select({ session: session }));
  }

  /** Populates the information of what sessions we created so the user can select them. */
  protected async populateCreatedSessions(result: UploadResponse[]) {
    const sessionIds = result[0].sessionIds!;
    // Find our matching sessions for selection and subscribe for changes in them
    this.addSubscription(
      this.store.select(selectAllSessionsFromState).subscribe((x) => {
        const sessions = x.filter((x) => sessionIds.includes(x.id));
        this.createdSessions = sessions.map((x, i) => {
          const description = `${Utility.getDateAsZulu(x.startDate)} to ${Utility.getDateAsZulu(x.endDate)}`;
          return {
            session: x,
            opt: { name: x.name, description: description, icon: i + 1 },
          };
        });
      })
    );
    // Set time frame information for how long it took
    this.createdSessionDuration = formatDistance(0, result[0].timeElapsed, { includeSeconds: true });
  }

  /** Handles what to do if the step goes backwards before the upload index */
  onStepBackwardsBeforeUpload() {
    // Wipe the current session name
    this.sessionNameControl.setValue("");
    this.domainSelection?.reset();
  }
}

import { Component } from "@angular/core";
import { UploaderOption } from "@tdms/frontend/modules/data-store/components/upload/generic/option/option.component";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This component is used to display background uploads that are running for TDMS uploads that you left. */
@Component({
  selector: "data-store-background-upload",
  templateUrl: "./background.component.html",
  styleUrls: ["./background.component.scss"],
})
export class BackgroundUploadComponent extends SubscribingComponent {
  /** This current uploads array utilizes the upload service to track any that are occurring for the current user. */
  currentUploads: (UploaderOption & { spinnerProgress?: number })[] = [];

  constructor(private uploadService: UploadService, private routerService: RouterService) {
    super();
    this.setupObservable();
  }

  /** Sets the observable that populates {@link currentUploads} */
  private setupObservable() {
    // Listen for changes in our available uploads
    this.addSubscription(
      this.uploadService.uploadStatus.subscribe((x) => {
        this.currentUploads = x.statuses.map((status, i) => {
          const progress =
            null || status.progress === 0 || status.progress === -1 || status.progress === 100
              ? undefined
              : status.progress;
          return {
            name: `${status.prettyNoProgressString}`,
            description: `Stage: ${status.currentStageIndex} of ${status.expectedStages.length}. Step: ${status.currentStepIndex} of ${status.stepsByStage.length}`,
            icon: i + 1,
            spinnerProgress: progress,
          };
        });
      })
    );
  }

  /** Returns the currently available statuses */
  get currentUploadCount() {
    return this.uploadService.currentlyUploadingRequests.length;
  }

  /** Returns to the uploader page */
  backToUpload() {
    this.routerService.redirectTo(Route_URLs.uploadPipeline, this.routerService.getAllQueryParams());
  }
}

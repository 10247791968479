import { Component, EventEmitter, Input, Output } from "@angular/core";

/** Defines an upload option definition for the user to interact with */
export type UploaderOption = {
  /** A user friendly name */
  name: string;
  /** A user friendly description so they know what this upload option does does */
  description: string;
  /** An icon that could be used to allow quick referencing. A number can be used if you just want to render a singular number as the icon. */
  icon: string | number;
  /** If this option should be enabled */
  enabled?: boolean;
  /** In the event the enabled status is false, this tells you why it might be disabled */
  disabledReason?: string;
  /** Extra details you want rendered in your option */
  details?: string;
};

/** This component defines upload options to display in our selection page */
@Component({
  selector: "data-store-upload-option[option]",
  templateUrl: "./option.component.html",
  styleUrls: ["./option.component.scss"],
})
export class UploadOptionComponent {
  /** This option defines the configuration on what we should display */
  @Input() option!: UploaderOption;

  /** If clicking this element should do anything. If this is set to false, {@link click} won't fire. */
  @Input() clickable = true;

  /** This event emitter is used when one of the options is clicked */
  @Output() click = new EventEmitter<UploaderOption>();

  /** Returns the type of icon display we are using. */
  iconMode(opt: UploaderOption) {
    if (typeof opt.icon === "number") return "number";
    else return "icon";
  }

  /** What to do when this option is clicked */
  onClick() {
    if (this.clickable) this.click.next(this.option);
  }
}

<div
  class="generic-step-container"
  [ngClass]="{ center: activeStepName ? centeredSteps.includes(activeStepName) : false }"
>
  <!-- Render the steps we'll need to take for this upload status-->
  <data-store-upload-stepper #stepper [steps]="steps">
    <!-- Footer. A button to navigate back to the main page -->
    <button
      mat-flat-button
      class="exit"
      color="warn"
      (click)="redirectToUpload()"
      matTooltip="Returns to the upload type selection. Provides a warning if you are in the middle of an upload."
      matTooltipPosition="right"
    >
      <mat-icon>close</mat-icon>
      <span class="text">Exit</span>
      <span></span>
    </button>
  </data-store-upload-stepper>

  <!-- Step 1: Figure out what type of data they are trying to upload -->
  <div class="file-type-step generic-step" *ngIf="activeStepName === 'File Type'">
    <div class="help">
      <h1 class="title">File Type</h1>
      <p class="description">What type of file are you uploading?</p>
    </div>
    <!-- Display our file type options to upload -->
    <div class="options">
      <data-store-upload-option
        *ngFor="let option of simpleUploadOptions"
        [option]="option"
        (click)="setFileType($event)"
      ></data-store-upload-option>
    </div>
  </div>

  <!-- Step 2: Figure out more about their data -->
  <div class="data-type-step generic-step" *ngIf="activeStepName === 'Data Type'">
    <div class="help">
      <h1 class="title">Content Info</h1>
      <p class="description">
        Now we need to know a little bit more about the file contents itself. Please select an option below the best
        aligns with your data.
      </p>
    </div>
    <!-- Display our file type options to upload -->
    <div class="options">
      <data-store-upload-option
        *ngFor="let type of specificTypes"
        [option]="type.option"
        (click)="setSpecificPluginType(type.pluginOpt)"
      ></data-store-upload-option>
    </div>
  </div>

  <!-- Step 3: Allow them to upload the file. -->
  <div class="upload-file-step generic-step" *ngIf="activeStepName === 'Upload'">
    <div class="help">
      <h1 class="title">File Upload</h1>
      <p class="description">Go ahead and upload your file using the upload icon below.</p>
    </div>

    <!-- File selector/uploader -->
    <data-store-file-uploader
      class="file-uploader"
      *ngIf="selectedUploadOption"
      [selectedUploadType]="selectedUploadOption"
      (fileUpdate)="setUploadedFiles($event)"
      [currentFiles]="uploadedFiles"
    ></data-store-file-uploader>

    <!-- Allow them to render any required data as a child after uploaded files are set. -->
    <div class="metadata" *ngIf="!hideMetadataTillFile || uploadedFiles.length !== 0">
      <ng-container *ngTemplateOutlet="uploadMetadata"></ng-container>
      <!-- Render the submit button -->
      <button
        mat-raised-button
        (click)="submitUpload()"
        color="accent"
        class="next"
        [disabled]="uploadSubmitDisabled || uploadedFiles.length === 0"
        [matTooltip]="uploadedFiles.length === 0 ? 'Please select a file for upload' : ''"
      >
        Submit
      </button>
    </div>
  </div>

  <!-- Step 4: Show the processing tracker -->
  <div class="processing-step generic-step" *ngIf="activeStepName === 'Processing'">
    <data-store-uploader-progress
      [status]="status"
      [shouldDisplayStatus]="shouldDisplayStatus"
      [filesUploading]="filesUploading"
      class="progress"
    ></data-store-uploader-progress>
  </div>

  <!-- Render the external steps based on the template -->
  <ng-container *ngIf="stepper && activeStep != null">
    <ng-container *ngFor="let step of stepTemplates">
      <div class="generic-step" *ngIf="activeStepName === step.step">
        <ng-container *ngTemplateOutlet="step.content"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

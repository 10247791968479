<div class="container">
  <!-- Left content -->
  <div class="left">
    <!-- Help info -->
    <div class="help">
      <p class="description">
        This page allows you to select the pipeline for what kind of uploading you are trying to accomplish. Each type
        has a description of what it is used for. Please select your pipeline below.
      </p>
    </div>

    <!-- Pipeline Options -->
    <div class="options">
      <data-store-upload-option
        *ngFor="let option of options"
        [option]="option"
        (click)="optionClick($event, 'options')"
      ></data-store-upload-option>
    </div>

    <!-- Single use pipeline options -->
    <mat-accordion class="accordion">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <data-store-upload-option
            [option]="{
              name: 'One Off Uploads',
              description:
                'These options are used for uploading a matching data file, letting TDMS perform some data manipulation, and immediately downloading the results.',
              icon: 'star_rate'
            }"
          ></data-store-upload-option>
        </mat-expansion-panel-header>
        <!-- Render options -->
        <div class="options">
          <data-store-upload-option
            *ngFor="let option of oneOffOptions"
            [option]="option"
            (click)="optionClick($event, 'oneOffOptions')"
          ></data-store-upload-option>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Advanced pipeline options -->
    <mat-accordion class="accordion">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <data-store-upload-option
            [option]="{
              name: 'Advanced Options',
              description: 'Advanced upload options for power users.',
              icon: 'person'
            }"
          ></data-store-upload-option>
        </mat-expansion-panel-header>
        <!-- Render all advanced options -->
        <div class="options">
          <data-store-upload-option
            *ngFor="let option of advancedOptions"
            [option]="option"
            (click)="optionClick($event, 'advancedOptions')"
          ></data-store-upload-option>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Right content -->
  <div class="right mat-card">
    <shared-img-wrapper src="../../../../../assets/upload/man-upload.png"></shared-img-wrapper>
  </div>
</div>

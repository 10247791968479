<div class="node-row" *ngIf="node.originalData">
  <!-- Node Name Row with conditional name styling -->
  <span *ngIf="nodeLevel === 1" matTooltip="{{ node.errorMessage }}" matTooltipPosition="right">
    {{ node.name }}
  </span>
  <span *ngIf="nodeLevel === 2" class="node-name-associated-file">{{ node.name }}</span>
  <!-- Session related file buttons dependent on configuration -->
  <div class="session-file-buttons">
    <!-- Info button if this file created this session content -->
    <button
      mat-icon-button
      disableRipple
      *ngIf="node.originalData.createdSession"
      matTooltip="This file created this session's data"
      matTooltipPosition="right"
      class="session-creation end-button"
    >
      <mat-icon>info</mat-icon>
    </button>

    <!-- Delete button -->
    <button
      mat-icon-button
      *ngIf="deleteCallback && node.originalData != null && node.originalData.removable"
      (click)="deleteCallback(node.originalData)"
      matTooltip="Delete File"
      matTooltipPosition="right"
      class="end-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<data-store-generic-upload
  #uploader
  [steps]="steps"
  uploadMode="session-creation"
  (uploadComplete)="populateCreatedSessions($event)"
  (onFilesSelected)="onFilesSelected($event)"
  [uploadSubmitDisabled]="!sessionNameControl.valid"
  [sessionName]="sessionNameControl.value"
  [centeredSteps]="['Intro']"
  (onStepBackwardsBeforeUpload)="onStepBackwardsBeforeUpload()"
>
  <!-- Initial Step -->
  <data-store-generic-upload-step step="Intro">
    <ng-template #content>
      <div class="help">
        <h1 class="title">Session Creation</h1>
        <shared-img-wrapper class="image" src="../../../../../assets/upload/uploadOptimized.png"></shared-img-wrapper>
        <p class="description">
          Welcome to Session Creation! This process will allow you to upload a file to create a session which allows you
          to view metrics about that data. Once complete, you'll be able to upload any additional files to that session
          as you see fit.
        </p>
        <p class="description">Please click next to continue.</p>
        <button mat-raised-button (click)="uploader.stepper.nextStep()" color="accent" class="next">Next</button>
      </div>
    </ng-template>
  </data-store-generic-upload-step>

  <!-- Complete step -->
  <data-store-generic-upload-step step="Complete">
    <ng-template #content>
      <div class="help">
        <h1 class="title">{{ createdSessions.length }} Session{{ createdSessions.length > 1 ? "s" : "" }} Created</h1>
        <p class="lower description">In {{ createdSessionDuration }}</p>
        <data-store-upload-check></data-store-upload-check>
        <p class="description">
          The created session information is below. If you'd like to upload additional files to the sessions, please
          select those sessions and navigate back to the upload menu. You can either select a session below or press
          continue to return to the upload page.
        </p>
      </div>
      <!-- Exit button -->
      <button mat-raised-button (click)="uploader.redirectToUpload()" color="accent" class="next">Continue</button>
      <!-- Session info for the user to instead select. -->
      <div class="sessions">
        <shared-div-progress-component *ngFor="let x of createdSessions" [ready]="!x.session.isProcessing">
          <data-store-upload-option [option]="x.opt" (click)="sessionClick(x.session)"></data-store-upload-option>
        </shared-div-progress-component>
      </div>
    </ng-template>
  </data-store-generic-upload-step>

  <!-- Template to render more data within the uploader under the metadata -->
  <ng-template #uploadMetadata>
    <div class="help">
      <p class="description">Feel free to adjust any additional session metadata below.</p>
    </div>
    <!-- Allow for session name selection in upload section -->
    <mat-form-field color="accent" appearance="outline" class="session-name-form-field">
      <mat-label>Session Name/Prefix</mat-label>
      <input
        [formControl]="sessionNameControl"
        matInput
        type="text"
        required
        autocomplete="off"
        (keyup.enter)="uploader.submitUpload()"
      />
      <mat-hint>
        Allows you to specify what to call this session. In the event this file contains multiple sessions, this text
        will be used to prefix the session name.
      </mat-hint>
      <div *ngIf="sessionNameControl.pending">Validating...</div>
      <mat-error *ngIf="sessionNameControl.hasError('forbiddenValue')">
        This session name is already in use.
      </mat-error>
      <mat-error *ngIf="sessionNameControl.hasError('similarValue') && !sessionNameControl.hasError('forbiddenValue')">
        A similar session exists with this prefix. You may experience session name collisions if this ends up being used
        as a prefix. Please pick a different name.
      </mat-error>
      <mat-error *ngIf="sessionNameControl.hasError('required')">Value is required</mat-error>
    </mat-form-field>
    <!-- Allow session domain selection -->
    <session-domain-selection
      class="domain-selection"
      (onDomainChange)="uploader.sessionDomain = $event"
    ></session-domain-selection>
  </ng-template>
</data-store-generic-upload>

import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { DataStoreFile } from "@tdms/common";
import { UploadStep } from "@tdms/frontend/modules/data-store/components/upload/generic/stepper/stepper.component";
import { GenericUploaderComponent } from "@tdms/frontend/modules/data-store/components/upload/generic/uploader/uploader.component";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/upload/model/tracked.file";
import {
  selectDataStoreUploadOptions,
  selectFilesForSession,
} from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { firstValueFrom } from "rxjs";

/** This component provides the ability for files to be uploaded to sessions that are already selected. This allows you to append more information after the fact. */
@Component({
  selector: "data-store-pipeline-current-session",
  templateUrl: "./current.component.html",
  styleUrls: ["./current.component.scss"],
})
export class UploadCurrentSessionComponent extends SubscribingComponent implements AfterViewInit {
  /** The uploaded component that should be rendered during the upload step */
  @ViewChild(GenericUploaderComponent) uploader: GenericUploaderComponent | undefined;

  /** This array defines the steps we'll take in our upload process. These should be in order that they occur. */
  steps: UploadStep[] = [
    { name: "Intro", icon: "start" },
    ...GenericUploaderComponent.STEPS, // Grab steps from external option
    { name: "Complete", icon: "done_outline", allowPrevious: false },
  ];

  /** Files currently uploaded to this session */
  sessionFiles: TrackedFile[] = [];

  /** Files currently in the file uploader */
  uploaderFiles: TrackedFile[] = [];

  /** Files that should be displayed within the file tree */
  displayFiles: TrackedFile[] = [];

  /** If true, we'll render a checkbox that allows the user to treat this uploaded file as a delayed creation file populate metric data */
  displayDelayedData = false;

  /** Form control used to decide if we should display delayed data toggle and it's current value */
  delayedDataControl = new FormControl({ value: false, disabled: false });

  constructor(
    private store: Store,
    private sessionService: SessionService,
    private notificationService: NotificationService
  ) {
    super();
    autoBind(this);
    // Populate the files currently associated to this session
    this.addSubscription(
      this.store.select(selectFilesForSession).subscribe(async (files) => {
        const uploadOptions = await firstValueFrom(this.store.select(selectDataStoreUploadOptions));
        this.sessionFiles = files.map((x) =>
          TrackedFile.fromPlainExtended({
            file: { name: x.fileName?.replace(DataStoreFile.COMPRESSION_EXTENSION, "") } as File,
            // Locate and use matching upload option
            fileType: uploadOptions.find((z) => z.associatedPlugin === x.matchingPlugin && z.name === x.pluginType),
            removable: false,
            countsAsNew: false,
            createdSession: x.createdSession,
          })
        );
        this.populateDisplayFiles();
      })
    );
  }

  ngAfterViewInit(): void {
    // Listen for changes in the steps
    this.addSubscription(
      this.uploader?.stepper.onNextStep.subscribe(() => {
        this.updateDelayedDataDisplay();
      })
    );
  }

  /** Populates the files to display in the file tree */
  private populateDisplayFiles() {
    this.displayFiles = [...this.uploaderFiles, ...this.sessionFiles];
    this.updateDelayedDataDisplay();
  }

  /** Returns the current selected session from the session service */
  get currentSession() {
    return this.sessionService.currentSession;
  }

  /** Used to handle deletions from the file tree to remove an uploaded file */
  onUploadedFileDelete(file: TrackedFile) {
    const matchingIndex = this.uploader!.uploadedFiles.findIndex((x) => x.fileDisplayName === file.fileDisplayName);
    if (matchingIndex !== -1) {
      this.uploader!.uploadedFiles.splice(matchingIndex, 1);
      this.uploader!.onFilesSelected.emit(this.uploader!.uploadedFiles);
    }
  }

  /** When the generic uploader has files changed this function handles checking duplicate names as well as assigning them to the class property if applicable. */
  onUploaderFileChange(files: TrackedFile[]) {
    // Filter duplicates
    const nonDuplicateFiles = [];
    for (let file of files) {
      file.removable = true; // Set files to removable so the uploader can not have to handle them
      const dupe = this.sessionFiles.find(
        (x) => x.fileDisplayName === file.fileDisplayName && x.fileType.name === file.fileType.name
      );
      if (!dupe) nonDuplicateFiles.push(file);
    }
    // Alert of duplicates if they exist
    if (nonDuplicateFiles.length !== files.length)
      this.notificationService.open("Duplicate file names detected. Ignoring duplicates.", "warning");
    this.uploader!.uploadedFiles = nonDuplicateFiles;
    this.uploaderFiles = nonDuplicateFiles;
    this.updateSessionCreatedState();
    this.populateDisplayFiles();
  }

  /** Updates the {@link displayDelayedData} property based on current options */
  updateDelayedDataDisplay() {
    this.displayDelayedData =
      // Make sure the option supports it
      this.uploader?.selectedUploadOption?.canCreateSessions! &&
      // Make sure no other files are created the session
      this.displayFiles.find((x) => x.fileType.canCreateSessions && x.createdSession && !x.countsAsNew) == null;
  }

  /** Updates the "created session" state of the first uploaded file based on the {@link delayedDataControl*/
  updateSessionCreatedState() {
    // Set created state
    if (this.uploaderFiles[0]) this.uploaderFiles[0].createdSession = this.delayedDataControl.value ?? false;
  }
}

import { createActionGroup, props } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";

/** Supported NGRX store session domain actions */
export const DomainActions = createActionGroup({
  source: "domain",
  events: {
    Add: props<{ data: SessionDomain[] }>(),
    Update: props<{ data: SessionDomain }>(),
    Delete: props<{ data: SessionDomain }>(),
    Empty: props<any>(),
  },
});

/** This class defines the base generic table column capability that can then be customized by extension classes */
export abstract class GenericTableColumn {
  /**
   * flag to indicate whether this column is backed by data or just a superfluous column added on to each row.
   */
  isDataBacked: boolean;

  /**
   * Unique internal name for the column. Used to reference the column internally, should not be diplayed to the user.
   */
  name: string;

  /**
   * The title for this column that will be displayed to the user.
   */
  title: string;

  /** If we should hide this column on the table but include it in the export only. This is only used to determine if the column can be rendered. */
  onlyShowInExport: boolean;
  /** If this should be hidden in the export of the table. This is used during export time. */
  hideFromExport: boolean = false;

  tooltip?: string;

  /** If the given column should be able to be toggled on and off for display */
  canEditColumnVisibility = true;

  constructor(
    name: string,
    title: string,
    tooltip?: string,
    isDataBacked: boolean = true,
    onlyShowInExport = false,
    canEditColumnVisibility = true
  ) {
    this.name = name;
    this.title = title;
    this.tooltip = tooltip;
    this.isDataBacked = isDataBacked;
    this.onlyShowInExport = onlyShowInExport;
    this.canEditColumnVisibility = canEditColumnVisibility;
  }

  /**
   * Given a row in the table, format and return the data that should be displayed for this cell.
   */
  abstract getDataValue(rowData: any): any;
}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  DataStoreFile,
  DataStoreTopics,
  DownloadProgress as DownloadProgressUpdate,
  DownloadRequest,
  TDMSWebSocketMessage,
  Utility,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import {
  BulkDownloadProgressComponent,
  BulkDownloadProgressProperties,
} from "@tdms/frontend/modules/data-store/components/bulk-download-progress/bulk-download-progress.component";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { format } from "date-fns";
import { filter, map, Subject } from "rxjs";
import { v4 } from "uuid";
import DataStoreService from "./data.store.service";

/**
 * A service to provide downloading capabilities from the data store
 */
@Injectable({
  providedIn: "root",
})
export class DownloadService extends Service {
  readonly _observable: Subject<DownloadProgressUpdate> = new Subject();

  constructor(
    private userService: UserService,
    private dataStoreService: DataStoreService,
    private wsService: WebSocketService,
    private dialog: MatDialog
  ) {
    super();
  }

  /**
   * Observe the download progress for a given bulk download operation.
   * @param id The id of the bulk download operation.
   * @returns An observable of progress events for that bulk download operation.
   */
  observeDownloadProgress(id: string) {
    return this._observable.pipe(
      filter((update) => {
        return update.id == id;
      })
    );
  }

  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DownloadProgressUpdate>>(
    DataStoreTopics.bulkDownloadProgress
  )
  async onDownloadOperationProgress(data: TDMSWebSocketMessage<DownloadProgressUpdate>) {
    this._observable.next(data.payload);
  }

  /**
   * Given a list of files to download and an access reason, ask the data store for a url to download them from and download.
   * @param files The files to download.
   * @param accessReason The user-provided access reason.
   * @param removeCompression Whether compression should be removed from the downloaded files.
   * @returns The file data from the download.
   */
  async bulkDownload(files: DataStoreFile[], accessReason: string, removeCompression: boolean = true) {
    const id = v4();
    let ref = this.dialog.open<BulkDownloadProgressComponent, BulkDownloadProgressProperties>(
      BulkDownloadProgressComponent,
      {
        data: {
          progress: this.observeDownloadProgress(id).pipe(map((message) => message.progress)),
          statusMessage: "Preparing files for download, please wait...",
        },
        disableClose: true,
      }
    );

    const payload = DownloadRequest.fromPlain({
      files: files,
      accessReason: accessReason,
      removeCompression: removeCompression,
      id: id,
    });

    let response;

    try {
      response = await this.wsService.sendAndReceive<string>(
        new TDMSWebSocketMessage(DataStoreTopics.bulkDownload, undefined, payload)
      );
    } catch (error) {
      ref.close();
      throw error;
    }

    ref.close();

    const downloadObservable = new Subject<number>();

    ref = this.dialog.open<BulkDownloadProgressComponent, BulkDownloadProgressProperties>(
      BulkDownloadProgressComponent,
      {
        data: {
          progress: downloadObservable,
          statusMessage: "Downloading file from server, please wait...",
        },
        disableClose: true,
      }
    );

    let blob;

    try {
      blob = await this.downloadFile(response.payload, accessReason, removeCompression, (progress) => {
        downloadObservable.next(progress);
      });
    } catch (error) {
      ref.close();
      throw error;
    }

    ref.close();
    downloadObservable.complete();

    if (blob) {
      let exportFilename;

      if (files.length > 1) {
        exportFilename = `tdms-export-${format(new Date(), "yyyy-MM-dd_HH-mm")}.zip`!;
      } else {
        exportFilename = files[0].uncompressedFileName;
      }
      FrontendUtility.saveFile("blob", blob, exportFilename);
    }
  }

  /**
   * Given some information, determines the download url for a given file
   * @param filename The file path to the file to download. This must contain session pathing information
   * @param accessReason The reason to download this file
   */
  getDownloadPathing(filename: string, accessReason: string) {
    return this.dataStoreService.getDataStoreEndpoint("download") + filename + `?reason=${accessReason}`;
  }

  /**
   * Download a given filename from the data store.
   * @param filename The filename to download.
   * @param accessReason The reason for file access.
   * @param removeCompression If compression should be removed for the download. Default is true.
   * @returns The file data as a Blob.
   */
  async downloadFile(
    filename: string,
    accessReason: string,
    removeCompression = true,
    onProgress?: (progress: number) => void
  ) {
    const response = await this.generateDownloadRequest(filename, accessReason, removeCompression);
    if (response == null) return;
    // Handle errors
    if (!response.ok) throw new Error(response.statusText);

    const reader = response.body?.getReader();

    if (!reader) throw new Error("Failed to read download file from server");

    // Get the total content length
    const contentLength = response.headers.get("Content-Length");
    const totalBytes = contentLength ? parseInt(contentLength, 10) : 0;

    const chunks: Uint8Array[] = [];
    let receivedBytes = 0;

    /**
     * We pipe the original fetch response through a readable stream in order to track download progress reactively.
     * The underlying issue we're solving here is that the browser doesn't allow you to properly pass downloads over to the browser
     * that require headers for authentication. And since grabbing the blob from the response is essentially streaming the entire file
     * into memory (or temp filesystem cache, unclear), the process will not give any progress indication unless we pipe like this and monitor.
     */
    const stream = new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            controller.close();
            break;
          }

          receivedBytes += value.length;
          chunks.push(value);
          const progress = totalBytes ? receivedBytes / totalBytes : 0;
          controller.enqueue(value);

          if (onProgress != null) {
            onProgress(progress);
          }
        }
      },
    });

    const newResponse = new Response(stream);
    const data = await newResponse.blob();

    return data;
  }

  async generateDownloadRequest(filename: string, accessReason: string, removeCompression = true) {
    //Handle user authentication. Check user's jwt local expiry time, log them out if jwt is expired.
    if (!this.userService.checkUserJwtValidation()) {
      await this.userService.logout();
      return;
    }
    // Remove compression
    if (removeCompression && filename.endsWith(DataStoreFile.COMPRESSION_EXTENSION))
      filename = filename.replace(DataStoreFile.COMPRESSION_EXTENSION, "");
    // Execute request
    return fetch(this.getDownloadPathing(filename, accessReason), {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + this.userService.getJWT(),
        "client-identifier": this.wsService.clientIdentifier,
      },
    });
  }

  /** Listens for parsed file results as they occur */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<string>>(DataStoreTopics.downloadSpecialParse)
  protected async downloadSpecialFile(data: TDMSWebSocketMessage<string>) {
    const filePath = data.payload;
    const fileName = Utility.getFileName(filePath);
    const blob = await this.downloadFile(filePath, "Special Download", false);
    if (blob) FrontendUtility.saveFile("blob", blob, fileName);
  }
}

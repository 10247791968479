/**
 * Given a data set and max number of data points to display (n),
 * show the top (n - 1) entries and aggregate entries [n...data.length] into one data point "other".
 * @param data The data to aggregate
 * @param maxDisplayed the maximum number of data points to display.
 * @returns The aggregated data list
 */
export function aggregateToMax<T extends { name: string; value: number }>(data: T[], maxDisplayed: number): T[] {
  if (data.length < maxDisplayed) return data;

  /// Sort the data inline to ensure we get the highest values.
  data.sort((a, b) => b.value - a.value);

  const displayed = data.slice(0, maxDisplayed - 1);

  const aggregate = data
    .slice(maxDisplayed - 1)
    .map((dataPoint) => dataPoint.value)
    .reduce((previous, current) => {
      return previous + current;
    });

  displayed.push({ name: "Other", value: aggregate } as T);

  return displayed;
}

import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";
import {
  DomainInfoComponent,
  DomainInfoDialogProperties,
} from "@tdms/frontend/modules/session-domain/components/info/info.component";
import { selectAllDomainsFromState } from "@tdms/frontend/modules/session-domain/store/selector";
import { DomainState } from "@tdms/frontend/modules/session-domain/store/state";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** Properties for the domain dialog */
export interface DomainDialogProperties {
  /** A domain to auto select when the dialog opens, if available based on ID. */
  defaultSelectedDomain?: SessionDomain;
  /** Called if the user uses the "select" button within the dialog. */
  selected?: { (domain?: SessionDomain): void };
}

/**
 * This component provides the ability to edit session domains, customize their associated keywords, and even create new of either sets.
 */
@Component({
  selector: "session-domain",
  templateUrl: "./domain.component.html",
  styleUrls: ["./domain.component.scss"],
})
export class DomainComponent extends SubscribingComponent {
  /** All currently available domains from the store */
  domains: SessionDomain[] = [];

  /** the currently selected domain we wish to modify the metadata of */
  selectedDomain: SessionDomain | undefined = undefined;

  constructor(
    private store: Store<DomainState>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public properties: DomainDialogProperties,
    private dialogRef: DialogRef<DomainComponent>
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllDomainsFromState).subscribe((x) => {
        this.domains = x;
        // Select default
        if (this.selectedDomain == null)
          if (this.properties.defaultSelectedDomain) {
            const matchExists = this.domains.find((x) => x.id === this.properties.defaultSelectedDomain?.id);
            if (matchExists) this.setSelectedDomain(matchExists);
          }
        // In case we didn't set one
        if (this.selectedDomain == null) this.setSelectedDomain(this.domains[0]);
      })
    );
  }

  /** Returns if the selected domain is the default. */
  get selectedIsDefault() {
    return [SessionDomain.defaults.medical.id, SessionDomain.defaults.pilot.id].includes(this.selectedDomain?.id ?? 0);
  }

  /** Sets the domain as the active domain and updates the form control name in the case of editing */
  setSelectedDomain(domain: SessionDomain) {
    this.selectedDomain = domain;
  }

  /** Called if the user uses the `select` button within the dialog to close this dialog and tell of the selected domain */
  userSelectedDomain() {
    this.properties.selected?.call(this, this.selectedDomain);
    this.dialogRef.close();
  }

  /** Open the dialog for domain info. If given a domain, opens the edit info else opens the create*/
  openDomainInfo(domain?: SessionDomain) {
    this.dialog.open(DomainInfoComponent, {
      data: {
        selectedDomain: domain,
        callback: (domain) => {
          if (domain == null) this.selectedDomain = this.domains[0];
          else this.selectedDomain = domain;
        },
      } as DomainInfoDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, NavigationStart, Params, Router } from "@angular/router";
import { Utility } from "@tdms/common";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { filter, map, Observable, Subject } from "rxjs";
import { ConfigService } from "../../settings/services/config.service";
import { Service } from "../../shared/services/base.service";
import { RouteHelper } from "../models/helper";
import { Route_URLs } from "../models/url";

/**
 * The commonly used params by this application.
 *
 * Any array params will be strings joined by `,`. You will need to parse this yourself in usage of it
 */
export enum RouterParamTypes {
  /**
   * The param name of the currently selected session
   */
  sessionID = "session",

  /**
   * The originally requested url we wanted to navigate to before redirecting to the login page
   *  during guard catching.
   */
  originalRequestURL = "originalRequestURL",

  /**
   * An array of sessions we are comparing in the session comparison page.
   */
  comparedSessions = "comparedSessions",

  /**
   * Controls the tab index of the data store management page.
   */
  dataStoreTab = "dataStoreTab",

  /** Controls the tab index of the session summary display */
  sessionSummaryTab = "sessionSummaryTab",

  /** The upload type we may be using for one off uploads. {@link OneOffUploadComponent} */
  oneOffUploadType = "oneOffUploadType",
}

/**
 * A service intended to make routing across this application easier
 */
@Injectable({
  providedIn: "root",
})
export class RouterService extends Service {
  /// This subject is emitted whenever a browser back/forward button press is detected.
  /// It is currently used by the user service to determine when the user navigates back to the login page
  /// to ensure that the current user is logged out.
  browserNavigatedSubject: Subject<NavigationStart> = new Subject();

  /** A subject that can be subscribed to for when a navigation end occurs */
  navigationEnd: Observable<NavigationEnd & { tdmsUrl: Route_URLs }>;

  constructor(private router: Router, private configService: ConfigService, private dialog: MatDialog) {
    super();

    /// code adapted from https://www.bennadel.com/blog/3533-using-router-events-to-detect-back-and-forward-browser-navigation-in-angular-7-0-4.htm
    /// listens for back/forward events using the browser buttons.
    this.router.events
      .pipe(
        /// The "events" stream contains all the navigation events, but we only care about popstate
        /// which indicates a back/forward button press.
        filter((event): event is NavigationStart => {
          return (
            event instanceof NavigationStart &&
            event.navigationTrigger != undefined &&
            event.navigationTrigger == "popstate"
          );
        })
      )
      .subscribe((event: NavigationStart) => {
        this.browserNavigatedSubject.next(event);
      });

    // Assign a navigation end observable
    this.navigationEnd = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((x) => ({ ...x, tdmsUrl: x.url.split("?")[0].substring(1) as Route_URLs }))
    );
  }

  /**
   * Returns if the given route string is active or not
   * @param startsWith If our active route should instead consider sub paths with a "start with"
   */
  isActiveRoute(route: string | Route_URLs, startsWith = false) {
    if (startsWith) return this.currentRouteBaseUrl?.startsWith(route);
    return this.currentRouteBaseUrl === route;
  }

  /**
   * Returns current route data
   *
   * Angular services can't determine routes from the activated route in constructor. So we have
   *  to do it a different way.
   */
  get currentActiveRoute() {
    let route = this.router.routerState.root;
    while (route.firstChild) route = route.firstChild;
    return route;
  }

  /**
   * Returns our current route base url
   */
  get currentRouteBaseUrl() {
    return this.currentActiveRoute.snapshot?.routeConfig?.path;
  }

  /**
   * Gets the routing config for the current route
   */
  get currentRouteConfig() {
    return RouteHelper.findConfigByRoute(this.currentRouteBaseUrl);
  }

  /**
   * Returns the base path from window instead of the router. This is helpful if you are trying to check
   *  the pathname before the angular router is actually in play
   */
  get baseUrlFromWindow() {
    return window.location.pathname.replace("/", "");
  }

  /**
   * Given some information, handles the redirect consider parent route
   */
  async redirectTo(command: Route_URLs, queryParams?: Params) {
    return await this.router.navigate([command], {
      queryParams: queryParams == null ? undefined : queryParams,
      relativeTo: this.currentActiveRoute.parent,
    });
  }

  /**
   * Formats and returns the given query param value
   */
  formatQueryParam(value: number | string | Array<number | string> | null) {
    if (Array.isArray(value)) return value.join(",");
    else return value;
  }

  /**
   * Given a param and a value sets the param to that value for the current route
   */
  async setQueryParam(param: RouterParamTypes, value: number | string | Array<number | string> | null) {
    await this.router.navigate([], {
      relativeTo: this.currentActiveRoute,
      queryParamsHandling: "merge",
      queryParams: { [param]: this.formatQueryParam(value) },
    });
  }

  /**
   * Gets our standard query param value
   */
  getQueryParam(param: RouterParamTypes, params: Params = this.queryParamsFromWindow) {
    let returnVal: string | string[] | number | number[];
    if (params.get != null) returnVal = params.get(param);
    else returnVal = (params as Params)[param];
    // Check for arrays
    if (typeof returnVal === "string" && returnVal.includes(",")) returnVal = returnVal.split(",");
    // Handle numbers
    if (Array.isArray(returnVal) && typeof returnVal[0] === "string" && !isNaN(parseInt(returnVal[0])))
      returnVal = returnVal.map((x) => parseInt(x as string));
    else {
      const parsedVal = parseInt(returnVal as string);
      if (!isNaN(parsedVal)) returnVal = parsedVal;
    }
    return returnVal as any;
  }

  /**
   * Returns all query params that should be maintained across redirects no matter what
   */
  getAllQueryParams() {
    return this.queryParamsFromWindow;
  }

  /**
   * Returns all of the current query params that we actually support from `window.location.search`
   */
  private get queryParamsFromWindow(): Params {
    const params = new URLSearchParams(window.location.search);
    const returnParams: Params = {};
    Object.keys(RouterParamTypes).map((x) => {
      const paramName = (RouterParamTypes as any)[x];
      let val: string[] | string | null = params.get(paramName);
      if (val != null) returnParams[paramName] = val;
    });
    return returnParams;
  }

  /** Returns the default redirect url when selecting a session */
  get defaultRedirectUrl() {
    return this.configService.pluginIsEnabled("DataStore") ? Route_URLs.sessionSummary : Route_URLs.dashboard;
  }

  /**
   * Redirects to the route requested by the set query params, if it can be activated.
   * @param onlyFireOnSessionSelection If this redirect considering params should only fire while on the session selection page.
   * @param fallbackToDefault Fallback to the default redirect URL for sessions {@link defaultRedirectUrl} in the event we can't pull one from query params.
   * @param shouldFallbackOnFailure If given true, will fallback to the default route {@link Route_URLs.default_route} on a failure to redirect.
   */
  async redirectConsideringParams(
    onlyFireOnSessionSelection = true,
    fallbackToDefault = false,
    shouldFallbackOnFailure = false
  ) {
    if (onlyFireOnSessionSelection && !this.isActiveRoute(Route_URLs.sessionSelection)) return;
    // Default redirect URL
    let redirectURL: Route_URLs | undefined = undefined;
    // Determine if the query params contain a different redirect URL
    const paramRedirectURL = this.getQueryParam(RouterParamTypes.originalRequestURL);
    const matchingRequestURLInternal = Utility.getEnumByValue(Route_URLs, paramRedirectURL);
    /**
     * Use original request param if:
     *  1. We have a valid redirect URL from the query param
     *  2. We verified that route exists internally (handles bad data)
     *  3. It's not a login redirect. (the user could get stuck to the login page with auto authentication from JWT)
     */
    if (paramRedirectURL != null && paramRedirectURL !== Route_URLs.login && matchingRequestURLInternal != null)
      redirectURL = (Route_URLs as any)[matchingRequestURLInternal];
    // Handle case where we have no redirect URL
    if (redirectURL == null)
      if (fallbackToDefault) redirectURL = this.defaultRedirectUrl;
      else return;
    // Remove query param for routing as we have attempted to handle it
    await this.setQueryParam(RouterParamTypes.originalRequestURL, null);
    const currentQueryParams = this.getAllQueryParams();
    const redirectResult = await this.redirectTo(redirectURL, currentQueryParams);
    // Unsuccessful, check if we should fallback and do so
    if (!redirectResult && shouldFallbackOnFailure) await this.redirectTo(Route_URLs.default_route, currentQueryParams);
  }

  /**
   * This helper function is used to control deactivation confirmations during route switching. It can be used for
   *  things like preventing leaving a page if changes are still being made. It also handles base cases where you may
   *  be getting logged out in which will not prompt the deactivation helper popup. Returns true to allow re routing, false to prevent it.
   *
   * You'll want to use this in the routing config like the following:
   *
   * ```ts
   *  {
   *    canDeactivate: [(component: MyComponent) => component.canDeactivate()],
   *  }
   * ```
   *
   * And within your canDeactivate (that is used to allow you to wrap the helper) you'll want to do something like the following:
   *
   * ```ts
   *  return await this.routerService.canDeactivateHelper(description, newRoute);
   * ```
   * @param description What to say in the exit popup
   * @param newRoute The new {@link Route_URLs} that we're redirecting to.
   */
  async canDeactivateHelper(description: string, newRoute: string): Promise<boolean> {
    // Replace any leading /
    newRoute = newRoute.replace("/", "");
    // Base check, in the event we are being forced out of this page, don't even ask the user
    if ([Route_URLs.login].includes(newRoute.slice(0, newRoute.indexOf("?")) as any)) return true;
    try {
      await new Promise<void>((res, rej) => {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            description,
            confirmButtonText: "Exit",
            header: "Exit Confirmation",
            confirmClickCallback: res,
            cancelClickCallback: () => {
              dialog.close();
              rej();
            },
          } as Partial<ConfirmationDialogProperties>,
          ...DialogWrapperComponent.getDefaultOptions(),
        });
      });
      // If the promise resolves (user his exit), then return true to allow the deactivation.
      return true;
    } catch {
      return false;
    }
  }
}

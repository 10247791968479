import { Type } from "class-transformer";
import { v4 as uuidv4 } from "uuid";
import { TDMSBase } from "./base";
import { AvailableMetricNamesType, AvailablePluginNamesType } from "./plugins";
import { AudioConfig } from "./plugins/audio/config";
import { DataStoreConfig } from "./plugins/data-store";
import { SessionSummaryConfig } from "./plugins/session-summary/config";

/**
 * The type casting of configuration registration values
 */
export type ConfigMetadata = {
  /**
   * The comment to print in the yml file above this config value. You can use newlines (\n) and they will automatically print multi line comments.
   */
  comment?: string;
  /**
   * If this should be able to be externally controlled from either env variables or the config file.
   */
  externalControlDisabled?: boolean;

  /**
   * If given, enforces that any configuration value loaded must be one of the options in this array. If it is not, it will grab the default value and
   *  write that back out as a replacement.
   */
  enforcedValues?: any[] | readonly any[];
};

/**
 * A class to help apply metadata to configuration fields for the backend to use in it's file. These same
 *  checks could be used in the frontend as well.
 */
export class ConfigurationMetadata {
  /**
   * The metadata key for the external configuration registration
   */
  static readonly METADATA_KEY = "config:register";

  /**
   * Sets metadata for the configuration field for additional information.
   */
  static set(config: ConfigMetadata) {
    return (target: any, propertyKey: string) => {
      Reflect.defineMetadata(ConfigurationMetadata.METADATA_KEY, config, target, propertyKey);
    };
  }
}

/**
 * This class helps centralize configuration capabilities for data processing
 */
export class DataProcessingConfiguration {
  /**
   * How many milliseconds is in-between each "isSpeaking" capture
   */
  static SPEAKER_SEPARATION_SPACING = 250;

  /**
   * How often we should allow chart updates to occur when updating the domains. In milliseconds.
   */
  static CHART_DOMAIN_DEBOUNCE_TIME = 100;
}

/**
 * Generic configuration data that will be utilized for multiple projects
 */
export class Configuration extends DataProcessingConfiguration {
  /**
   * This is the unique identifier of this client
   */
  static readonly APP_ID = uuidv4();
}

/**
 * This configuration helps define what the demo mode config will look like
 */
export class DemoModeConfig {
  /** Leading text to prefix every demo session */
  static DEMO_NAME_LEADING_TEXt = "Demo Session";

  /** If demo mode is enabled */
  enabled: boolean;

  @ConfigurationMetadata.set({ comment: "If Comm Step-On's should be intentionally inserted into demo data." })
  shouldInsertStepOns: boolean;

  /**
   * The default password to use for the demo mode
   */
  readonly defaultPassword = "demo";

  /**
   * The default username to use for demo mode
   */
  readonly defaultUsername = "demo";

  constructor(enabled: boolean = true, shouldInsertStepOns = true) {
    this.enabled = enabled;
    this.shouldInsertStepOns = shouldInsertStepOns;
  }
}

/** This class provides generic editing configurations related to a specific functionality. This will include config for adding, updating, and deleting. */
export class GenericEditingConfig extends TDMSBase {
  /** If we should allow creation */
  allowCreation = true;
  /** If we should allow editing*/
  allowEditing = true;
  /** If we should allow deletion*/
  allowDeleting = true;

  constructor(allowCreation: boolean = false, allowEditing = false, allowDeleting = false) {
    super();
    this.allowCreation = allowCreation;
    this.allowEditing = allowEditing;
    this.allowDeleting = allowDeleting;
  }
}

/** Configuration options specific to session information */
export class SessionConfig extends TDMSBase {
  /** If sessions should be allowed to be created from scratch */
  allowSessionCreationFromScratch: boolean;
  /** If sessions can be edited */
  allowEditing: boolean;
  /** If metric data can be regenerated */
  allowRegeneration: boolean;
  /** If role mapping data can be edited when editing a session*/
  allowRoleMapEditing: boolean;

  constructor(
    allowSessionCreationFromScratch: boolean = false,
    allowEditing = false,
    allowRegeneration = false,
    allowRoleMapEditing = false
  ) {
    super();
    this.allowSessionCreationFromScratch = allowSessionCreationFromScratch;
    this.allowEditing = allowEditing;
    this.allowRegeneration = allowRegeneration;
    this.allowRoleMapEditing = allowRoleMapEditing;
  }
}

/** Configuration options specific to tag information */
export class TagConfig extends GenericEditingConfig {}

/** Configuration options specific to session domain information */
export class SessionDomainConfig extends GenericEditingConfig {}

/** Configuration options specific to keyword information */
export class KeywordConfig extends GenericEditingConfig {}

/** Config options related to users. */
export class UserConfig extends TDMSBase {
  allowCreation: boolean;
  allowEditing: boolean;
  allowPasswordChange: boolean;

  constructor(allowCreation = false, allowEditing = false, allowPasswordChange = false) {
    super();
    this.allowCreation = allowCreation;
    this.allowEditing = allowEditing;
    this.allowPasswordChange = allowPasswordChange;
  }
}

/** Configuration options specific to bookmark information */
export class BookmarkConfig extends TDMSBase {
  /** If we should allow bookmarks to be created. This will not override if a chart doesn't support them. */
  allowCreation = true;
  /** If we should allow bookmarks information to be edited */
  allowEditing = true;
  /** If we should allow bookmarks to be deleted */
  allowDeleting = true;

  // Type data
  /** If we should allow bookmark types to be created */
  allowTypeCreation = true;
  /** If we should allow bookmark type information to be edited */
  allowTypeEditing = true;
  /** If we should allow bookmark types to be deleted */
  allowTypeDeleting = true;

  constructor(
    allowCreation: boolean = false,
    allowEditing = false,
    allowDeleting = false,
    allowTypeCreation = false,
    allowTypeEditing = false,
    allowTypeDeleting = false
  ) {
    super();
    this.allowCreation = allowCreation;
    this.allowEditing = allowEditing;
    this.allowDeleting = allowDeleting;
    this.allowTypeCreation = allowTypeCreation;
    this.allowTypeEditing = allowTypeEditing;
    this.allowTypeDeleting = allowTypeDeleting;
  }
}

/**
 * A class that supplies configuration data to endpoints
 */
export class ConfigMessage extends TDMSBase {
  /**
   * This status message contains information on each plugin/metric in the backend for
   *  common functionality. You can use this to check things like what plugins are currently enabled.
   */
  @Type(() => Map)
  pluginStatus = new Map<
    AvailablePluginNamesType | AvailableMetricNamesType,
    { enabled: boolean; isMetric: boolean }
  >();

  demoMode: DemoModeConfig;
  /** Data store configuration with default values */
  dataStore: DataStoreConfig = DataStoreConfig.fromPlain({});
  /** Session summary options from that plugin */
  sessionSummary: SessionSummaryConfig = SessionSummaryConfig.fromPlain({});
  /** Audio related config */
  audio: AudioConfig = AudioConfig.fromPlain({});
  /** Tracks if this is a developmental build */
  isDevBuild: boolean;
  /** Configuration options related to core session capabilities */
  session = new SessionConfig();
  /** Configuration options related to core tag capabilities */
  tag = new TagConfig();
  /** Configuration options related to the user controller of core */
  user = new UserConfig();
  /** Configuration options related to core bookmark capabilities */
  bookmark = new BookmarkConfig();
  /** Configuration options related to session domain information. */
  sessionDomain = new SessionDomainConfig();
  /** Configuration options related to keyword information./ */
  keyword = new KeywordConfig();

  constructor(demoMode: DemoModeConfig, isDevBuild: boolean) {
    super();
    this.demoMode = demoMode;
    this.isDevBuild = isDevBuild;
  }

  /** Given an array of plugins with some information, builds out the {@link pluginStatus} map. */
  addPluginStatus(
    plugins: { name: string; config: ConfigMessage["pluginStatus"] extends Map<any, infer V> ? V : never }[]
  ) {
    for (let plugin of plugins) this.pluginStatus.set(plugin.name as any, plugin.config);
  }
}

import { TDMSBase } from "../../base";
import { AudioFilterModels } from "./filter.models";
import { Transcription, TranscriptionModelOptionsType } from "./transcription";
import { VADParameters } from "./vad";

/** The base class that audio analysis requests support. */
export class AudioAnalyzeRequestBase extends TDMSBase {}

/** This class defines a request for analyzing a transcript that was generated elsewhere. */
export class AudioAnalyzeTranscriptRequest extends AudioAnalyzeRequestBase {
  /** The transcription you want to perform an analysis of */
  transcription!: Transcription[];
}

/**
 * A class used to define the request to the audio microservice for analyzing audio files
 */
export class AudioAnalyzeRequest extends AudioAnalyzeRequestBase {
  /**
   * These references provide linking so we know what file name is associated to what identifier. Used for
   *  multi track file processing to better align content as it's created.
   */
  fileReferences: {
    /** Should be the original name of the file */
    name: string;
    /** Should be the identifier of this file that makes it unique from the other files */
    identifier?: string;
  }[] = [];

  /** The model to utilize during the request */
  transcriptionModel: TranscriptionModelOptionsType = "medium.en";

  /** How many speakers are in the file for diarization. Helps tune the accuracy. */
  diarizeSpeakerCount: number = 3;

  /** If we should send this request to the diarization engine */
  diarize: boolean = false;

  /** If we should send this request to the transcription engine */
  transcribe: boolean = false;

  /** If set to true along side `transcribe`, this will allow us to perform an analysis on the results of a transcription for more metadata. */
  analyze: boolean = true;

  /** If we should send this request to the vad engine */
  vad: boolean = false;

  /** If this request should ignore the cache on the microservice and force a regeneration */
  bypassCache: boolean = false;

  /** The paramaters to control the VAD model invocation (default if empty). */
  vadParams: VADParameters = new VADParameters();
}

/** This class defines the structure of a request to filter the audio file. In the event this is successful, the same object will be returned with the new URL. */
export class AudioFilter extends TDMSBase {
  /* Location of the audio file to be adjusted. This should be relative to the cache. */
  audioFilePath!: string;

  /** Audio filter model selected */
  filterNeuralNet: AudioFilterModels = AudioFilterModels.STD;

  /** This is the location/url to the data store's http server of the audio file that has been successfully filtered, if set. If not set, we will fall back to the original path. */
  url?: string;
}

/** Class that defines a request to refresh transcription data. Same class will be used for returns. */
export class TranscriptionRefresh extends TDMSBase {
  audioFileId!: number;
}

/** Class that defines a request to edit transcription data. Same class will be used for the response */
export class TranscriptionEdit extends TDMSBase {
  transcriptions!: Transcription[];
}

<shared-dialog-wrapper
  class="keyword-create-dialog"
  [header]="properties.keyword == null ? 'New Keyword' : 'Edit Keyword'"
>
  <h1 class="instructions">Please input your keyword information below and click the submit button</h1>

  <form [formGroup]="form" (submit)="complete()" class="form">
    <!-- Text -->
    <mat-form-field appearance="outline" color="accent" class="input">
      <mat-label>Keyword</mat-label>
      <input matInput placeholder="New Keyword" formControlName="text" />
      <mat-hint>The keyword(s) will be used to identify specific content within a sessions transcript.</mat-hint>
      <mat-error *ngIf="form.controls.text.hasError('forbiddenValue')"> This keyword is already in use.</mat-error>
      <mat-error *ngIf="form.controls.text.hasError('required')">Keyword is required</mat-error>
    </mat-form-field>

    <!-- Group -->
    <shared-improved-select
      class="input"
      [control]="$any(form.controls.group)"
      [dropdownOptions]="groupOptions"
      dropdownName="Keyword Group"
      [filterEnabled]="true"
      [paginatorEnabled]="true"
      [clearable]="false"
      [shouldCleanName]="false"
      [allowMultipleSelections]="false"
      hint="The grouping that this keyword applies to for further metric refinement."
    >
    </shared-improved-select>
  </form>

  <div class="buttons">
    <div>
      <button
        *ngIf="properties.keyword != null"
        class="delete"
        mat-raised-button
        color="warn"
        (click)="this.delete()"
        matTooltip="Requests to delete this keyword"
        matTooltipPosition="above"
        [configDisable]="'keyword.allowDeleting'"
        configDisabledStateMessage="Keyword deletion is disabled"
      >
        Delete
      </button>
    </div>
    <div>
      <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        [matTooltip]="form.valid ? '' : 'Please make sure all fields are filled out and valid'"
        matTooltipPosition="below"
        (click)="complete()"
      >
        {{ properties.keyword == null ? "Create" : "Save" }}
      </button>
    </div>
  </div>
</shared-dialog-wrapper>

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { UserService } from "../services/user.service";

/**
 * This guard requires that a user is authenticated before they may
 *  proceed to using the application.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private routerService: RouterService) {}
  /**
   * Handles determining if a user can activate a route
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const hasCurrentUser = this.userService.currentAuthenticatedUser != null;
    const jwtIsValid = this.userService.checkUserJwtValidation();
    const isAuthenticated = hasCurrentUser && jwtIsValid;
    // Grab original query params
    const originalRequestURL: string | undefined = route.url?.join("/");
    const queryParams = this.routerService.getAllQueryParams();
    // If not authenticated
    if (!isAuthenticated) {
      // Set original request param
      if (originalRequestURL) queryParams[RouterParamTypes.originalRequestURL] = originalRequestURL;
      // If the JWT is not valid and we have a current user, that means it's expired. Log them out
      if (!jwtIsValid && hasCurrentUser) this.userService.logout();
      // Else redirect to login with query params because this assumes we don't have a current user as base case
      else this.routerService.redirectTo(Route_URLs.login, queryParams);
    }
    return isAuthenticated;
  }
}

<shared-dialog-wrapper header="Session Domain">
  <div class="container">
    <div class="description">
      This component allows you to select session domains and edit their associated keywords. On the left you can select
      your domain to edit or create a new one. On the right you can see keywords in that domain, remove, or add new
      ones.
      <br /><br />
      <strong>Any edits to the keywords in a domain will affect any session that is using that domain.</strong>
    </div>
    <div class="content">
      <div class="column domains">
        <div
          *ngFor="let domain of domains"
          class="domain"
          [ngClass]="{ active: selectedDomain?.id === domain.id }"
          (click)="setSelectedDomain(domain)"
        >
          <span>{{ domain.name }}</span>
        </div>
      </div>
      <div class="column info">
        <!-- Render the keyword editing and selection -->
        <keyword-list [domain]="selectedDomain"></keyword-list>
      </div>
    </div>
    <!-- Any buttons we may need -->
    <div class="buttons">
      <div>
        <button
          mat-raised-button
          color="accent"
          class="create domain"
          (click)="openDomainInfo()"
          [configDisable]="'sessionDomain.allowCreation'"
          configDisabledStateMessage="Session domain creation is disabled"
          matTooltip="Creates a new domain for you to customize the keywords of"
          matTooltipPosition="left"
        >
          Create Domain
        </button>
        <!-- Editing for the actual session domain info -->
        <button
          class="edit"
          mat-raised-button
          color="primary"
          (click)="openDomainInfo(selectedDomain)"
          [disabled]="selectedIsDefault"
          [matTooltip]="selectedIsDefault ? 'You cannot edit the default domain' : 'Opens a dialog to edit this domain'"
          matTooltipPosition="right"
          [configDisable]="'sessionDomain.allowEditing'"
          configDisabledStateMessage="Session domain editing is disabled"
        >
          Edit Domain
        </button>
      </div>
      <div>
        <!-- Select this for the dropdown -->
        <button
          class="select"
          mat-raised-button
          color="accent"
          (click)="userSelectedDomain()"
          matTooltip="Selects this domain for our current session"
          matTooltipPosition="left"
        >
          Select Domain
        </button>
      </div>
    </div>
  </div>
</shared-dialog-wrapper>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { KeywordInfoComponent } from "./component/keyword-info/keyword-info.component";
import { KeywordListComponent } from "./component/keyword-list/keyword-list.component";
import { keywordReducer } from "./model/store/keyword.reducer";
import { KeywordService } from "./services/keyword.service";

/** Components provided by this module */
const COMPONENTS = [KeywordListComponent, KeywordInfoComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, SharedModule, AngularMaterialModule, StoreModule.forFeature("keyword", keywordReducer)],
  providers: [KeywordService],
  exports: COMPONENTS,
})
export class KeywordModule {}

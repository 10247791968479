<svg viewBox="0 0 48 48">
  <circle cx="24" cy="24" r="20" stroke-dasharray="40, 1" />
  <path
    #checkPath
    [attr.stroke-dashoffset]="dashOffset"
    [attr.stroke-opacity]="strokeOpacity"
    d="M11 24 l10 10 l16 -20"
    stroke-dasharray="48"
    stroke-dashoffset="48"
  />
</svg>

import { HttpClientModule } from "@angular/common/http";
import { ENVIRONMENT_INITIALIZER, NgModule } from "@angular/core";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { AudioModule } from "@tdms/frontend/modules/audio/audio.module";
import { BookmarkModule } from "@tdms/frontend/modules/bookmark/bookmark.module";
import { ChartsModule } from "@tdms/frontend/modules/charts/charts.module";
import { CommunicationModule } from "@tdms/frontend/modules/communication/communication.module";
import { DataStoreModule } from "@tdms/frontend/modules/data-store/data-store.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { NotificationModule } from "@tdms/frontend/modules/notification/notification.module";
import { AppRoutingModule } from "@tdms/frontend/modules/routing/routing.module";
import { SessionSummaryModule } from "@tdms/frontend/modules/session-summary/session.summary.module";
import { SessionModule } from "@tdms/frontend/modules/session/session.module";
import { sessionReducer } from "@tdms/frontend/modules/session/store/session.reducer";
import { SettingsModule } from "@tdms/frontend/modules/settings/settings.module";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { TagModule } from "@tdms/frontend/modules/tag/tag.module";
import { userReducer } from "@tdms/frontend/modules/user/store/user.reducer";
import { UserModule } from "@tdms/frontend/modules/user/user.module";
import { AppComponent } from "./app.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    RouterModule,
    ChartsModule,
    SharedModule,
    MetricsModule,
    DataStoreModule,
    UserModule,
    StoreModule.forRoot({
      // The follow reducers unfortunately need combined into core as they are used across multiple modules
      user: userReducer,
      session: sessionReducer,
    }),
    HttpClientModule,
    DataStoreModule,
    AudioModule,
    SessionSummaryModule,
    SessionModule,
    TagModule,
    BookmarkModule,
    SettingsModule,
    CommunicationModule,
    NotificationModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT_INITIALIZER,
      // Make sure the items in the useFactory parameters here are in the same order as the deps list below.
      useFactory: (serviceManager: ServiceManager) => () => {
        // Initialize all services using the service manager.
        serviceManager.initialize();
      },
      deps: [ServiceManager],
      multi: true,
    },
    {
      // Fixes chrome accessibility. See: https://github.com/angular/components/issues/30187
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { autoFocus: "dialog", restoreFocus: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Directive } from "@angular/core";
import { Transcription } from "@tdms/common";
import { Axis, CanvasHelperBase } from "@tdms/frontend/modules/metrics/components/timeline/canvas/canvas-helper";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import autoBind from "auto-bind";

export interface TimeDomain {
  start: Date;
  end: Date;
}

/**
 * This is a little display wrapper for our canvas computations to contain the data for a transcription to display on the canvas.
 * We compute both an x and y offset to draw the text at (top left), and the actual text to display.
 * We store the text here instead of referencing the transcription object directly because the text may get sliced up
 * if it starts before the beginning of the canvas safe space.
 * We only need to do this for the canvas start because there is safe space.
 */
export interface ComputedTranscriptionData {
  offsetX: number;
  textToDisplay: string;
}

@Directive({})
export class TextSyncedCanvasHelper extends CanvasHelperBase {
  private transcriptions!: Transcription[][];
  private domain!: TimeDomain;
  charactersOnScreen!: number;
  charactersPerSecond!: number;
  letterWidth!: number;

  maxTimeOnScreen!: number;
  private currentVisibleStart!: Date;
  private currentVisibleEnd!: Date;

  constructor(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    super(canvas, context);
    autoBind(this);
  }

  initialize(
    theme: TDMSTheme,
    transcriptions: Transcription[][],
    domain: TimeDomain,
    canvasWidth: number,
    xAxis: Axis,
    yAxis: Axis,
    longestXLabel: string
  ) {
    this.context.font = "16px monospace";
    this.domain = domain;
    this.transcriptions = transcriptions;

    this.reset();
    this.setupCanvasTheme(theme);
    this.setupCanvasSizing(canvasWidth, xAxis, yAxis, longestXLabel);
    this.computeTextMetrics();
  }

  draw() {
    this.reset();
    const filtered = this.filterVisibleTranscriptions();

    for (let row = 0; row < filtered.length; row++) {
      for (const transcription of filtered[row]) {
        this.renderTranscription(row, transcription);
      }
    }

    /// Clear out the horizontal safe space area, to prevent transcription text overlapping the role label space.
    /// Because of how we calculate the offset and slice the text string up, this should never happen,
    /// but it's a good safeguard.
    this.context.clearRect(0, 0, this.safeSpace.horizontal, this.canvas.height);
  }

  /**
   * Render a single visible transcription on the canvas.
   * The row is tied to the speaker label, and created by iterating through our transcription 2d array.
   * The first dimension is the speaker label, and the second dimension is the text content.
   */
  renderTranscription(row: number, transcription: Transcription) {
    const transcriptionData = this.computeTranscriptionMetrics(transcription);
    this.verticalCenterTextAt(transcriptionData.offsetX, this.computeYOffsetForRow(row), {
      text: transcriptionData.textToDisplay,
    });
  }

  /**
   * Compute several important metrics wrt how much transcript data we will display on screen and where to place it.
   * First, we measure the width of the letter W in the current font, which is *generally* the widest character.
   * Then, using that and the width of the canvas layer (considering safe space),
   * we determine how many letters we can safely fit on screen.
   * We also look at the current transcription data and compute an average CPS (characters per second) based on how many letters they say in N seconds.
   * Using these two metrics (characters on screen and characters per second), we determine the maximum amount of *time* that should be displayed on screen.
   * That then sets up our visible domain that will be used to filter down the transcription data to be displayed.
   * We will also use these metrics to compute the x offsets for a given transcription's data when rendering it on screen.
   * SHORTCOMINGS: There are a few shortcomings to this higher-level approach to estimating text positioning on screen.
   * 1) Averaging characters per second across all speakers and all utterances creates inaccuracies in the actual positioning of individual utterances.
   *  For instance,
   */
  computeTextMetrics() {
    this.context.font = "16px monospace";

    this.letterWidth = this.context.measureText("W").width;
    this.charactersOnScreen = Math.trunc((this.canvas.width - this.safeSpace.horizontal) / this.letterWidth);
    this.charactersPerSecond = this.averageCPS();
    this.maxTimeOnScreen = this.charactersOnScreen / this.charactersPerSecond;
    this.resetToBeginning();
  }

  /**
   * Compute the average cps of all our transcription data.
   * We use this metric to determine a (somewhat) reasonable amount of time to display on the canvas.
   * @returns The average cps.
   */
  averageCPS() {
    if (this.transcriptions.length <= 0) return 0;
    const cps = this.transcriptions.map((row) => row.map((t) => t.text.length / (t.end - t.start)));
    return (
      cps
        .map((row) => {
          /// Average CPS for each individual speaker.
          return row.reduce((a, b) => {
            /// Filter out any zero/negative values to prevent negative transcription paging which will break the playback indicator and transcription placement.
            if (a <= 0) return b;
            if (b <= 0) return a;
            return (a + b) / 2;
          });
        })
        /// Average up each speaker's average CPS.
        .reduce((a, b) => (a + b) / 2)
    );
  }

  /**
   * Filter our transcriptions that do not fit our currently visible time window, includes partial matches.
   * @returns The visible transcriptions.
   */
  filterVisibleTranscriptions() {
    return this.transcriptions.map((row) =>
      row
        .filter((t) => t != null)
        .filter((t) => {
          // Algorithm to find overlap between two different time ranges.
          // x1 <= y2 && y1 <= x2
          const x1: number = this.domain.start.getTime() + t.start * 1000;
          const x2: number = this.domain.start.getTime() + t.end * 1000;

          const y1 = this.currentVisibleStart.getTime();
          const y2 = this.currentVisibleEnd.getTime();
          return x1 <= y2 && y1 <= x2;
        })
    );
  }

  /**
   * Reset the pagination back to the beginning of the domain.
   */
  resetToBeginning() {
    this.currentVisibleStart = this.domain.start;
    this.computeVisibleEnd();
    this.draw();
  }

  /**
   * Seek to a page using the given start date/time.
   */
  seekToPage(page: number) {
    this.seekToDate(new Date(page * this.maxTimeOnScreen * 1000 + this.domain.start.getTime()));
  }

  seekToDate(start: Date) {
    this.currentVisibleStart = start;
    this.computeVisibleEnd();
    this.draw();
  }

  /**
   * Goto the next visible page using our current endpoint as the new start.
   * Metrics like CPS and max time on screen are not recomputed as a result of this function.
   */
  gotoNextPage() {
    this.currentVisibleStart = this.currentVisibleEnd;
    this.computeVisibleEnd();
    this.draw();
  }

  /**
   * Recompute our visible end metric with respect to the current start and max time on screen.
   */
  computeVisibleEnd() {
    this.currentVisibleEnd = new Date(this.currentVisibleStart.getTime() + this.maxTimeOnScreen * 1000);
  }

  /**
   * Update our current *available* time domain.
   * Available time domain is different from visible, and should be the entire available data set space.
   * @param domain The new available time domain.
   */
  updateDomain(domain: Partial<TimeDomain>) {
    this.domain = { ...this.domain, ...domain };
  }

  /**
   * Update our available complete transcription data.
   * Available transcription data is different from visible transcriptions and should contain all transcriptions
   * that can ever be rendered during playback.
   * @param transcriptions The transcriptions.
   */
  updateTranscriptions(transcriptions: Transcription[][]) {
    this.transcriptions = transcriptions;
    this.draw();
  }

  /**
   * Compute a date object for a given X offset.
   * Used by the transcription playback component to draw x axis labels given an x offset by the grid helper.
   * @param offset The x axis offset to compute time for.
   * @returns The compute date/time object based on our current visible start and end times.
   */
  computeDateForX(offset: number) {
    const percentage = offset / this.safeWidth;
    const start = this.currentVisibleStart.getTime();
    const end = this.currentVisibleEnd.getTime();
    const timeOffset = (end - start) * percentage;
    return new Date(this.currentVisibleStart.getTime() + timeOffset);
  }

  /**
   * Given a transcription, compute the x offset and do any additional text slicing depending on safe area/clipping.
   * @param transcription The transcription to display.
   * @returns The computed x offset and sliced display text.
   */
  computeTranscriptionMetrics(transcription: Transcription): ComputedTranscriptionData {
    const tStartMs = transcription.start * 1000;
    const start = this.currentVisibleStart.getTime();

    let text = transcription.text;
    let timestamp = this.domain.start.getTime() + tStartMs;

    if (timestamp < start) {
      /// This happens when the transcription starts before our currently visible data, but
      /// ends *after* the start point.
      /// Because we have a y axis with speaker labels, there is space we can't render in but
      /// partial transcription text shouldn't be lost if some of it is still visible.
      /// We compute the Characters per second of the current transcription, then use that to determine
      /// How much of the text we should slice off and how much to display.
      // const cps = Math.round(transcription.text.length / (transcription.end - transcription.start));
      const secondsMissing = Math.round((start - timestamp) / 1000);
      const charsHidden = this.charactersPerSecond * secondsMissing;
      text = transcription.text.substring(charsHidden);
      timestamp = start;
    }

    const end = this.currentVisibleEnd.getTime();
    const percentage = (timestamp - start) / (end - start);
    const offset = percentage * this.safeWidth;

    /// This code computes the delta between where the text "should"
    /// have stopped (based on individual cps and text length) and where it actually stopped (based on average cps).
    // const endTimestamp = this.domain.start.getTime() + transcription.end * 1000;
    // const endPercentage = (endTimestamp - start) / (end - start);

    // const expectedEndOffset = endPercentage * this.safeWidth;

    // const textDimensions = this.context.measureText(text);
    // const actualEndOffset = offset + textDimensions.width;

    // const delta = actualEndOffset - expectedEndOffset;
    // }

    return {
      offsetX: offset + this.safeSpace.horizontal,
      textToDisplay: text,
    };
  }
}

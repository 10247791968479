import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CustomTypes } from "@tdms/common";
import { UploaderOption } from "@tdms/frontend/modules/data-store/components/upload/generic/option/option.component";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionInfoComponent } from "@tdms/frontend/modules/session/components/info/info.component";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This component specifies the upload selection display so the user can choose what type of pipeline they want to use. */
@Component({
  selector: "data-store-upload-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.scss"],
})
export class UploadSelectionComponent extends SubscribingComponent {
  /** This array contains pipeline options that can be used by the user to handle different data uploads into TDMS */
  options: UploaderOption[] = [
    {
      name: "Create a new session",
      description: "Upload training session data and create a new analytics dashboard for that session.",
      icon: "add",
    },
    {
      name: "Upload to the current session",
      description: "Upload additional file types to the current selected session displayed on the sidebar.",
      icon: "publish",
      enabled: false, // Session must be defined for this to be available
      disabledReason: "Please select a session to use this option.",
    },
    {
      name: "View Active Uploads",
      description: "View any background session creations that are currently running.",
      icon: "upload",
    },
  ];

  /** These options are one off selections that allows the user to upload content to just do some quick data processing that they'll immediately download. */
  oneOffOptions: UploaderOption[] = [];

  /** Similar to {@link options} but for more advanced options that the normal user probably won't use */
  advancedOptions: UploaderOption[] = [
    {
      name: "Create session from scratch",
      description: "Allows you to create an empty session with no data associated to it.",
      icon: "post_add",
    },
  ];

  constructor(
    private store: Store,
    private routerService: RouterService,
    private dialog: MatDialog,
    private uploadService: UploadService
  ) {
    super();
    this.addSubscription(
      this.store.select(selectCurrentSession).subscribe((session) => {
        // Update option
        this.options[1].enabled = session != null;
        this.options[1].details = session ? `Current Session: ${session.name}` : "";
      })
    );
    // Grab all special upload types that are only used for one off processing
    this.addSubscription(
      this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
        this.oneOffOptions = options
          .filter((x) => x.usedOnlyForParsing)
          .map((x) => ({
            name: x.friendlyName ?? x.name,
            description: x.description ?? "",
            icon: x.icon,
          }));
      })
    );
    // Listen to how many background uploads are occurring
    this.addSubscription(
      this.uploadService.uploadStatus.subscribe((x) => {
        const len = x.statuses.length;
        this.options[2].details = len > 0 ? `${len} upload${len > 1 ? "s" : ""} currently in progress` : "";
      })
    );
  }

  /** Fired whenever any option is selected */
  async optionClick(
    option: UploaderOption,
    source: CustomTypes.PropertyNames<UploadSelectionComponent, UploaderOption[]>
  ) {
    const params = this.routerService.getAllQueryParams();
    // Handle the dynamic one off uploads
    if (source === "oneOffOptions") {
      this.routerService.redirectTo(Route_URLs.uploadOneOff, {
        ...params,
        [RouterParamTypes.oneOffUploadType]: option.name,
      });
    } else {
      // Handle the hard codes
      if (option.name === this.options[0].name) this.routerService.redirectTo(Route_URLs.uploadCreate, params);
      else if (option.name === this.options[1].name) this.routerService.redirectTo(Route_URLs.uploadCurrent, params);
      else if (option.name === this.options[2].name)
        this.routerService.redirectTo(Route_URLs.backgroundUploads, params);
      else if (option.name === this.advancedOptions[0].name) this.openCreateScratchSession();
    }
  }

  /** Callback to fire when we wish to create a new session from scratch. We use a dialog for this one to re-use the input handler */
  openCreateScratchSession() {
    this.dialog.closeAll();
    this.dialog.open(SessionInfoComponent, DialogWrapperComponent.getDefaultOptions());
  }
}

import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Bookmark, BookmarkType, Session } from "@tdms/common";
import {
  BookmarkTypeDialogProperties,
  BookmarkTypeInfoComponent,
} from "@tdms/frontend/modules/bookmark/components/bookmark-type-info/bookmark-type-info.component";
import { BookmarkService } from "@tdms/frontend/modules/bookmark/services/bookmark.service";
import { BookmarkTypeState } from "@tdms/frontend/modules/bookmark/store/bookmark.state";
import { selectAllBookmarkTypesFromState } from "@tdms/frontend/modules/bookmark/store/bookmark.type.selector";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DateTimePickerComponent,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { cloneDeep } from "lodash-es";

/**
 * A type to help enforce what type of move mode we are requesting
 */
export type BookmarkMoveModes = "moveAll" | "moveStart" | "moveEnd" | "moveSingular";

/**
 * A type to help enforce data passed to this dialog
 */
export type BookmarkDialogProperties = {
  /**
   * A function to call when we wish to start drawing a new bookmark
   */
  newBookmark: { (bookmark: Bookmark, isRange: boolean): Bookmark };

  /**
   * A function to call when we wish to start moving a bookmark
   * @param rangeMode The type of movement we wish to occur when handling range bookmarks. This should be undefined for singular bookmarks.
   */
  bookmarkMoveStart: { (bookmark: Bookmark, rangeMode?: BookmarkMoveModes): Bookmark };

  /**
   * A bookmark we wish to edit instead of creating a new one
   */
  existingBookmark?: Bookmark;

  /** Controls if this open should override the editable state. Defaults to true which allows other checks to takeover. */
  editable?: boolean;

  /** Controls if this open should override the deletable state. Defaults to true which allows other checks to takeover. */
  deletable?: boolean;
};

@Component({
  selector: "dialog-bookmark",
  templateUrl: "./bookmark-dialog.component.html",
  styleUrls: ["./bookmark-dialog.component.scss"],
})
export class BookmarkDialogComponent extends SubscribingComponent {
  /**
   * Bookmark creation form
   */
  readonly bookmarkForm: FormGroup;

  /**
   * Current list of available bookmark types
   */
  bookmarkTypes: BookmarkType[] = [];

  /** Current session so we know what to restrict the bookmark times between */
  protected currentSession: Session | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: BookmarkDialogProperties,
    private bookmarkDialogRef: MatDialogRef<any>,
    private configService: ConfigService,
    private bookmarkService: BookmarkService,
    private store: Store<BookmarkTypeState>,
    private dialog: MatDialog
  ) {
    super();
    // Create the form group
    const dateValidators = this.isCreationDialog
      ? []
      : [DateTimePickerComponent.dateDifferenceValidator, DateTimePickerComponent.dateLessThanValidator];
    this.bookmarkForm = new FormGroup({
      bookmarkTypeSelection: new FormControl<BookmarkType>(undefined as any, [Validators.required]),
      note: new FormControl("", []),
      startDate: new FormControl<Date | undefined>(undefined, dateValidators),
      endDate: new FormControl<Date | undefined>(undefined, dateValidators),
      isCritical: new FormControl(false),
    });
    // Grab some data from the store to keep updated
    this.addSubscription(
      this.store.subscribe((state) => {
        // Get types, only display the ones creatable by the user
        this.bookmarkTypes = BookmarkType.fromPlainArray(selectAllBookmarkTypesFromState(state)).filter(
          (x) => x.canBeDrawnByUser
        );
        this.currentSession = selectCurrentSession(state);
        // If we don't have a type selected, set one
        if (this.bookmarkForm.controls.bookmarkTypeSelection.value == null && this.bookmarkTypes?.length !== 0)
          this.bookmarkForm.controls.bookmarkTypeSelection.setValue(this.bookmarkTypes[0]);
        // If we don't have any bookmark types yet still have one selected, deselect it
        if (this.bookmarkTypes.length === 0 && this.bookmarkForm.controls.bookmarkTypeSelection.value != null)
          this.bookmarkForm.controls.bookmarkTypeSelection.setValue(undefined);
      })
    );
    // Set default properties, if given
    if (this.properties.existingBookmark) {
      this.bookmarkForm.controls.bookmarkTypeSelection.setValue(this.properties.existingBookmark.bookmarkType);
      if (this.properties.existingBookmark.metadata?.transcription) {
        this.bookmarkForm.controls.note.setValue(
          this.properties.existingBookmark.note +
            "\n\nTranscription: \n" +
            this.formatMetadata(this.properties.existingBookmark.metadata, ["speaker", "text"])
        );
      } else {
        this.bookmarkForm.controls.note.setValue(this.properties.existingBookmark.note);
      }

      this.bookmarkForm.controls.startDate.setValue(cloneDeep(this.properties.existingBookmark.startTime));
      this.bookmarkForm.controls.isCritical.setValue(this.properties.existingBookmark.critical);
      this.bookmarkForm.controls.endDate.setValue(cloneDeep(this.properties.existingBookmark.endTime));
      // Disable the form if need be  based on options
      if (
        !this.properties.existingBookmark.bookmarkType.canBeDrawnByUser ||
        !this.configService.configData?.bookmark.allowEditing ||
        !(this.properties.editable ?? true)
      )
        this.bookmarkForm.disable();
    }
  }

  /**
   * Traverses through each item in metadata and calls a string formatter to make it look nice in the dialog.
   */
  private formatMetadata(metadata: any, fields: string[]): string {
    let formattedString = "";

    if (metadata.transcription) {
      metadata.transcription.forEach((item: any) => {
        formattedString += this.formatObject(item, fields);
      });
    }

    if (!metadata.hasTranscriptionA || !metadata.hasTranscriptionB) {
      formattedString +=
        "\nOur transcription service was unable to transcribe speech data for one or more of the speakers in this step-on. It is common for automated transcription services to incorrectly transcribe data when two or more speakers talk at the same time.";
    }

    return formattedString;
  }

  /**
   * Takes each value in metadata and formats it.
   */
  private formatObject(obj: any, fields: string[]): string {
    let formattedString = "";
    fields.forEach((field, index) => {
      if (obj.hasOwnProperty(field)) {
        const value = obj[field];
        if (index === 0) {
          // If the value is "speaker" or the first value formatting is slightly different
          formattedString += `${value}: `;
        } else {
          formattedString += `${value}\n`;
        }
      }
    });
    return formattedString;
  }

  /**
   * Returns if this is a range bookmark or not
   */
  get isRangeBookmark() {
    return this.bookmarkForm.controls.endDate.value != null;
  }

  /**
   * Returns the bookmark types we can actually select
   */
  get selectableBookmarkTypes() {
    return this.bookmarkForm.disabled ? this.bookmarkTypes : this.bookmarkTypes.filter((x) => x.canBeDrawnByUser);
  }

  /**
   * Returns our dialog title
   */
  get dialogTitle() {
    return this.properties.existingBookmark == null ? "Bookmark Creation" : "Bookmark Editor";
  }

  /**
   * Returns if this dialog should be considered creation or editing
   */
  get isCreationDialog() {
    return this.properties.existingBookmark == null;
  }

  /**
   * Returns the type of the selected bookmark type in the form
   */
  get currentlySelectedBookmarkType(): BookmarkType | undefined {
    return this.bookmarkTypes.find((x) => x.id === this.bookmarkForm.controls.bookmarkTypeSelection.value?.id);
  }

  /**
   * Returns if the bookmark has had changes made to allow a save
   */
  get bookmarkHasChanges() {
    if (!this.properties.existingBookmark) return false;
    return (
      this.bookmarkForm.controls.note.value !== this.properties.existingBookmark.note ||
      this.bookmarkForm.controls.bookmarkTypeSelection.value?.id !== this.properties.existingBookmark.bookmarkType.id ||
      this.bookmarkForm.controls.isCritical.value !== this.properties.existingBookmark.critical ||
      this.bookmarkForm.controls.startDate.value?.getTime() !== this.properties.existingBookmark.startTime.getTime() ||
      this.bookmarkForm.controls.endDate.value?.getTime() !== this.properties.existingBookmark.endTime?.getTime()
    );
  }

  /**
   * Returns the bookmark considering user inputs
   */
  get finalizedBookmark() {
    return Bookmark.fromPlain({
      ...this.properties.existingBookmark,
      note: this.bookmarkForm.controls.note.value || "",
      bookmarkType: this.currentlySelectedBookmarkType,
      critical: this.bookmarkForm.controls.isCritical.value!,
      startTime: this.bookmarkForm.controls.startDate.value!,
      endTime: this.bookmarkForm.controls.endDate.value!,
    });
  }

  /**
   * Handles creating the bookmark creation request
   */
  createBookmark(isRange = false) {
    const partialBookmark = Bookmark.fromPlain({
      bookmarkType: this.currentlySelectedBookmarkType,
      note: this.bookmarkForm.controls.note.value!,
      critical: this.bookmarkForm.controls.isCritical.value!,
    });
    this.bookmarkDialogRef.close();
    // Emit the partial bookmark to start drawing
    this.properties.newBookmark(partialBookmark, isRange);
  }

  /**
   * Handles the delete callback for the requested bookmark
   */
  deleteBookmark() {
    this.bookmarkDialogRef.close();
    // Open a confirmation dialog
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        confirmClickCallback: () => this.bookmarkService.deleteBookmark(this.properties.existingBookmark!),
        // Cancel should just re open this dialog
        cancelClickCallback: () =>
          this.dialog.open(BookmarkDialogComponent, {
            data: {
              newBookmark: this.properties.newBookmark,
              bookmarkMoveStart: this.properties.bookmarkMoveStart,
              existingBookmark: this.properties.existingBookmark,
            } as BookmarkDialogProperties,
            ...DialogWrapperComponent.getDefaultOptions(),
          }),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Fires the necessary functions to start moving where the bookmark is positioned
   */
  startMove(rangeMode: BookmarkMoveModes) {
    this.bookmarkDialogRef.close();
    this.properties.bookmarkMoveStart(this.finalizedBookmark, rangeMode);
  }

  /**
   * Saves the bookmark changes
   */
  saveBookmark() {
    this.bookmarkService.updateBookmark(this.finalizedBookmark);
    this.bookmarkDialogRef.close();
  }

  get maxBookmarkTypeLength() {
    return BookmarkType.MAX_NAME_LENGTH;
  }

  /** Same as {@link openInfo} for creation but allows you to specify the name */
  openInfoCreateWithNewBookmarkType(name: string | undefined) {
    this.openInfo(BookmarkType.fromPlain({ name: name ?? "New Bookmark Type" }), "create");
  }

  /** Opens a dialog for us to create or edit a bookmark type within */
  openInfo(data: BookmarkType, type: "create" | "edit" = "create") {
    this.dialog.open(BookmarkTypeInfoComponent, {
      data: {
        data,
        totalData: this.bookmarkTypes,
        type: type,
        onComplete: (returnData) => {
          this.bookmarkForm.controls.bookmarkTypeSelection.setValue(returnData);
        },
        onDelete: (deletedItem) => {
          // If this is not a new bookmark creation...
          if (this.properties.existingBookmark != null) {
            // Make sure that this is our currently selected bookmark type and close the ref if it is
            if (deletedItem.id === this.currentlySelectedBookmarkType?.id) {
              // If we currently have a change in state, reset back to original type
              if (this.bookmarkHasChanges)
                this.bookmarkForm.controls.bookmarkTypeSelection.setValue(
                  this.properties.existingBookmark.bookmarkType
                );
              // Else, close the ref as they deleted their type
              else this.bookmarkDialogRef.close();
            }
          } else {
            // Else, if this is the currently selected bookmark type, change it to one that exists
            if (deletedItem.id === this.currentlySelectedBookmarkType?.id)
              this.bookmarkForm.controls.bookmarkTypeSelection.setValue(this.bookmarkTypes[0]);
          }
        },
      } as BookmarkTypeDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}

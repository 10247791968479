import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { UploadOption } from "@tdms/common";
import { UploadStep } from "@tdms/frontend/modules/data-store/components/upload/generic/stepper/stepper.component";
import { GenericUploaderComponent } from "@tdms/frontend/modules/data-store/components/upload/generic/uploader/uploader.component";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { filter, firstValueFrom } from "rxjs";

/** This component supports a one off upload to allow our user to quickly process a file as necessary */
@Component({
  selector: "data-store-pipeline-one-off",
  templateUrl: "./one-off.component.html",
  styleUrls: ["./one-off.component.scss"],
})
export class OneOffUploadComponent implements OnInit {
  /** The uploaded component that should be rendered during the upload step */
  @ViewChild(GenericUploaderComponent) uploader: GenericUploaderComponent | undefined;

  constructor(private store: Store, private routerService: RouterService) {}

  /** This array defines the steps we'll take in our upload process. These should be in order that they occur. */
  steps: UploadStep[] = [
    { name: "Intro", icon: "start" },
    // Grab steps from external option
    GenericUploaderComponent.STEPS[2],
    GenericUploaderComponent.STEPS[3],
    { name: "Complete", icon: "done_outline", allowPrevious: false },
  ];

  /** This is the selected option we want this one off to fire as */
  selectedOption: UploadOption | undefined;

  async ngOnInit(): Promise<void> {
    // Grab the matching type
    const options = await firstValueFrom(
      this.store.select(selectDataStoreUploadOptions).pipe(filter((x) => x.length > 0))
    );
    const param = await this.routerService.getQueryParam(RouterParamTypes.oneOffUploadType);
    const option = options.find((x) => x.name === param || x.friendlyName === param);
    this.selectedOption = option;
    // No option? Redirect home
    if (!option) this.routerService.redirectTo(Route_URLs.uploadPipeline);
  }
}

<div
  class="uploader-progress-wrapper"
  [ngClass]="{ 'status-displayed': shouldDisplayStatus, advanced: shouldRenderAdvanced }"
>
  <div class="uploader-progress-wrapper-inner" [ngClass]="{ 'status-displayed': shouldDisplayStatus }">
    <!-- Render the advanced progress display -->
    <div *ngIf="shouldDisplayStatus && shouldRenderAdvanced && status" class="advanced-display">
      <div class="header-content">
        <div class="header">
          <span>{{ processingText }}</span>
        </div>
        <div class="header extra-content" *ngIf="status.sourceLocation">Remote Server: {{ status.sourceLocation }}</div>
        <div class="header extra-content">{{ status.extraContent }}</div>
        <div class="total-time-remaining" *ngIf="totalTimeRemaining">
          <mat-icon>schedule</mat-icon>{{ totalTimeRemaining }} remaining
        </div>
      </div>

      <!-- Display how many stages we are planning -->
      <div class="stages">
        <ng-container *ngFor="let stage of status.expectedStages; index as i">
          <!-- Render the stage indicator -->
          <div class="stage">
            <span class="title">{{ stage }}</span>
            <span
              *ngVar="stageStatus.get(stage) as isComplete"
              class="circle"
              [ngClass]="{ complete: isComplete, 'in-progress': status.currentStageIndex === i }"
            >
              <mat-icon *ngIf="isComplete">check</mat-icon></span
            >
          </div>
          <!-- Render the line between the stages -->
          <div *ngIf="i !== status.expectedStages.length - 1" class="connecting-line"></div>
        </ng-container>
      </div>
      <!-- Display the current spinner progress as well as the text -->
      <div class="spinner">
        <mat-spinner
          class="spinner"
          [diameter]="360"
          [mode]="spinnerProgress == null ? 'indeterminate' : 'determinate'"
          [value]="spinnerProgress"
        ></mat-spinner>
        <div class="spinner-text">
          <span>{{ spinnerText }}</span>
          <div class="step-remaining-time" *ngIf="remainingStageTime">
            <mat-icon>schedule</mat-icon>{{ remainingStageTime }} remaining
          </div>
        </div>
      </div>

      <!-- Display steps for current stage -->
      <div class="steps">
        <ng-container *ngFor="let step of status.stepsByStage; index as i">
          <div
            class="step"
            [ngClass]="{
              complete: stepStatus.get(status.stage + '-' + step),
              'in-progress': status.currentStepIndex === i
            }"
          >
            {{ step }}
          </div>
        </ng-container>
      </div>
    </div>

    <!-- No advanced display? Display something simple then -->
    <shared-div-progress-component
      *ngIf="shouldDisplayStatus && !shouldRenderAdvanced"
      [ready]="false"
      [notReadyMessage]="spinnerText"
    ></shared-div-progress-component>
  </div>
  <!-- Render the child content from the original capability -->
  <div class="content content-wrapper" [ngClass]="{ 'status-displayed': shouldDisplayStatus }">
    <ng-content></ng-content>
  </div>
</div>

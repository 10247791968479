/**
 * An enum of router URL's that have components assigned to them and can be navigated to.
 */
export enum Route_URLs {
  login = "login",
  sessionSummary = "session-summary",
  dashboard = "dashboard",
  sessionSelection = "session-selection",
  sessionComparison = "session-comparison",
  communication = "communication",
  userManagement = "user-control",
  //// Data store pages
  dataManagement = "data-management",
  /** The pipeline selection for what we are trying to do */
  uploadPipeline = "uploader",
  /** Pipeline for creating a new session */
  uploadCreate = "uploader/create",
  /** Pipeline for uploading files to an existing session */
  uploadCurrent = "uploader/current",
  /** Pipeline for uploading a file that will be manipulated and re-downloaded by TDMS. */
  uploadOneOff = "uploader/one/off",
  /** A display for any uploads running in the background */
  backgroundUploads = "uploader/background",
  /** The default route to always redirect back to */
  default_route = "session-selection",
}

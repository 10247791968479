import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DataStoreFile, UploadOption, Utility } from "@tdms/common";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { TrackedFile } from "../../model/tracked.file";

/** This component provides the file selection and uploader display. */
@Component({
  selector: "data-store-file-uploader[selectedUploadType]",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
})
export class FileUploaderComponent implements OnInit {
  /** HTML reference to the hidden file input element so we can reset it if need be */
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement> | undefined;
  /** Our currently selected files. */
  @Input() currentFiles: TrackedFile[] = [];
  /** Our currently selected upload type that we can pull upload type information out of. */
  @Input() selectedUploadType!: UploadOption;
  /** An emitter that fires when the selected files are updated. Will always send an array. */
  @Output() fileUpdate = new EventEmitter<TrackedFile[]>();
  /** If the files in `currentFiles` should be displayed in the input box as an indicator of what files we've uploaded. */
  @Input() displayPreviouslyUploadedFiles = true;
  /** If supported file types should be displayed */
  @Input() displaySupportedFileTypes = true;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  get supportedFileTypes() {
    return this.selectedUploadType.types;
  }

  /** Performs some validation checking against the given files */
  private validateFileList(files: FileList | File[]): { continueProcessing: boolean; files?: File[] } {
    // Make sure our files are an array
    files = Array.from(files);
    const defaultReturn = { continueProcessing: true, files: files };
    // Handle checking for duplicate file names. Don't forget to consider plugins
    const nonDuplicateFiles = files.filter(
      (x) =>
        this.currentFiles.find((z) => {
          const globalFileName = z.fileDisplayName;
          const currentFileName = DataStoreFile.getPreferredFileName(x.name);
          return globalFileName === currentFileName && z.fileType.equals(this.selectedUploadType);
        }) == null
    );
    // If duplicates exist, remove them
    if (files.length !== nonDuplicateFiles.length) {
      files = nonDuplicateFiles;
      this.notificationService.open("You cannot upload files with the same name. Filtering duplicates.", "warning");
      defaultReturn.files = nonDuplicateFiles;
    }
    // Cases where we can't have more than one file
    if (!this.selectedUploadType?.canContainMoreThanOne) {
      // One already exists
      const existing = this.currentFiles.findIndex((x) => x.fileType.equals(this.selectedUploadType));
      if (existing !== -1) {
        this.notificationService.open("You cannot upload any more of this type. Replacing original.", "warning");
        this.currentFiles.splice(existing, 1);
        return { continueProcessing: true, files: [files[0]] };
      }
      // We are attempting to upload two at the same time
      else if (files.length > 1) {
        this.notificationService.open(
          "This upload source can only support one file of this type. Grabbing the first one selected.",
          "warning"
        );
        return { continueProcessing: true, files: [files[0]] };
      } else return defaultReturn;
    } else return defaultReturn;
  }

  /** Handles whenever we have a new file set in the hidden file input */
  fileChange(event: Event & { target: { files: FileList | File[]; value: any } }) {
    let files: FileList | File[] = event.target?.files;
    const validationResult = this.validateFileList(files);
    // Only process if allowed
    if (validationResult.continueProcessing) {
      for (let i = 0; i < validationResult.files!.length; i++) {
        const selectedFile = validationResult.files![i];
        if (selectedFile != null) {
          let ext = Utility.getFileExtensionFromString(selectedFile.name);
          if (ext != null) {
            // Validate this is not a duplicate
            if (this.currentFiles.find((x) => x.file.name === selectedFile.name && x.file.size === selectedFile.size))
              return;
            if (DataStoreFile.verifyFileExtension(selectedFile.name, this.supportedFileTypes)) {
              const trackedFile = TrackedFile.fromPlain({
                fileType: this.selectedUploadType!,
                removable: true,
              });
              trackedFile.file = selectedFile;
              this.currentFiles.push(trackedFile);
              this.fileUpdate.next(this.currentFiles);
            } else this.notificationService.open("That file type is not supported", "error");
          }
        }
      }
    }
    // Reset hidden input content so it knows when data actually changes
    if (this.fileInput) this.fileInput.nativeElement.value = "";
  }

  /** Returns if we can select multiple files for this upload. */
  get allowMultiple() {
    return this.selectedUploadType.canContainMoreThanOne;
  }

  /** Get's the text to display in the input box */
  get inputDisplay() {
    return this.currentFiles.length === 0 || !this.displayPreviouslyUploadedFiles
      ? this.allowMultiple
        ? "Upload Files..."
        : "Upload File..."
      : this.currentFiles.map((x) => x.file.name).join(", ");
  }
}

<shared-dialog-wrapper
  class="role-mapping-wrapper"
  header="Aggregate File Splitter"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="aggregate-file-splitter-container">
    <h1 class="instructions">Configure how to split the file: {{properties.request.fileName}}</h1>

    <!-- sheet dropdown -->
    <div class="section">
      <h2>1. Select Sheet</h2>
      <shared-improved-select
        (selectedValueChanged)="handleSheetSelection($event || '')"
        [dropdownOptions]="getSheetNames()"
        dropdownName="Sheets"
        [clearable]="false"
        [shouldCleanName]="false"
        [allowMultipleSelections]="false"
      ></shared-improved-select>
    </div>

    <!-- preview and header row selection -->
    <div class="section" *ngIf="selectedSheet">
      <h2>2. Select Header Row</h2>
      <h6>
        This value defaults to the first row in excel file. If the header is further down the sheet (ie row 10) adjust
        the value until the header is clickable
      </h6>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Header row start</mat-label>
        <input
          matInput
          type="number"
          class="example-right-align"
          [(ngModel)]="headerRowStart"
          (input)="changeHeaderStart($event)"
        />
        <div matSuffix class="wrapper-indicator">
          <button class="up" (click)="increaseHeader()" mat-icon-button>
            <mat-icon>expand_less</mat-icon>
          </button>
          <button class="down" (click)="decreaseHeader()" mat-icon-button>
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      </mat-form-field>

      <div class="preview-table">
        <table>
          <tr *ngFor="let row of getPreviewRows(); let i = index">
            <td>
              <mat-radio-button [checked]="headerRow === i" (change)="handleHeaderRowSelection(i)"></mat-radio-button>
            </td>
            <td *ngFor="let cell of row">{{cell}}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- header dropdown -->
    <div class="section" *ngIf="headerRow !== undefined">
      <h2>3. Select Split Column</h2>
      <shared-improved-select
        (selectedValueChanged)="handleHeaderSelection($event || '')"
        [dropdownOptions]="availableHeaders"
        dropdownName="Columns"
        [filterEnabled]="true"
        [clearable]="true"
        [shouldCleanName]="false"
        [allowMultipleSelections]="false"
      ></shared-improved-select>

      <mat-checkbox
        class="process-all-sheets"
        [checked]="processAllSheets"
        (change)="handleProcessAllSheetsChange($event.checked)"
      >
        Process matching rows across all sheets
      </mat-checkbox>
      <h6>If left checked all sheets will look at the column selected to aggregate upon</h6>
    </div>

    <div class="buttons" *ngVar="validityError as validityMessage">
      <button mat-raised-button color="warn" (click)="properties.onCancel()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        (click)="properties.onComplete()"
        [disabled]="validityMessage != null"
        [matTooltip]="validityMessage || ''"
      >
        Submit
      </button>
    </div>
  </div>
</shared-dialog-wrapper>

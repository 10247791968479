<div class="container">
  <div class="help">
    <div class="title-row">
      <!-- Back to uploader -->
      <span class="flex">
        <button mat-flat-button color="accent" (click)="backToUpload()">
          <mat-icon>arrow_back</mat-icon>
          <span class="text">Back to Selection</span>
          <span></span>
        </button>
      </span>
      <!-- Actual title -->
      <h1 class="title">
        {{ currentUploadCount }} Background Upload{{ currentUploadCount > 1 || currentUploadCount === 0 ? "s" : "" }}
      </h1>
      <!-- Flex placeholder -->
      <span class="flex"></span>
    </div>
    <p class="description">
      This page provides the ability to view background uploads for the current user that are still running
    </p>
  </div>
  <!-- Some information about current uploads -->
  <div class="upload-options" [ngClass]="{ 'no-uploads': currentUploadCount === 0 }">
    <!-- Some data if there are no available uploads -->
    <h1 *ngIf="currentUploadCount === 0">There are no uploads currently running in the background</h1>
    <!-- Render the actual options as they occur -->
    <data-store-upload-option
      *ngFor="let upload of currentUploads"
      [option]="upload"
      [clickable]="false"
      class="upload"
    >
      <mat-spinner
        color="accent"
        class="spinner"
        [diameter]="60"
        [mode]="upload.spinnerProgress == null ? 'indeterminate' : 'determinate'"
        [value]="upload.spinnerProgress"
      ></mat-spinner>
    </data-store-upload-option>
  </div>
</div>

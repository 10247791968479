<shared-improved-select
  #select
  [control]="control"
  [dropdownOptions]="domainOptions"
  dropdownName="Session Domain"
  [filterEnabled]="true"
  [paginatorEnabled]="true"
  [clearable]="false"
  [shouldCleanName]="false"
  [allowMultipleSelections]="false"
  hint="Tells the system how to treat this session when generating metrics."
  appearance="outline"
  prefixButton
  prefixButtonIcon="edit"
  prefixButtonTooltip="Opens the domain creation and editing dialog"
  (prefixButtonCallback)="onCustomKeywordClick()"
  (selectedValueChanged)="onDomainChange.next(control.value!)"
  [prefixButtonDisabled]="control.disabled"
>
</shared-improved-select>

import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  CustomTypes,
  DataStoreFileTypes,
  SessionDomain,
  UploadOption,
  UploadRequest,
  UploadResponse,
} from "@tdms/common";
import { UploaderProgressComponent } from "@tdms/frontend/modules/data-store/components/upload-progress/uploader-progress.component";
import { UploaderOption } from "@tdms/frontend/modules/data-store/components/upload/generic/option/option.component";
import {
  StepperComponent,
  UploadStep,
} from "@tdms/frontend/modules/data-store/components/upload/generic/stepper/stepper.component";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/upload/model/tracked.file";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { isEqual } from "lodash-es";

/** This allows the generic uploader to specify what step it needs to render in the event in exists externally */
@Directive({ selector: "data-store-generic-upload-step[step]" })
export class GenericUploadStepDirective {
  /** The name of the step we are tracking */
  @Input() step!: string;
  @ContentChild("content") content!: TemplateRef<any> | null;
}

/** This component provides the interfacing capabilities for uploading files while allowing for selection of file type to be uploaded. */
@Component({
  selector: "data-store-generic-upload[uploadMode][steps]",
  templateUrl: "./uploader.component.html",
  styleUrls: ["./uploader.component.scss"],
})
export class GenericUploaderComponent extends UploaderProgressComponent implements OnInit, AfterViewInit {
  /** These steps provide a centralized location for other components to extend upon these uploader steps. */
  static STEPS = [
    { name: "File Type", icon: "description" },
    { name: "Data Type", icon: "inventory_2" },
    { name: "Upload", icon: "upload_file" },
    { name: "Processing", icon: "pending", allowPrevious: false },
  ] as const;

  /** This template allows for metadata to be rendered under the upload file input */
  @ContentChild("uploadMetadata") uploadMetadata!: TemplateRef<any>;

  /** This template allows for custom step rendering based on child content */
  @ContentChildren(GenericUploadStepDirective) stepTemplates!: QueryList<GenericUploadStepDirective>;

  /** Reference to our sidenav stepper displayed */
  @ViewChild(StepperComponent) stepper!: StepperComponent;

  /** Steps that should be rendered and handled. **This must include {@link GenericUploaderComponent.STEPS} somewhere within it.** */
  @Input() steps!: UploadStep[];

  /** When files are selected within the uploader step, this output will be fired with the selected files. */
  @Output() onFilesSelected = new EventEmitter<TrackedFile[]>();

  /** After the upload has been handled successfully, this will respond with the results. */
  @Output() uploadComplete = new EventEmitter<UploadResponse[]>(true);

  /** When the current step changes backwards before the upload step, this will be fired. */
  @Output() onStepBackwardsBeforeUpload = new EventEmitter<void>();

  /**
   * This upload mode helps define what type of files we are allowed to select in this process.
   * - `session-creation`: Means we should only be allowed to select types that support creating a session.
   * - `all`: Means we should be allowed to select any type.
   * - `exclude-session-creation`: Excludes any types that are restricted only to session creation
   */
  @Input() uploadMode: "session-creation" | "all" | "exclude-session-creation" = "all";

  /** Allows the developer to decide if the upload button submit should be disabled. Usually used if you are requiring more metadata. */
  @Input() uploadSubmitDisabled = false;

  /** If true, we won't display the metadata, or submit button, until a file has been selected in the uploader. */
  @Input() hideMetadataTillFile = true;

  /** Steps that you would like centered on the page */
  @Input() centeredSteps: (typeof this.steps)[number]["name"][] = [];

  /** Upload options provided by the backend plugins. */
  availableUploadOptions: UploadOption[] = [];

  /** These types help simplify {@link availableUploadOptions} to more basic categories. */
  simpleUploadOptions: ReturnType<GenericUploaderComponent["populateBaseOptions"]> = [];

  /** These types are the specific data store upload options mapped to selection options to help the developer select where this data came from. */
  specificTypes: { option: UploaderOption; pluginOpt: UploadOption }[] | undefined;

  //// The following fields customize some data sent to the {@link UploaderProgressComponent.submitUploadRequest} callback
  /** If given, during upload we will include this as the session name. Only will be used for session creations. */
  @Input() sessionName: string | undefined;
  /** If given, we will provide this during the upload request to instead upload to a specific session. */
  @Input() sessionId: number | undefined;
  /** A type that allows us to upload specific special data endpoints for handling */
  @Input() specialUploadType?: UploadRequest["specialUploadType"];
  /** If this upload process should become the data provider for the {@link sessionId} */
  @Input() isDelayedSessionCreation = false;
  /** The domain a created session for the uploader should have referenced to it. */
  @Input() sessionDomain?: SessionDomain;

  /// Below is the data that has been selected by the user as the steps progress

  /** The selected simplified file type the user wants to upload data for. Should be like "Audio" or "Video" */
  selectedFileType: CustomTypes.UnArray<GenericUploaderComponent["simpleUploadOptions"]> | undefined;

  /**
   * The selected option that we wish to upload our file as. This is the backend defined option. Can be given on
   *  input to bypass selection. You'll need to not include the steps if you choose to do this.
   */
  @Input() selectedUploadOption: UploadOption | undefined;

  /** Files that were uploaded in our upload step. */
  uploadedFiles: TrackedFile[] = [];

  /** The active step from the stepper component */
  activeStep: UploadStep | undefined;

  constructor(
    private store: Store,
    userService: UserService,
    uploadService: UploadService,
    notificationService: NotificationService,
    private routerService: RouterService
  ) {
    super(userService, uploadService, notificationService);
  }

  ngAfterViewInit(): void {
    // Listen for stepper decrements so if we end up below the upload index, we should wipe some data
    const uploadIndex = this.stepper.steps.findIndex((x) => x.name === GenericUploaderComponent.STEPS[2].name);
    this.addSubscription(
      this.stepper.onPrevStep.subscribe((index) => {
        if (index < uploadIndex) {
          // Wipe the current files
          this.setUploadedFiles([]);
          // Inform
          this.onStepBackwardsBeforeUpload.next();
        }
      })
    );
    // Update active step based on step change
    this.addSubscription(this.stepper.onPrevStep.subscribe(() => (this.activeStep = this.stepper.activeStep)));
    this.addSubscription(this.stepper.onNextStep.subscribe(() => (this.activeStep = this.stepper.activeStep)));
  }

  ngOnInit(): void {
    // Keep all upload options in check
    this.addSubscription(
      this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
        this.availableUploadOptions = this.getAvailableUploadOptions(options);
        this.simpleUploadOptions = this.populateBaseOptions(this.availableUploadOptions);
      })
    );
    // Set default active step tracker
    this.activeStep = this.steps[0];
  }

  /** This function is used to prevent redirects away from the upload in the event the user is in the middle of something */
  async canDeactivate(newRoute: string): Promise<boolean> {
    const currentStep = this.stepper.currentStep;
    // Only ask if we're somewhere where they might lose progress
    if (currentStep !== 0 && currentStep !== this.steps.length - 1) {
      const description = this.status
        ? "Are you sure you want to leave? Any failures will not be able to be handled. You will be able to see the upload progress in the upload page."
        : "Are you sure you want to leave? Any current populated information will be forgotten!";
      return await this.routerService.canDeactivateHelper(description, newRoute);
    } else return true; // Allow route change
  }

  get activeStepName() {
    return this.activeStep?.name;
  }

  /** Based on {@link uploadMode}, and given an array of upload options, returns a filtered version to allow only specific types. */
  private getAvailableUploadOptions(options: UploadOption[]) {
    // We don't want options that can't actually be uploaded
    options = options.filter((x) => x.canBeUploadedExternal && !x.usedOnlyForParsing);
    if (this.uploadMode === "session-creation")
      return options.filter((x) => x.canCreateSessions || x.canCreateMultipleSessions);
    else if (this.uploadMode === "exclude-session-creation")
      return options.filter((x) => !x.canCreateSessions || !x.restrictedToSessionCreation);
    else return options;
  }

  /**
   * Given a filtered array of upload options, returns some data that defines human friendly file type selection.
   *  This means that .wav, .mp3, etc will be condensed to "Audio" to allow the user a more easier to track experience.
   */
  private populateBaseOptions(options: UploadOption[]) {
    // Create a mapping and allow it to be identified and use a reusable data set
    const mappings: { type: DataStoreFileTypes.DataStoreFileTypesKeys; name?: string; icon: string }[] = [
      { type: "Audio", icon: "hearing" },
      { type: "Video", icon: "webcam" },
      { type: "CSV", name: "Spreadsheet", icon: "description" },
      { type: "Generic", icon: "settings" },
    ];
    // Now flatten to actual options
    const result = mappings.map((z) => {
      const types = DataStoreFileTypes.typeToStringArray(z.type);
      const typesDescription = `Supported file types: ${types.join(", ")}`;
      // If we contain an uploadable option with these types, we can track this as an upload type
      if (options.find((x) => isEqual(types, x.types)))
        return { name: z.name ?? z.type, type: z.type, description: typesDescription, icon: z.icon };
      else return undefined!;
    });
    return result.filter((x) => x != null);
  }

  /** Given an option, sets that as the currently tracked file type option to be uploaded. */
  protected setFileType(option: UploaderOption) {
    const opt = option as CustomTypes.UnArray<ReturnType<GenericUploaderComponent["populateBaseOptions"]>>;
    this.selectedFileType = opt;
    this.stepper.steps[1].completeText = opt.name;
    // Align our available data sources based in this file type
    const fileTypes = DataStoreFileTypes.typeToStringArray(opt.type);
    // Find all types that match that, turn them into options
    const types = this.availableUploadOptions.filter((x) => isEqual(x.types, fileTypes));
    this.specificTypes = types.map((x) => ({
      pluginOpt: x,
      option: { name: x.friendlyName ?? x.name, description: x.description ?? "", icon: x.icon },
    }));
    this.stepper.nextStep();
  }

  /** Given the plugin option, sets the specific type that the user will upload as. */
  setSpecificPluginType(type: UploadOption) {
    this.selectedUploadOption = type;
    this.stepper.steps[2].completeText = type.friendlyName ?? type.name;
    this.stepper.nextStep();
  }

  /** Tracks the uploaded files and moves to the next step */
  setUploadedFiles(files: TrackedFile[]) {
    this.uploadedFiles = files;
    this.onFilesSelected.next(files);
  }

  /** Once all steps are complete, we submit the upload for actual processing */
  async submitUpload() {
    this.stepper.nextStep();
    try {
      const result = await this.submitUploadRequest(this.uploadedFiles, {
        sessionId: this.sessionId,
        sessionName: this.sessionName,
        specialUploadType: this.specialUploadType,
        isDelayedSessionCreation: this.isDelayedSessionCreation,
        sessionDomain: this.sessionDomain,
      });
      if (result) this.uploadComplete.next(result);
      // Once complete, we can tell the user
      this.stepper.nextStep();
    } catch (e) {
      // Handle failures. Maybe they should try their handling again.
      this.stepper.prevStep();
    }
  }

  /** Redirects to the main upload pipeline selection page */
  redirectToUpload() {
    const params = this.routerService.getAllQueryParams();
    // Reset the one off upload type if we have one
    params[RouterParamTypes.oneOffUploadType] = undefined;
    this.routerService.redirectTo(Route_URLs.uploadPipeline, params);
  }
}

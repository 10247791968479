import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AggregateFileRequest } from "@tdms/common";

import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** The properties to display for the aggregate heading selection */
export interface AggregateFileDialogProps {
  request: AggregateFileRequest;
  onComplete: Function;
  onCancel: Function;
}

/**
 * This component is used as a dialog to handle selecting a header you wish to split aggregate data from
 */
@Component({
  selector: "aggregate-file-component-dialog",
  templateUrl: "./aggregate-file-component.html",
  styleUrls: ["./aggregate-file-component.scss"],
})
export class AggregateFileComponent extends SubscribingComponent implements OnDestroy {
  selectedSheet: string = "";
  headerRow: number = 0;
  headerRowStart: number = 0;
  availableHeaders: string[] = [];
  processAllSheets: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public properties: AggregateFileDialogProps) {
    super();
  }

  /** Returns sheet names contained in excel file  */
  getSheetNames(): string[] {
    return this.properties.request.sheets.map((s) => s.sheetName);
  }

  /** Grabs 5 rows from a sheet for the preview based on header start */
  getPreviewRows(): any[][] {
    const sheet = this.properties.request.sheets.find((s) => s.sheetName === this.selectedSheet);
    return sheet?.previewRows.slice(this.headerRowStart, this.headerRowStart + 5) || [];
  }

  /** Changes sheet and resets header row */
  handleSheetSelection(sheetName: string) {
    this.selectedSheet = sheetName;
    this.properties.request.selectedSheet = sheetName;
    this.headerRow = 0;
    this.headerRowStart = 0;
    this.updateAvailableHeaders();
  }

  /**
   * Selects which row is supposed to be the header
   * @param rowIndex
   */
  handleHeaderRowSelection(rowIndex: number) {
    this.headerRow = rowIndex;
    this.properties.request.headerRow = rowIndex;
    this.updateAvailableHeaders();
  }

  /**
   *  Handles header selection to aggregate values on
   * @param header
   */
  handleHeaderSelection(header: string) {
    this.properties.request.selectedHeader = header;
  }

  /**
   * Increases header start by one
   */
  increaseHeader() {
    this.headerRowStart++;
    this.updateAvailableHeaders();
  }
  /**
   * Decreases header start by one
   */
  decreaseHeader() {
    if (this.headerRowStart != 0) this.headerRowStart--;
    this.updateAvailableHeaders();
  }

  /**
   *  Boolean to control to aggregate all sheets or just the one selected
   * @param checked
   */
  handleProcessAllSheetsChange(checked: boolean) {
    this.processAllSheets = checked;
    this.properties.request.processAllSheets = checked;
  }

  /**
   * Change header row offset valu
   * @param event
   */
  changeHeaderStart(event: any) {
    if (event.data) this.headerRowStart = event.data;
    else this.headerRowStart = 0;

    this.updateAvailableHeaders();
  }

  /**
   * Updates available headers for step 3
   */
  private updateAvailableHeaders() {
    const sheet = this.properties.request.sheets.find((s) => s.sheetName === this.selectedSheet);
    if (sheet && sheet.previewRows[this.headerRow + this.headerRowStart]) {
      this.availableHeaders = sheet.previewRows[this.headerRow + this.headerRowStart];
    } else {
      this.availableHeaders = [];
    }
  }

  /** Returns if this set of data is valid or not with an error message */
  get validityError() {
    if (!this.properties.request.selectedSheet) {
      return "You must select a sheet";
    }
    if (this.headerRow === undefined) {
      return "You must select a header row";
    }
    if (!this.properties.request.selectedHeader) {
      return "You must select a header";
    }
    return undefined;
  }
}

<shared-dialog-wrapper
  class="domain-create-dialog"
  [header]="properties.selectedDomain == null ? 'New Session Domain' : 'Edit Session Domain'"
>
  <h1 class="instructions">Please input your session domain information below and click the submit button</h1>

  <form [formGroup]="form" (submit)="complete()" class="form">
    <mat-form-field appearance="outline" color="accent" class="input">
      <mat-label>Domain Name</mat-label>
      <input matInput placeholder="New domain" formControlName="name" />
      <mat-error *ngIf="form.controls.name.hasError('forbiddenValue')"> This domain name is already in use. </mat-error>
      <mat-error *ngIf="form.controls.name.hasError('required')">Domain name is required</mat-error>
    </mat-form-field>
  </form>

  <div class="buttons">
    <div>
      <button
        *ngIf="properties.selectedDomain != null"
        class="delete"
        mat-raised-button
        color="warn"
        (click)="this.delete()"
        [disabled]="!canDelete"
        [matTooltip]="canDelete ? 'Requests to delete this session domain' : 'You cannot delete the default domain'"
        matTooltipPosition="above"
        [configDisable]="'sessionDomain.allowDeleting'"
        configDisabledStateMessage="Session domain deletion is disabled"
      >
        Delete
      </button>
    </div>
    <div>
      <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        [matTooltip]="form.valid ? '' : 'Please make sure all fields are filled out and valid'"
        matTooltipPosition="below"
        (click)="complete()"
      >
        {{ properties.selectedDomain == null ? "Create" : "Save" }}
      </button>
    </div>
  </div>
</shared-dialog-wrapper>

import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { groupBy } from "lodash-es";
import { TrackedFile } from "../../model/tracked.file";
import { FileNode } from "./node/file.node";

/**
 * A component that allows us to display a file tree of given information
 */
@Component({
  selector: "data-store-file-tree[files]",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.scss"],
})
export class FileTreeComponent implements OnChanges {
  /**
   * The files we wish to display
   */
  @Input() files: TrackedFile[] = [];

  /**
   * Message to display when the tree is empty
   */
  @Input() noFilesMessage = "No Files To Display";

  /**
   * A callback that will be fired if you try to delete a node on the tree. The
   *  delete button will only be shown if this is given.
   */
  @Input() deleteCallback: { (node: TrackedFile): void } | undefined;

  /**
   * An event that will be fired when the user wants to associate files with
   *  a session listed in the tree.
   */
  @Output() uploadToNode = new EventEmitter<TrackedFile>();

  /**
   * An emitter so we can select a file from the file tree and rename the session.
   */
  @Output() editNodeName = new EventEmitter<TrackedFile>();

  /**
   * The controller for displaying our tree
   */
  treeControl = new FlatTreeControl<FileNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  /**
   * The data source for our tree
   */
  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    new MatTreeFlattener(
      this.treeTransformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children
    )
  );

  ngOnChanges(changes: SimpleChanges): void {
    // Track changes for files so we can adjust display
    if (changes.files != null || changes.sessionFiles != null) {
      this.dataSource.data = this.convertTreeFilesToTree();
      this.treeControl.expandAll();
    }
  }

  selectNodeForUpload(sessionFile: TrackedFile) {
    this.uploadToNode.emit(sessionFile);
  }

  selectNodeToEdit(sessionFile: TrackedFile) {
    this.editNodeName.emit(sessionFile);
  }
  /**
   * Converts the file data to tree data and returns it
   */
  convertTreeFilesToTree() {
    const pluginGroups = groupBy(this.files, (x) => x.fileType.associatedPlugin);
    return Object.keys(pluginGroups).flatMap(
      (x) =>
        ({
          name: x,
          children: pluginGroups[x].map((z) => ({ name: z.fileDisplayName, originalData: z } as FileNode)),
        } as FileNode)
    );
  }

  /**
   * Returns if the given node has a child or not to determine
   *  what type of tree node to display.
   */
  nodeHasChild(_: number, node: FileNode) {
    return node.expandable;
  }

  /**
   * Returns if the given node has a child or not to determine
   *  what type of tree node to display.
   */
  getNodeLevel(node: FileNode) {
    return this.treeControl.getLevel(node);
  }

  /**
   * Converts our file nodes into something the tree can understand to display
   */
  private treeTransformer(node: FileNode, level: number): FileNode {
    return {
      ...node,
      expandable: !!node.children && node.children.length > 0,
      level: level,
    } as FileNode;
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { DomainInfoComponent } from "./components/info/info.component";
import { DomainSelectionComponent } from "./components/selection/selection.component";
import { SessionDomainService } from "./services/session.domain.service";
import { domainReducer } from "./store/reducer";

/** Components provided by the session module */
const COMPONENTS = [DomainInfoComponent, DomainSelectionComponent];

/** A module that provides session capabilities */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    SharedModule,
    StoreModule.forFeature("domain", domainReducer),
  ],
  providers: [SessionDomainService],
  exports: COMPONENTS,
})
export class SessionDomainModule {}

<div class="file-uploader-container">
  <div class="row">
    <!-- File input -->
    <div class="file-input-container">
      <!-- Upload button -->
      <button class="upload-button" matPrefix mat-flat-button color="accent" (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
      </button>
      <!-- Input for displaying the name of our current file -->
      <mat-form-field appearance="fill" class="file-input">
        <input disabled matInput type="text" [value]="inputDisplay" autocomplete="off" />
        <!-- Hidden input to actually do the file upload -->
        <input
          hidden
          (change)="fileChange($any($event))"
          (click)="$any($event.target).value = null"
          #fileInput
          type="file"
          [accept]="supportedFileTypes"
          [multiple]="allowMultiple"
        />
      </mat-form-field>
      <!-- Display supported file types -->
      <span *ngIf="displaySupportedFileTypes" class="supported-file-types">
        <span class="title">Supported Types</span>
        <span class="options">{{ supportedFileTypes.join(", ") }}</span>
      </span>
    </div>
  </div>
</div>

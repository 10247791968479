import { SelectionChange } from "@angular/cdk/collections";
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { TableColumnVisibility } from "@tdms/frontend/modules/shared/components/tables/column-visibility/visibility.component";
import { GenericTableColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import {
  CellDisplay,
  TableRequiredObject,
} from "@tdms/frontend/modules/shared/components/tables/table-base/table-base.component";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import { kebabCase } from "lodash-es";
import { v4 } from "uuid";

/**
 * This component is a generic table that can be utilized in multiple customizable aspects
 * across the application.
 * Allows consumers to override individual column display widgets by defining <cell-display> directives
 * inside the component.
 * The component currently also supports a deprecated child template called #cellTemplate which creates one view
 * for all child components. Consumers that use this mechanism will need to create an ngSwitchCase element for all columns
 * and they will get no default behavior for columns they don't support, which is why the <cell-display> directives are the recommended usage.
 *
 * Simple example:
 * <shared-generic-table ...>
 *   <cell-display [columns]="['col1', 'col2', ...]">
 *     <ng-template #display let-value="value" let-column="column" let-element="element">
 *        <!-- Your display logic goes here. Column, value and element reference the table column info and value for the column. -->
 *     </ng-template>
 *   </cell-display>
 *   <cell-display [columns]="['col3', 'col4', ...]">
 *     <ng-template #display let-value="value" let-column="column" let-element="element">
 *        <!-- Your display logic goes here. Column, value and element reference the table column info and value for the column. -->
 *     </ng-template>
 *   </cell-display>
 * <!-- You can define as many cell-displays as you want. The first element matching a given column will be used to display that column. -->
 * <!-- If a column doesn't have a matching cell-display, a default textual display will be used for that column. -->
 * </shared-generic-table>
 */
@Component({
  selector: "shared-generic-table[data][displayedColumns][tableName]",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
})
export class GenericTableComponent<T extends TableRequiredObject>
  extends TableColumnVisibility<T>
  implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, AfterContentInit, OnDestroy
{
  /**
   * Reference to the old style cell display template that should do a switch case on all columns.
   * @deprecated prefer to use the <cell-display> multi-template style instead!
   */
  @ContentChild("cellTemplate") cellDisplayRef!: TemplateRef<any> | null;

  /**
   * Reference to the button row so the developer can override it
   */
  @ContentChild("buttonRow") buttonRowRef!: TemplateRef<any> | null;

  /** The paginator element displayed at the bottom of the table */
  @ViewChild("contentTablePaginator") paginator!: MatPaginator;

  /** The actual mat table that renders our table content */
  @ViewChild("genericTable") table!: MatTable<any>;

  /** The sorting control that allows us to sort by column headers at the top of the table */
  @ViewChild(MatSort) sort!: MatSort;

  /** Maps column names to the cell display objects.
   * We track changes and update our display refs as the angular content changes to keep things up to date properly.
   */
  displayMap: Map<string, CellDisplay> = new Map();

  constructor(private cdr: ChangeDetectorRef, public override settingService: PluginSettingsService) {
    super(settingService);
    this.getCellValue = this.getCellValue.bind(this);
    this.addSubscription(this.selection.changed.subscribe(this.selectionStateChange.bind(this)));
  }

  /**
   * Once the content has initialized, our cell display refs will be loaded and we can setup our map.
   */
  ngAfterContentInit(): void {
    this.mapCellDisplayRefs();
    this.addSubscription(
      this.cellDisplayRefs.changes.subscribe((_) => {
        this.mapCellDisplayRefs();
        this.cdr.markForCheck();
      })
    );
  }

  ngAfterViewChecked(): void {
    const currTableSize = this.tableWidth;
    this.tableWidth = this.tableWidthFromElement;
    // Fixes an issue where the page doesn't set the paginator size correctly when switching views
    const result = this.guessPaginatorPageSize();
    if (result || currTableSize !== this.tableWidth) this.cdr.detectChanges();
  }

  override ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<GenericTableComponent<any>>): void {
    super.ngOnChanges(changes);
    if (changes.data) this.setDataSourceData(changes.data.currentValue as T[]);
  }

  ngAfterViewInit(): void {
    // Assign paginator
    this.dataSource.paginator = this.paginator;
    // Assign sort
    this.dataSource.sort = this.sort;
    this.guessPaginatorPageSize();
    // Force detection for initial page size guess
    this.cdr.detectChanges();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Set default filterable fields
    if (this.filterableFields == null)
      this.filterableFields = this.displayedColumns
        /// Only allow filtering on fields that are data backed
        .filter((field) => field.isDataBacked && !field.onlyShowInExport)
        .map((x) => {
          return { value: x.name, viewValue: x.title };
        });
    // Set default filter
    if (this.filterOn == null && this.filterableFields != null) this.filterOn = this.filterableFields[0].value as any;
    if (this.defaultSortHeader == null && this.filterableFields != null)
      this.defaultSortHeader = this.filterableFields[0].value as any;
  }

  /**
   * Iterate through our child cell-display elements and map them to their specified column(s).
   */
  mapCellDisplayRefs() {
    this.displayMap = new Map();
    for (let cellDisplay of this.cellDisplayRefs) {
      for (let column of cellDisplay.columns) {
        this.displayMap.set(column, cellDisplay);
      }
    }
  }

  /**
   * Attempts to guess how many rows we can use for this table to fit in it's container. Will only update
   *  class variables in the event the container height is not still the same to the last calculated height.
   * @returns True if we should fire a change detection, false if not
   */
  @HostListener("window:resize", ["$event"])
  guessPaginatorPageSize() {
    if (this.shouldGuessPaginatorPageSize) {
      // The mat row CSS defaults to this
      const totalHeight = document.querySelector("#generic-table-container")?.clientHeight || 0;
      if (this.containerHeight === totalHeight) return false;
      this.containerHeight = totalHeight;
      const utilityRowHeight = document.querySelector("#generic-table-utility-row")?.clientHeight || 0;
      const paginatorHeight = document.querySelector("#generic-table-paginator")?.clientHeight || 0;
      let guessedRows = Math.floor((totalHeight - utilityRowHeight - paginatorHeight) / this.matRowHeight) - 1;
      if (guessedRows < 1) guessedRows = 1;
      this.paginatorPageSize = guessedRows;
      this.paginator?._changePageSize(guessedRows);
      return true;
    } else return false;
  }

  /**
   * Returns the table elements width from DOM
   */
  get tableWidthFromElement() {
    return (this.table as any as { _elementRef: ElementRef })._elementRef.nativeElement.clientWidth;
  }

  /**
   * Replaces the current table data source with new data and copies over
   *  the important functions
   */
  setDataSourceData(data: T[]) {
    // Copy relevant info
    const newSource = new MatTableDataSource(data);
    newSource.paginator = this.paginator;
    newSource.filterPredicate = this.filterTableData.bind(this) as any;
    newSource.sort = this.sort;
    this.dataSource = newSource;
    this.selection.clear();
  }

  /**
   * This function determines if the given data matches the current filter. This allows
   *  us to change what the table shows.
   */
  filterTableData(data: T, filter: string) {
    let dataToCheck = `${data[this.filterOn]}`;
    // Get matching column so we can execute the conversion function if it exists
    const displayColumn = this.displayedColumns.find((x) => x.name === this.filterOn);
    dataToCheck = displayColumn?.getDataValue(data);
    return `${dataToCheck}`.trim().toLowerCase().includes(filter);
  }

  /**
   * Given an event, filters the table
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // Apply filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Clears the current filer
   */
  clearFilter() {
    this.filterValue = "";
    this.dataSource.filter = this.filterValue;
  }

  /**
   * Handles when the search on changes so we can re trigger the filter
   */
  searchOnChange() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getCellValue(rowData: any, column: GenericTableColumn) {
    return column.getDataValue(rowData);
  }

  /**
   * When called, exports all the data and columns loaded into the table into a .csv format and
   *  allows the user to download it.
   */
  exportTableData() {
    if (this.data) {
      const fileName = kebabCase(`${this.tableName}-table-data`);
      // Build the CSV string based on data and content
      const headersToIncludeInOutput = this.displayedColumns.filter((x) => x.isDataBacked && !x.hideFromExport);
      const tableHeaders = headersToIncludeInOutput.map((x) => x.title).join(",");
      let csvString = `${tableHeaders}\n`;
      // Iterate over all of our data and add it to the export string
      for (let chartData of this.data) {
        // Iterate over every header to include
        csvString += headersToIncludeInOutput
          .map((header) => {
            const val = header.getDataValue(chartData);
            if (val == null) return undefined;
            else return `"${val}"`;
          })
          .filter((x) => x != null)
          .join(",");
        // Don't forget the ending newline
        csvString += "\n";
      }
      FrontendUtility.saveFile("csv", csvString, fileName);
    }
  }

  /**
   * Returns if the selected set of data matches the length of our data source
   */
  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  /** Handles what to do when the selection mapping of any checkbox changes */
  selectionStateChange(_changes: SelectionChange<T>) {
    if (this.selectionUpdate) this.selectionUpdate(this.selection.selected);
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** Given an element from {@link data}, locates it's index and returns what page it should be on within the paginator. */
  getPageIndex(val: T) {
    const elementIndex = this.data?.findIndex((x) => x === val) ?? 0;
    return Math.floor(elementIndex / this.paginator.pageSize);
  }

  /** Given a page index to go to, updates the paginator to go to that page. */
  goToPage(val: number) {
    // Only update if there is an actual change
    if (val !== this.paginator.pageIndex) {
      this.paginator.pageIndex = val;
      const event = {
        length: this.paginator.length,
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
      } as PageEvent;
      this.paginator.page.next(event);
      this.cdr.detectChanges();
    }
  }

  /** Returns the Id used to populate the Id field of each table row. */
  getTableRowId(data: T, index?: number) {
    // Try to use object Id, if not available, use index, if also not available, use uid
    const id = (data as any).id ?? index ?? v4().substring(0, 4);
    return `${this.tableName}-${id}`;
  }
}

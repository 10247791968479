import { createFeatureSelector, createSelector } from "@ngrx/store";
import { domainAdapter } from "./reducer";
import { DomainState } from "./state";

/** Given a state selects the SessionDomain object */
export const selectSessionDomainState = createFeatureSelector<DomainState>("domain");

/** Returns all session domains */
export const selectAllDomains = (state: DomainState) => domainAdapter.getSelectors().selectAll(state);
/** Selects all the session domains from our current state */
export const selectAllDomainsFromState = createSelector(selectSessionDomainState, selectAllDomains);

import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Session, SessionSelectionTableConfiguration, Utility } from "@tdms/common";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { selectAllSessionsFromState } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";

@Component({
  selector: "app-session-selector",
  templateUrl: "./session-selector.component.html",
  styleUrls: ["./session-selector.component.scss"],
})
export class SessionSelectorComponent extends SubscribingComponent {
  /**
   * The table data set to display for our sessions
   */
  sessions: Session[] = [];

  /** Columns that should be displayed for the table within this component */
  displayedColumns = [
    new DatalessColumn("actions", "Actions"),
    DataColumn.fromColumnConfig(SessionSelectionTableConfiguration.Columns.Name),
    DataColumn.fromColumnConfig(SessionSelectionTableConfiguration.Columns.Domain, (val) => val.name),
    DataColumn.fromColumnConfig(SessionSelectionTableConfiguration.Columns.Start, Utility.getDateAsZulu.bind(this)),
    DataColumn.fromColumnConfig(SessionSelectionTableConfiguration.Columns.End, Utility.getDateAsZulu.bind(this)),
    DataColumn.fromColumnConfig(SessionSelectionTableConfiguration.Columns.Tags, (value) =>
      value.map((x) => x.name).join(",")
    ),
  ];

  /** Defines the setting name and plugin for column visibility persistence within the table of this component */
  readonly columnVisibilitySetting = SessionSelectionTableConfiguration.SettingConfig;

  constructor(
    private store: Store<SessionState>,
    private serviceManager: ServiceManager,
    public sessionService: SessionService,
    public configService: ConfigService
  ) {
    super();
    this.addSubscription(
      this.store
        .select(selectAllSessionsFromState)
        .subscribe((sessions) => (this.sessions = Session.fromPlainArray(sessions)))
    );
    autoBind(this);
  }

  /** Returns the loading status message for the progress spinner */
  get loadingMessage() {
    // Tracks if the services have completed processing on their "user" change. This is actually when session info is loaded.
    const userServicesReady = this.serviceManager.onServicesLoadedUser.value;
    // If the services have completed processing for the session, assuming we have a session
    const servicesReady = this.serviceManager.onServicesLoadedSession.value;
    if (!userServicesReady) return "Loading...";
    else if (!servicesReady) return "Loading Session...";
    else return undefined;
  }

  /**
   * Sets the given session as the current session
   */
  async selectSession(session: Session) {
    this.store.dispatch(SessionActions.select({ session: session }));
    // Redirect is handled in session service
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * A synchronous validator function that checks if a control's value is similar to any of the given values. The similarity
 *  is controlled by the configuration.
 *
 * You can use this like the following:
 *
 * ```ts
 * this.myForm = this.formBuilder.group({
 *   myField: ['', [similarValueValidator([1, 2, 3])]]
 * });
 * ```
 *
 * To check this in the template, use the following:
 *
 * ```html
 * <mat-error *ngIf="sessionNameControl.hasError('similarValue')"></mat-error>
 * ```
 *
 * @param values - An array of values to check for similarity.
 * @param config - Leading if the leading text should be checked or trailing of the trailing text should be checked.
 * @returns A `ValidatorFn` that validates the control against the provided similar config.
 */
export function similarValueValidator(values: string[], config: "leading" | "trailing"): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = (control.value as string).trim().toLowerCase();
    const hasASimilarValue = values.some((x) => {
      const cleanX = x.trim().toLowerCase();
      if (config === "leading") return cleanX.startsWith(value);
      else return cleanX.endsWith(value);
    });
    if (hasASimilarValue) return { similarValue: { value: control.value } };
    return null;
  };
}

import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Keyword, KeywordGroup, KeywordTableConfiguration, SessionDomain } from "@tdms/common";
import {
  KeywordInfoComponent,
  KeywordInfoDialogProperties,
} from "@tdms/frontend/modules/keyword/component/keyword-info/keyword-info.component";
import { selectAllKeywordsFromState } from "@tdms/frontend/modules/keyword/model/store/keyword.selector";
import { KeywordState } from "@tdms/frontend/modules/keyword/model/store/keyword.state";
import { KeywordService } from "@tdms/frontend/modules/keyword/services/keyword.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This component is used to allow inserting/removing keywords to domains.*/
@Component({
  selector: "keyword-list",
  templateUrl: "./keyword-list.component.html",
  styleUrls: ["./keyword-list.component.scss"],
})
export class KeywordListComponent extends SubscribingComponent implements OnChanges {
  /** The domain that we want to modify keywords for */
  @Input() domain: SessionDomain | undefined;

  /** A list of all available keywords to the frontend */
  keywords: Keyword[] = [];

  /** A list of selected keywords that are related to this domain */
  selectedKeywords: Keyword[] = [];

  /** Columns that should be displayed for the table within this component */
  displayedColumns = [
    DataColumn.fromColumnConfig(KeywordTableConfiguration.Columns.Text),
    DataColumn.fromColumnConfig(KeywordTableConfiguration.Columns.Group, (group) => KeywordGroup[group]),
    new DatalessColumn("actions", "Actions"),
  ];

  constructor(private store: Store<KeywordState>, private keywordService: KeywordService, private dialog: MatDialog) {
    super();
    this.addSubscription(
      this.store.select(selectAllKeywordsFromState).subscribe((x) => {
        this.keywords = x;
        this.populateSelection();
      })
    );
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<KeywordListComponent>) {
    if (changes.domain) this.populateSelection();
  }

  /** Populates the current selection based on our current domain */
  populateSelection() {
    this.selectedKeywords = this.keywords.filter((x) => x.domain.id === this.domain?.id);
  }

  /** Opens a dialog to add or edit a keyword*/
  openKeywordInfoDialog(keyword?: Keyword) {
    this.dialog.open(KeywordInfoComponent, {
      data: {
        keyword,
        domain: this.domain,
      } as KeywordInfoDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Opens a dialog to prompt for removing the given keyword */
  deleteKeyword(keyword: Keyword) {
    this.keywordService.openKeywordDeleteDialog(keyword);
  }
}

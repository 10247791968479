import {
  BarChartData,
  Bookmark,
  ChartConfiguration,
  ChartData,
  GaugeChartData,
  LineChartData,
  MetricPluginReply,
  PieChartData,
  TDMSBase,
  TimeSeriesBarChartData,
} from "@tdms/common";
import { ChartBaseComponent } from "@tdms/frontend/modules/charts/shared/base/base.component";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { LegendDisplayObject } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display.object";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, Observable, of } from "rxjs";

/**
 * The data collection used by the metric card component to display it's relevant data.
 * Primarily contains the metric chart data, the chart configuration, generated legend, and color map pulled from the theme service.
 */
export class MetricCardDataStore<T extends FrontendSupportedMetricType = ChartData[]> {
  metric: MetricServiceDataStore<T> | undefined;
  configuration: ChartConfiguration;
  colorMap: Observable<ColorMap>;
  /** The Legend to display for this card. */
  legend?: BehaviorSubject<LegendDisplay[]>;

  enabled: Observable<boolean>;

  /** Whether or not bookmarks should be drawn on this chart. */
  bookmarksEnabled: Observable<boolean>;

  /**
   * Used via the metric-grid to track last enabled status of this card.
   *
   * **Warning: This will only be filled out via the metric-grid**
   */
  lastEnabledStatus: boolean = false;

  /** Tracks if this component is set to be the timeline for whatever grid it's used within. */
  isTimeline: boolean;

  constructor(
    metric: MetricServiceDataStore<T>,
    configuration: ChartConfiguration,
    colorMap: Observable<ColorMap>,
    enabled: Observable<boolean>,
    bookmarksEnabled: Observable<boolean>,
    legend?: BehaviorSubject<LegendDisplay[]>,
    isTimeline = false
  ) {
    this.metric = metric;
    this.configuration = configuration;
    this.colorMap = colorMap;
    this.enabled = enabled;
    this.legend = legend;
    this.bookmarksEnabled = bookmarksEnabled;
    this.isTimeline = isTimeline;
  }

  static empty<_chartDataType extends FrontendSupportedMetricType = ChartData[]>(
    configuration: ChartConfiguration
  ): MetricCardDataStore<_chartDataType> {
    return new MetricCardDataStore(new MetricServiceDataStore(), configuration, of([]), of(true), of(true));
  }

  /** When the legend is clicked from a chart display, this callback will be hit to tell the actual legend to update */
  onLegendClicked(element: LegendDisplayObject) {
    if (this.legend) {
      const updatedLegendValues = LegendDisplay.updateSpecificElement(cloneDeep(this.legend.value), element);
      // Inform others that use this subject
      this.legend.next(updatedLegendValues);
    }
  }
}

/**
 * The metrics our metric service supports
 */
export type FrontendSupportedMetricType =
  | BarChartData[]
  | LineChartData[]
  | TimeSeriesBarChartData[]
  | ChartData[]
  | GaugeChartData;

/**
 * A class to fill with data in metric service to allow us to track different capabilities for a metric
 */
export class MetricServiceDataStore<T extends FrontendSupportedMetricType = ChartData[]> extends TDMSBase {
  isCalculating: boolean = true;

  /**
   * The original data the backend gave us
   */
  data?: T;

  /**
   * Bookmarks that should be rendered for this metric
   */
  bookmarks: Bookmark[] = [];

  constructor(data?: T, bookmarks: Bookmark[] = [], isCalculating = true) {
    super();
    this.data = data;
    this.bookmarks = bookmarks;
    this.isCalculating = isCalculating;
  }

  /**
   * Given a chart configuration and the data, converts it into it's specific format dynamically
   */
  static convertDataTyping(config: ChartConfiguration, data: MetricPluginReply) {
    let returnData: FrontendSupportedMetricType;
    switch (config.type) {
      case "waveform":
      case "line":
        returnData = LineChartData.fromPlainArray(data.payload.data as LineChartData[]);
        break;
      case "vertical-bar":
        returnData = BarChartData.fromPlainArray(data.payload.data as BarChartData[]);
        break;
      case "time-series-horizontal-bar":
        returnData = TimeSeriesBarChartData.fromPlainArray(data.payload.data as TimeSeriesBarChartData[]);
        break;
      case "gauge":
        returnData = GaugeChartData.fromPlain(data.payload.data as GaugeChartData);
        break;
      case "pie":
        returnData = PieChartData.fromPlain(data.payload.data as PieChartData);
        break;
      default:
        throw new Error(`Unsupported chart type received for processing.`);
    }
    return returnData;
  }

  /** Returns if this chart has data ready to display */
  get dataIsNotAvailable(): boolean {
    // Centralized as chart base needs the same functionality
    return ChartBaseComponent.getChartDataIsNotAvailable<MetricServiceDataStore<any>>(this);
  }

  /**
   * Returns the series specific bookmarks that should be shown based on legend data
   */
  static getEnabledBookmarks(bookmarks: Bookmark[] | undefined, legendData: LegendDisplayObject[]) {
    return bookmarks?.filter((bookmark) => {
      if (!bookmark.excludeWhenMissingName) return true;
      else {
        const excludeWhenMissing = legendData.find((x) => x.name === bookmark.excludeWhenMissingName);
        const associatedRole = legendData.find((x) => x.name === bookmark.associatedRoleName);
        if (associatedRole == null && excludeWhenMissing == null) return true; // Include if no matching association
        else
          return (
            (excludeWhenMissing == null ? true : excludeWhenMissing.displayed) &&
            (associatedRole == null ? true : associatedRole.displayed)
          );
      }
    });
  }
}

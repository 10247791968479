import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AudioDashboardComponent } from "@tdms/frontend/modules/audio/components/dashboard/audio.dashboard.component";
import { AudioGuard } from "@tdms/frontend/modules/audio/guard/audio.guard";
import { DataStoreDashboardComponent } from "@tdms/frontend/modules/data-store/components/dashboard/dashboard.component";
import { BackgroundUploadComponent } from "@tdms/frontend/modules/data-store/components/upload/background/background.component";
import { UploadCreateSessionComponent } from "@tdms/frontend/modules/data-store/components/upload/pipeline/create/create.component";
import { UploadCurrentSessionComponent } from "@tdms/frontend/modules/data-store/components/upload/pipeline/current/current.component";
import { OneOffUploadComponent } from "@tdms/frontend/modules/data-store/components/upload/pipeline/one-off/one-off.component";
import { UploadSelectionComponent } from "@tdms/frontend/modules/data-store/components/upload/selection/selection.component";
import { MetricDashboardComponent } from "@tdms/frontend/modules/metrics/components/dashboard/dashboard.component";
import { SessionSummaryComponent } from "@tdms/frontend/modules/session-summary/components/dashboard/dashboard.component";
import { SessionComparisonComponent } from "@tdms/frontend/modules/session/components/session-comparison/session-comparison.component";
import { SessionSelectorComponent } from "@tdms/frontend/modules/session/components/session-selector/session-selector.component";
import { SessionSelectedGuard } from "@tdms/frontend/modules/session/guard/session.selected.guard";
import { LoginComponent } from "@tdms/frontend/modules/user/components/login/login.component";
import { UserManagementComponent } from "@tdms/frontend/modules/user/components/user-management/user-management.component";
import { AuthGuard } from "@tdms/frontend/modules/user/guard/auth.guard";
import { PermissionGuard } from "@tdms/frontend/modules/user/guard/permission.guard";
import { EnabledGuard } from "../guard/enabled.guard";
import { RouteConfig } from "./route.config";
import { RouteGroupConfig } from "./route.group.config";
import { Route_URLs } from "./url";

/** Central typing to help define how we can format routes */
export type SupportedRouteTyping = RouteConfig | RouteGroupConfig;

/** This generic function uses the given component and locates the can deactivate function to determine how to handle redirects that still have data.*/
async function uploaderDeactivate(
  component: UploadCreateSessionComponent | UploadCurrentSessionComponent | OneOffUploadComponent,
  _a: ActivatedRouteSnapshot,
  _b: RouterStateSnapshot,
  nextState: ActivatedRouteSnapshot
) {
  return await component.uploader?.canDeactivate(nextState.url as any);
}

/** SideNav configuration for Team Dynamics. Some configuration values in here may not contain paths so make sure to handle that. */
export const TDMS_ROUTES: SupportedRouteTyping[] = [
  // Floating Options
  { path: Route_URLs.login, component: LoginComponent },
  // Session Content
  {
    sidenavOptions: {
      title: "Session",
      disabledTooltip: function () {
        if (!this.serviceManager.onServicesLoadedSession.value) return "Session is loading...";
        else if (this.currentSession?.isProcessing && this.sessionService.regenerationStatus.value != null)
          return "Please wait while the session completes regenerating";
        else if (this.currentSession?.isProcessing) return "Please wait while the session finishes processing";
        else return "Please select a session first";
      },
      enabled: function () {
        return (
          this.currentSession != null &&
          this.serviceManager.onServicesLoadedSession.value &&
          !this.currentSession.isProcessing
        );
      },
    },
    configs: [
      {
        sidenavOptions: {
          icon: "edit",
          title: "Edit",
          ignoreParentOptions: function () {
            if (this.sessionService.regenerationStatus.value != null) return true;
            else return false;
          },
          clickOverride: function (event) {
            this.sessionService.editSession(this.currentSession, event);
          },
          spinner: function () {
            if (this.currentSession?.isProcessing && this.sessionService.regenerationStatus.value != null)
              return { text: "Session is regenerating", shouldSpin: true };
            else return undefined;
          },
        },
      },
      {
        path: Route_URLs.sessionSelection,
        component: SessionSelectorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: Route_URLs.sessionSummary,
        component: SessionSummaryComponent,
        canActivate: [AuthGuard, SessionSelectedGuard, EnabledGuard],
        enabledGuardCheck: (configService) =>
          configService.configData == null ? false : configService.pluginIsEnabled("SessionSummary"),
        sidenavOptions: {
          icon: "summarize",
          title: "Summary",
        },
      },
      {
        path: Route_URLs.dashboard,
        component: MetricDashboardComponent,
        canActivate: [AuthGuard, SessionSelectedGuard],
        sidenavOptions: {
          icon: "dashboard",
          title: "Dashboard",
        },
      },
      {
        path: Route_URLs.communication,
        component: AudioDashboardComponent,
        canActivate: [AuthGuard, SessionSelectedGuard, EnabledGuard, AudioGuard],
        enabledGuardCheck: (configService) =>
          configService.configData == null
            ? false
            : configService.pluginIsEnabled("Audio") && configService.pluginIsEnabled("DataStore"),
        sidenavOptions: {
          icon: "speaker_notes",
          title: "Communication",
          disabledTooltip: function () {
            if (!this.configService.pluginIsEnabled("Audio") || !this.configService.pluginIsEnabled("DataStore"))
              return `Audio is disabled`;
            else if (!this.audioService.hasTranscript && this.audioService.audioFile == null)
              return "No audio data was associated to this session";
            else if (!this.audioService.hasTranscript) return "No transcription data available for this session";
            else return "No audio files available for this session";
          },
          enabled: function () {
            return this.audioService.audioFile != null || this.audioService.hasTranscript;
          },
        },
      },
    ],
  },
  // Other Content
  {
    sidenavOptions: {
      title: "Other",
    },
    configs: [
      {
        path: Route_URLs.sessionComparison,
        component: SessionComparisonComponent,
        canActivate: [AuthGuard],
        sidenavOptions: {
          icon: "difference",
          title: "Comparison",
        },
      },
      {
        path: Route_URLs.dataManagement,
        component: DataStoreDashboardComponent,
        canActivate: [AuthGuard, EnabledGuard],
        enabledGuardCheck: (configService) => configService.pluginIsEnabled("DataStore"),
        sidenavOptions: {
          icon: "storage",
          title: "Data Store",
          disabledTooltip: "Data Store Management is disabled",
        },
      },
    ],
  },
  // Admin Content
  {
    sidenavOptions: {
      title: "Admin",
    },
    configs: [
      {
        path: Route_URLs.userManagement,
        component: UserManagementComponent,
        canActivate: [AuthGuard, PermissionGuard],
        requiredPermissions: ["userManagement"],
        sidenavOptions: {
          icon: "group",
          title: "User Control",
          hideIfDisabled: true,
        },
      },
    ],
  },
  //// Uploader pages for the data store
  {
    path: Route_URLs.uploadPipeline,
    customPathName: "Upload Selection",
    component: UploadSelectionComponent,
    sidenavOptions: {
      // This is not rendered automatically but this configuration will be the one used
      hide: true,
      title: "Upload",
      icon: "upload",
      spinner: function () {
        if (this.uploadService.currentlyUploadingRequests.length > 0)
          return { text: "Uploads are running", shouldSpin: true };
        else return undefined;
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: Route_URLs.uploadCreate,
    customPathName: "Session Creation",
    component: UploadCreateSessionComponent,
    canActivate: [AuthGuard],
    canDeactivate: [uploaderDeactivate],
  },
  {
    path: Route_URLs.uploadCurrent,
    customPathName: "Upload To Current Session",
    component: UploadCurrentSessionComponent,
    canActivate: [AuthGuard, SessionSelectedGuard],
    canDeactivate: [uploaderDeactivate],
  },
  {
    path: Route_URLs.uploadOneOff,
    customPathName: "One Off Upload",
    component: OneOffUploadComponent,
    canActivate: [AuthGuard],
    canDeactivate: [uploaderDeactivate],
  },
  {
    path: Route_URLs.backgroundUploads,
    customPathName: "Background Uploads",
    component: BackgroundUploadComponent,
    canActivate: [AuthGuard],
  },
];

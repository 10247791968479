import { Type } from "class-transformer";
import { v4 } from "uuid";
import { TDMSBase } from "./base";

/** A request an aggregate file so it can be parsed **/
export class AggregateFileRequest extends TDMSBase {
  sheets: { sheetName: string; previewRows: any[][] }[] = [];
  fileName?: string;
  selectedSheet?: string;
  headerRow?: number;
  selectedHeader?: string;
  processAllSheets?: boolean;
}

/** A response to the aggregate file request that we'd like to parse*/
export class AggregateFileResponse extends TDMSBase {
  /** The result mappings that we consider to be clean and ready to go */
  @Type(() => AggregateFile)
  header: string[] = [];
  /** Tracks if the user completes or cancels. False is cancels, true is completes. We cancel uploads on false. */
  complete: boolean = false;
  selectedSheet?: string;
  headerRow?: number;
  selectedHeader?: string;
  processAllSheets?: boolean;
}

/** The base set of information required for an aggregate file. Requests will fill this out further */
export class AggregateFile extends TDMSBase {
  /** Unique ID associated to this aggregate file. Used to realign the parsed data after user responses */
  uniqueId = v4();

  header: string[];

  constructor(header: string[]) {
    super();
    this.header = header;
  }
}

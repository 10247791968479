import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * A synchronous validator function that checks if a control's value is present in an array of forbidden values.
 *
 * @param values - An array of forbidden values.
 * @param caseInsensitive - If true (default) always lower cases everything.
 * @returns A `ValidatorFn` that validates the control against the provided forbidden values.
 *
 * @example
 * ```typescript
 * this.myForm = this.formBuilder.group({
 *   myField: ['', [forbiddenValuesValidator([1, 2, 3])]]
 * });
 * ```
 */
export function forbiddenValuesValidator(values: string[], caseInsensitive = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = (control.value as string).trim();
    // Handle case sensitivity
    if (caseInsensitive) {
      value = value.toLowerCase();
      values = values.map((x) => x.toLowerCase());
    }
    if (values.includes(value)) return { forbiddenValue: { value: control.value } };
    return null;
  };
}

<data-store-generic-upload
  #uploader
  *ngIf="selectedOption"
  [steps]="steps"
  uploadMode="exclude-session-creation"
  [selectedUploadOption]="selectedOption"
  [specialUploadType]="$any(selectedOption.name)"
  [centeredSteps]="['Intro', 'Complete']"
>
  <!-- Initial Step -->
  <data-store-generic-upload-step step="Intro">
    <ng-template #content>
      <div class="help">
        <h1 class="title">{{ selectedOption.friendlyName ?? selectedOption.name }}</h1>
        <p class="description">{{ selectedOption.description }}</p>
        <shared-img-wrapper class="image" src="../../../../../assets/upload/uploadOptimized.png"></shared-img-wrapper>
        <p class="description">
          This process allows you to upload a file, TDMS will make modifications or produce another file based on that
          content and then allow you to immediately download it.
        </p>
        <p class="description">Please click next to continue.</p>
        <button mat-raised-button (click)="uploader.stepper.nextStep()" color="accent" class="next">Next</button>
      </div>
    </ng-template>
  </data-store-generic-upload-step>

  <!-- Complete step -->
  <data-store-generic-upload-step step="Complete">
    <ng-template #content>
      <div class="help">
        <h1 class="title">File processed successfully</h1>
        <data-store-upload-check></data-store-upload-check>
        <p class="description">
          The requested file was successfully processed. Please check your downloads for the resulting file. Press
          continue to return to the uploader.
        </p>
      </div>
      <!-- Exit button -->
      <button mat-raised-button (click)="uploader.redirectToUpload()" color="accent" class="next">Continue</button>
    </ng-template>
  </data-store-generic-upload-step>
</data-store-generic-upload>

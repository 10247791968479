<shared-dialog-wrapper
  header="Bulk Data Download"
  [showCloseButton]="false"
  [closeDialogCallback]="disableDialogClosing"
  [backdropClickCallback]="disableDialogClosing"
>
  <shared-div-progress-component
    [ready]="false"
    [notReadyMessage]="properties.statusMessage"
    [progress]="currentProgress"
    class="progress-bar"
    [spinnerDiameter]="160"
  ></shared-div-progress-component>
</shared-dialog-wrapper>

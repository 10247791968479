import { Type } from "class-transformer";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../base";
import { SessionDomain } from "./domain";

/** Each keyword can be identified and related to a specific grouping. This helps better isolate what keywords may go together. */
export enum KeywordGroup {
  /** Used for overarching team keywords */
  "Team Management",
  /** Used more specifically in the medical domain for vitals */
  "Vital Sign",
}

/**
 * This class defines a keyword that will be used within a session for identifying data that should
 *  be bookmarked from it's transcript.
 */
export class Keyword extends TDMSBase {
  /** The name the bookmark should use for the keyword type. */
  static readonly BOOKMARK_TYPE_NAME = "Keyword";

  /** Unique identifier */
  id!: number;
  /** The text we are trying to find for this keyword. You should use the constructor so it can be auto lower cased. */
  text: string;
  /** The grouping that this keyword is related to */
  group: KeywordGroup;
  /** The domain that these keywords correlate to */
  @decorate(Type(() => SessionDomain))
  domain: SessionDomain;

  constructor(text: string, group: KeywordGroup, domain: SessionDomain) {
    super();
    this.text = text?.toLowerCase();
    this.group = group;
    this.domain = domain;
  }

  /**
   * Returns keywords specific to the medical domain.
   * @param domain The medical domain object to link to. We'll need this to know it's DB ID.
   */
  static getMedicalKeywords(domain: SessionDomain = SessionDomain.defaults.medical) {
    const createTeam = (name: string) => new Keyword(name, KeywordGroup["Team Management"], domain);
    const createVital = (name: string) => new Keyword(name, KeywordGroup["Vital Sign"], domain);
    return [
      // Team Management
      createTeam("Eyes on me"),
      createTeam("Heads up"),
      createTeam("Time out"),
      createTeam("Huddle"),
      createTeam("Team"),
      createTeam("Review"),
      createTeam("Summary"),
      // Vital Signs
      createVital("Vitals"),
      createVital("Vital signs"),
      createVital("Monitor"),
      createVital("Ventilator"),
      createVital("Vent"),
      createVital("Heart rate"),
      createVital("Respiratory rate"),
      createVital("Temperature"),
      createVital("Temp"),
      createVital("Rhythm"),
      createVital("Blood pressure"),
      createVital("Systolic"),
      createVital("Diastolic"),
      createVital("MAP"),
      createVital("Mean pressure"),
      createVital("Systolic blood pressure"),
      createVital("Systolic pressure"),
      createVital("Diastolic blood pressure"),
      createVital("Diastolic pressure"),
      createVital("CPP"),
      createVital("ICP"),
      createVital("Cerebral perfusion pressure"),
      createVital("Intracranial pressure"),
      createVital("Oxygen"),
      createVital("O2"),
      createVital("Sats"),
      createVital("Saturation"),
      createVital("Pulse"),
      createVital("BP"),
      createVital("Oxygen saturation"),
    ];
  }
}

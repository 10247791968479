<data-store-generic-upload
  #uploader
  uploadMode="exclude-session-creation"
  [steps]="steps"
  [sessionId]="currentSession?.id"
  (onFilesSelected)="onUploaderFileChange($event)"
  [hideMetadataTillFile]="false"
  [isDelayedSessionCreation]="delayedDataControl.value ?? false"
  [centeredSteps]="['Intro', 'Complete']"
>
  <!-- Initial Step -->
  <data-store-generic-upload-step step="Intro">
    <ng-template #content>
      <div class="help">
        <h1 class="title">Upload To: {{ currentSession?.name }}</h1>
        <shared-img-wrapper class="image" src="../../../../../assets/upload/uploadOptimized.png"></shared-img-wrapper>
        <p class="description">This process will allow you to upload a file to the currently selected session.</p>
        <p class="description">Please click next to continue.</p>
        <button mat-raised-button (click)="uploader.stepper.nextStep()" color="accent" class="next">Next</button>
      </div>
    </ng-template>
  </data-store-generic-upload-step>

  <!-- Complete step -->
  <data-store-generic-upload-step step="Complete">
    <ng-template #content>
      <div class="help">
        <h1 class="title">File successfully uploaded</h1>
        <data-store-upload-check></data-store-upload-check>
        <p class="description">
          The requested file was successfully uploaded. Press continue to be taken back to the uploader home.
        </p>
      </div>
      <!-- Exit button -->
      <button mat-raised-button (click)="uploader.redirectToUpload()" color="accent" class="next">Continue</button>
    </ng-template>
  </data-store-generic-upload-step>

  <!-- Template to render more data within the uploader under the metadata -->
  <ng-template #uploadMetadata>
    <div class="internal-metadata">
      <!-- Render two columns, if applicable -->
      <div class="left">
        <!-- Render the file tree of what files are available already -->
        <div class="help">
          <p class="description">List of files currently associated to this session</p>
        </div>
        <data-store-file-tree
          class="file-tree"
          [files]="displayFiles"
          [deleteCallback]="onUploadedFileDelete"
        ></data-store-file-tree>
      </div>
      <!-- Render some selectable metadata -->
      <div class="right" *ngIf="displayDelayedData">
        <!-- Render the options for delayed session data to be added to a scratch session -->
        <div class="help">
          <p class="description">Extra options that may apply to this upload</p>
        </div>
        <!-- Checkbox for identifying data files post session creation. This will only display in the event we have support for createSession handlers.-->
        <div class="delayed-data-creation">
          <mat-checkbox [formControl]="delayedDataControl" class="check" (change)="updateSessionCreatedState()">
            Treat as Session Data?
          </mat-checkbox>
          <mat-hint class="hint">
            Checking this box will allow this file uploaded to act like the data file to populate metrics.
          </mat-hint>
        </div>
      </div>
    </div>
  </ng-template>
</data-store-generic-upload>

import { Role } from "../../../../session-data";
import { TableColumnConfiguration, TableConfiguration } from "../../../../settings/table/settings";
import { DataStoreFile } from "../../data.store";
import { SearchEntry } from "../../search";
import { DataStoreSettings } from "../settings";

/** This configuration defines table options related to the Search table within the data store */
export class SearchTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: DataStoreSettings.PLUGIN_NAME,
    settingName: DataStoreSettings.Names.searchVisibleColumns,
  };

  static override readonly Columns = {
    SessionName: new TableColumnConfiguration<SearchEntry, string>("sessionName"),
    FileName: new TableColumnConfiguration<SearchEntry, string>("filename"),
    GenericFileName: new TableColumnConfiguration<SearchEntry, DataStoreFile>("file", "Generic Filename", false),
    StartDate: new TableColumnConfiguration<SearchEntry, Date>("startDate"),
    EndDate: new TableColumnConfiguration<SearchEntry, Date>("endDate", "End Date"),
    Users: new TableColumnConfiguration<SearchEntry, { role: Role; guid: string }[]>("users", undefined, false),
  };
}

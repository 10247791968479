import { Keyword, KeywordGroup } from "../../session-data";
import { TableColumnConfiguration, TableConfiguration } from "../../settings/table/settings";

/** This namespace contains settings related to rendering the keyword table */
export class KeywordTableConfiguration extends TableConfiguration {
  static override readonly Columns = {
    Text: new TableColumnConfiguration<Keyword, string>("text"),
    Group: new TableColumnConfiguration<Keyword, KeywordGroup>("group"),
  };
}

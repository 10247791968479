<div class="communication-container">
  <!-- Display the waveform -->
  <audio-track #audioTrack class="audio-track"></audio-track>

  <!-- Display our table with the transcription capabilities -->
  <data-store-uploader-progress
    [shouldDisplayStatus]="transcriptions.length === 0 && !audioService.failureToGenerate"
    [status]="refreshStatus"
    class="communication-container"
  >
    <shared-editable-table
      class="transcription-table"
      tableName="Communication"
      [data]="transcriptions"
      [defaultSortHeader]="'start'"
      [displayedColumns]="displayedColumns"
      [clickCallback]="seekTo"
      defaultHeaderSortDirection="asc"
      [activeRows]="activeRows"
      [editConfigEnable]="'audio.allowEditing'"
      (onEditStarted)="batchEdit()"
      (onChangesCancelled)="cancelChanges()"
      [preChangesSaved]="preChangesSaved"
      (onChangesSaved)="saveChanges()"
      saveTooltip="Save transcription changes"
      cancelTooltip="Cancel transcription changes"
      [hasChanged]="hasChanged"
      [editsValid]="inputsValid"
      [paginatorPageSize]="25"
      [paginatorPageSizeOptions]="[25, 50, 100]"
      [shouldGuessPaginatorPageSize]="false"
      [columnVisibilitySettingConfig]="columnVisibilitySetting"
      [paginationButtonsEnabled]="!autoScrollSetting.value || (autoScrollSetting.value && bgAudioPlayer.isPaused)"
      paginationDisabledReasoning="Pagination is disabled during playback as the table will autoscroll to the current speech."
      (onScroll)="updateAutoScrollSetting(false)"
    >
      <cell-editor [columns]="['prettySpeaker']">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <shared-improved-select
            #select
            class="speaker-select"
            [selectedValue]="value"
            (selectedValueChanged)="speakerUpdate(element, $event)"
            [dropdownOptions]="uniqueSpeakers"
            appearance="standard"
            [filterEnabled]="false"
            [paginatorEnabled]="false"
            [clearable]="false"
            [shouldCleanName]="false"
            [allowMultipleSelections]="false"
            prefixOptionIcon="add"
            prefixOptionTooltip="Opens a dialog to add a new speaker identity"
            prefixOptionTooltipPosition="right"
            prefixOptionText="Speaker Identity"
            (prefixOptionCallback)="openNewSpeakerIdentity(element, select)"
          >
          </shared-improved-select>
        </ng-template>
      </cell-editor>

      <!-- CLC Checkmark -->
      <cell-display [columns]="['isCLC']">
        <ng-template #display let-value="value" let-element="element" let-column="column">
          <ng-container *ngIf="value">
            <mat-icon matTooltip="This utterance was identified as a Closed Loop Communication">check</mat-icon>
          </ng-container>
        </ng-template>
      </cell-display>

      <!-- On text display for utterance, show the confidence percentage -->
      <cell-display [columns]="['text']" *ngIf="!isEditing">
        <ng-template #display let-value="value" let-column="column" let-element="element">
          <span matTooltipPosition="before" [matTooltip]="'ASR Confidence: ' + confidenceToString(element.confidence)">
            {{ value }}
          </span>
        </ng-template>
      </cell-display>

      <!-- Or, during editing, allow for some custom display/styling -->
      <cell-editor [columns]="['text']" *ngIf="isEditing">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <cell-text-editor
            [column]="column"
            [value]="value"
            (onValueSubmitted)="saveChanges()"
            (onValueChanged)="transcriptionUpdate(element, column.name, $any($event))"
            class="transcription-content-form-field"
          >
          </cell-text-editor>
        </ng-template>
      </cell-editor>

      <!-- On classification display, show the confidence percentage -->
      <cell-display [columns]="['classification']">
        <ng-template #display let-value="value" let-column="column" let-element="element">
          <span
            matTooltipPosition="before"
            [matTooltip]="'Classification Confidence: ' + confidenceToString(element.classificationConfidence)"
          >
            {{ value }}
          </span>
        </ng-template>
      </cell-display>

      <cell-editor [columns]="['start', 'end']">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <cell-text-editor
            [column]="column"
            [value]="value"
            (onValueSubmitted)="saveChanges()"
            [isValid]="inputValid(element, column.name)"
            (onValueChanged)="transcriptionTimeUpdate(element, column.name, $event)"
          >
          </cell-text-editor>
        </ng-template>
      </cell-editor>

      <!-- Custom end buttons -->
      <ng-template #extraHeaderButtons>
        <!-- Reset transcription -->
        <button
          mat-raised-button
          color="accent"
          [disabled]="isEditing || audioService.audioFile == null"
          (click)="refreshTranscriptions()"
          [matTooltip]="
            audioService.audioFile == null
              ? 'No audio file to refresh with'
              : 'Deletes the current transcription and requests a new one to be generated'
          "
          matTooltipPosition="below"
          [configDisable]="'audio.allowRefresh'"
          configDisabledStateMessage="Refresh is disabled"
        >
          <mat-icon>restart_alt</mat-icon> Refresh Transcription
        </button>
        <!-- Autoscroll button -->
        <button mat-raised-button color="accent" class="autoscroll-button checkbox-white">
          <mat-checkbox
            (change)="updateAutoScrollSetting($event.checked)"
            [checked]="autoScrollSetting.value"
            matTooltip="This button controls if the table should auto scroll and navigate to the page of the current utterance on playback. Any user interaction will disable this."
            matTooltipPosition="above"
            >Autoscroll
          </mat-checkbox>
        </button>
      </ng-template>
    </shared-editable-table>
  </data-store-uploader-progress>
</div>

import { Injectable } from "@angular/core";
import { MemoizedSelector, Store } from "@ngrx/store";
import {
  AudioSettings,
  AudioWaveformSettings,
  Bookmark,
  ChartConfiguration,
  filterNulls,
  MetricBaseSettings,
  Session,
} from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { BookmarkService } from "@tdms/frontend/modules/bookmark/services/bookmark.service";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import {
  selectMetricConfiguration,
  selectMetricDataForCurrentSession,
  selectMetricDataForGivenSession,
} from "@tdms/frontend/modules/metrics/store/metric.selector";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { selectCurrentSession, selectSessionFromId } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { selectSettingValues } from "@tdms/frontend/modules/settings/store/settings.selector";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  of,
  startWith,
  switchMap,
} from "rxjs";
import { LegendService } from "./legend.service";
/**
 * The Metric Grid Service is responsible for generating the `MetricGridDataStore` objects that the Metric Grid needs in order to display it's data.
 * It's primary function is to bridge the gap between ChartConfiguration and `MetricServiceDataStore`
 * to orchestrate the data for the low level components appropriately.
 */
@Injectable({ providedIn: "root" })
export class MetricGridService extends Service {
  constructor(
    private store: Store<MetricState>,
    private themeService: ColorThemeService,
    private sessionService: SessionService,
    private settingsService: PluginSettingsService,
    private legendService: LegendService,
    private audioService: AudioService,
    private configService: ConfigService,
    private bookmarkService: BookmarkService
  ) {
    super();
  }

  /**
   * Create an observable that returns the latest `MetricGridDataStore` populated with all the requisite data as it loads.
   * the observable first emits an empty data store with no requisite fields, but mutates the fields and emits as the data becomes available.
   * NOTE: If you provide extra bookmarks, MAKE SURE they emit an event at least once! If not, the bookmark observable will never emit
   * and thus even the standard bookmarks won't render on the metric card.
   * @param extraBookmarks Extra bookmarks to include in our render
   * @param getTimeline If we should apply a timeline or not. Default is true.
   * @returns A stream containing the latest metric grid data available.
   */
  public async observeMetricDataForSession(
    configurations: ChartConfiguration[],
    session = this.sessionService.currentSession!,
    extraBookmarks?: Observable<Bookmark[]>,
    getTimeline = true
  ): Promise<MetricGridDataStore> {
    const sessionObservable = this.store.select(selectSessionFromId(session.id));
    const sessionStartObservable = sessionObservable.pipe(filterNulls).pipe(map((x) => x.startDate));
    const sessionEndObservable = sessionObservable.pipe(filterNulls).pipe(map((x) => x.endDate));
    // Create an observable for metric plugin based bookmarks
    const sessionBookmarkObservable = combineLatest([
      this.store.select(selectCurrentSession),
      this.store.select(selectSettingValues),
    ]).pipe(
      switchMap(async (x) => {
        const session = x[0];
        const bookmarks: Bookmark[] = [];
        if (session) {
          // Grab plugin specifics
          const overarchingBookmarks = await this.bookmarkService.getOverarchingFromPlugins(session);
          if (overarchingBookmarks.length) bookmarks.push(...overarchingBookmarks);
          // Add any others
          if (session.bookmarks) bookmarks.push(...session.bookmarks);
        }
        return bookmarks;
      })
    );
    const bookmarkObservable = extraBookmarks
      ? combineLatest([sessionBookmarkObservable, extraBookmarks]).pipe(map((x) => [...x[0], ...x[1]]))
      : sessionBookmarkObservable;

    const rolesBasedLegend = this.legendService.observableToBehavior(
      this.legendService.getRoleBasedLegendWithStore(session),
      this.legendService.getRoleBasedLegend(session)
    );

    // Handle no existing configurations
    if (configurations.length === 0)
      return new MetricGridDataStore([], of(false), sessionStartObservable, sessionEndObservable, bookmarkObservable);

    /// Create an empty array to contain all the observables we are going to create.
    const observables = [];

    // Find the metric that should be tied to the timeline and append it to our list of configurations.
    let timelineObservable: Observable<MetricCardDataStore | undefined> = of(undefined);
    if (getTimeline) {
      const timelineConfig = this.findTimelineMetricConfig(configurations);
      if (timelineConfig)
        timelineObservable = timelineConfig.pipe(
          switchMap((config) => {
            if (!config) return of(undefined);
            else return this.createObservableFor(config, session, rolesBasedLegend, true);
          })
        );
    }

    for (let configuration of configurations)
      observables.push(
        this.createObservableFor(
          configuration,
          session,
          configuration.useRoleBasedLegend ? rolesBasedLegend : undefined
        )
      );

    return new MetricGridDataStore(
      observables,
      of(true),
      sessionStartObservable,
      sessionEndObservable,
      bookmarkObservable,
      timelineObservable
    );
  }

  /**
   * Utility function to get the current metric configurations from the store.
   * @returns A promise of the List of ChartConfigurations.
   */
  public getMetricConfigurations() {
    return firstValueFrom(this.store.select(selectMetricConfiguration));
  }

  /**
   * Looks up a metric from the given set of configurations that should be utilized as the timeline metric. This will support trying to use the audio waveform
   *  if enabled. Else we'll default to the adaptation chart.
   * @param configurations The available chart configurations.
   * @param considerWaveform If the waveform should even be considered for this timeline metric. Also disables some settings if set to false.
   * @returns An observable of what metric should be used. Considers some settings to help increase the timeline options.
   */
  findTimelineMetricConfig(configurations: ChartConfiguration[], considerWaveform = true) {
    return this.settingsService
      .observeSetting(AudioWaveformSettings.PLUGIN_NAME, AudioWaveformSettings.Names.useWaveform)
      .pipe(
        map((x) => x.value),
        startWith(false)
      )
      .pipe(
        map((useWaveform) => {
          // Default is adaptation
          let timelineConfig = configurations.find((configuration) => configuration.metricName === "Adaptation");
          // If we have an audio file, see if we can use the waveform
          const audioAvailable = this.configService.pluginIsEnabled("Audio") && this.audioService.audioFile;
          if (audioAvailable && useWaveform && considerWaveform)
            timelineConfig = configurations.find((configuration) => configuration.metricName === "AudioWaveform");
          // If we still don't have a config, return undefined to break out
          if (!timelineConfig) return undefined;
          // Store will give us readonly values so just in case
          timelineConfig = timelineConfig.clone();
          // Update some defaults
          timelineConfig.title = "Timeline";
          timelineConfig.showXAxis = false;
          timelineConfig.showYAxis = false;
          timelineConfig.showHelp = false;
          timelineConfig.exportAllowed = false;
          timelineConfig.supportsSelectionZone = false;
          timelineConfig.additionalSettings = [];
          // Support toggling transcription display if audio is enabled
          if (audioAvailable && considerWaveform)
            timelineConfig.additionalSettings.push({
              plugin: AudioSettings.PLUGIN_NAME,
              settingName: AudioSettings.Names.transcriptionDisplayed,
            });
          // Support toggling the chart we want to display
          if (this.configService.pluginIsEnabled("AudioWaveform") && considerWaveform)
            timelineConfig.additionalSettings.push({
              plugin: AudioWaveformSettings.PLUGIN_NAME,
              settingName: AudioWaveformSettings.Names.useWaveform,
            });
          return timelineConfig;
        })
      );
  }

  /**
   * Create a metric data observable for the given chart configuration, along with a custom legend generator that should be used.
   * @param configuration The chart configuration to observe.
   * @param isTimeline If this is the timeline configuration or not. Setting to true will cause bookmarks to always be displayed.
   * @param legend The legend to render for the chart within the subject. If nothing is given, a data based legend will be generated.
   * @returns The created observable that will provide data updates for that metric.
   */
  public createObservableFor(
    configuration: ChartConfiguration,
    session?: Session,
    legend?: BehaviorSubject<LegendDisplay[]>,
    isTimeline = false
  ) {
    let selector: MemoizedSelector<MetricState, MetricServiceDataStore<any> | undefined>;

    if (session == null) selector = selectMetricDataForCurrentSession(configuration);
    else selector = selectMetricDataForGivenSession(configuration, session.id);

    /// Create an observable for the metric matching this configuration.
    const baseObservable = combineLatest([
      this.store.select(selector).pipe(filterNulls),
      this.store.select(selectSessionFromId(session?.id)),
    ]);

    // Build a legend in the event we wanted data based legend
    if (legend == null)
      legend = this.legendService.observableToBehavior(
        baseObservable.pipe(map((data) => this.legendService.getDataBasedLegend(data[0].data))),
        []
      );

    return baseObservable
      .pipe(
        map((data) => {
          const metric = data[0].clone();
          const session = data[1];
          if (session != null && session.isProcessing) metric.isCalculating = true;
          // Map the color map for the selected metric data.
          let colorMap = this.themeService.observeColorsFor(metric.data);
          // Combine the legend to the color map observable, incase our data is not role based but the colors are needed.
          if (legend) {
            const legendObservable = this.legendService.observeColors(legend);
            // Update the color map to combine them
            colorMap = combineLatest([colorMap, legendObservable]).pipe(map((x) => [...x[0], ...x[1]]));
          }
          const enabledObservable = this.getChartEnabledObservable(configuration);
          const bookmarkEnabledObservable = this.getBookmarkEnabledObservable(configuration);
          return new MetricCardDataStore(
            metric,
            configuration,
            colorMap,
            enabledObservable,
            bookmarkEnabledObservable,
            legend,
            isTimeline
          );
        })
      )
      .pipe(filterNulls);
  }

  /**
   * Returns an observable that tracks the enabled status of the bookmarks displayed setting.
   * @param configuration The configuration for the chart to track
   */
  getBookmarkEnabledObservable(configuration: ChartConfiguration): Observable<boolean> {
    return this.settingsService.observeSettingValue(
      configuration.metricName,
      MetricBaseSettings.Names.bookmarksDisplayed
    );
  }

  /**
   * Returns an observable for tracking the enabled status of a chart based on settings changes. We also utilize the dashboard configuraiton
   *  of the chart config to see if it should be displayed.
   * @param configuration The configuration for the chart to track so we can get the metric name.
   */
  getChartEnabledObservable(
    configuration: ChartConfiguration,
    setting = MetricBaseSettings.Names.chartEnabled
  ): Observable<boolean> {
    return from(this.settingsService.hasSetting(configuration.metricName, setting)).pipe(
      mergeMap((hasSetting) => {
        if (hasSetting)
          return this.settingsService
            .observeSettingValue<boolean>(configuration.metricName, setting)
            .pipe(map((z) => z && configuration.dashboard.enabled));
        else return of(configuration.dashboard.enabled);
      })
    );
  }
}

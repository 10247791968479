import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Keyword, KeywordTopics, TDMSWebSocketMessage, WebSocketCommunication } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { KeywordActions } from "@tdms/frontend/modules/keyword/model/store/keyword.action";
import { KeywordState } from "@tdms/frontend/modules/keyword/model/store/keyword.state";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";

/** This service provides keyword manipulation options as requested */
@Injectable({ providedIn: "root" })
export class KeywordService extends Service {
  constructor(private wsService: WebSocketService, private store: Store<KeywordState>, private dialog: MatDialog) {
    super();
  }

  override async onUserChanged(): Promise<void> {
    const response = await this.wsService.sendAndReceive(new TDMSWebSocketMessage(KeywordTopics.getAll));
    const data = Keyword.fromPlainArray(response.payload as any);
    this.store.dispatch(KeywordActions.empty({}));
    this.store.dispatch(KeywordActions.add({ keywords: data }));
  }

  /** Opens a dialog that checks if the user wants to delete the given keyword */
  openKeywordDeleteDialog(keyword: Keyword, cb?: Function) {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Delete Keyword",
        description: `Are you sure you want to delete this keyword? This cannot be undone! Any sessions that use this domain will also have this keyword deleted!`,
        confirmButtonText: "Confirm",
        confirmButtonColor: "warn",
        confirmClickCallback: () => {
          this.delete(keyword);
          cb?.call(this);
        },
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Creates a new keyword via the websocket */
  async create(keyword: Keyword) {
    const result = await this.wsService.sendAndReceive<Keyword>(
      new TDMSWebSocketMessage(KeywordTopics.add, undefined, keyword)
    );
    return Keyword.fromPlain(result.payload);
  }

  /** Listens for added keywords */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Keyword>>(KeywordTopics.add)
  async addReceived(data: TDMSWebSocketMessage<Keyword>) {
    this.store.dispatch(KeywordActions.add({ keywords: [Keyword.fromPlain(data.payload)] }));
  }

  /** Updates a keyword via the websocket */
  async update(keyword: Keyword) {
    const result = await this.wsService.sendAndReceive<Keyword>(
      new TDMSWebSocketMessage(KeywordTopics.update, undefined, keyword)
    );
    return Keyword.fromPlain(result.payload);
  }

  /** Listens for updated keywords */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Keyword>>(KeywordTopics.update)
  async updateReceived(data: TDMSWebSocketMessage<Keyword>) {
    this.store.dispatch(KeywordActions.update({ data: Keyword.fromPlain(data.payload) }));
  }

  /** Deletes a keyword via the websocket */
  async delete(keyword: Keyword) {
    const result = await this.wsService.sendAndReceive<Keyword>(
      new TDMSWebSocketMessage(KeywordTopics.remove, undefined, keyword)
    );
    return Keyword.fromPlain(result.payload);
  }

  /** Listens for deleted keywords */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Keyword>>(KeywordTopics.remove)
  async deleteReceived(data: TDMSWebSocketMessage<Keyword>) {
    this.store.dispatch(KeywordActions.delete({ data: Keyword.fromPlain(data.payload) }));
  }
}

<shared-dialog-wrapper
  class="upload-metadata-wrapper"
  header="Metadata Request"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="wrapper">
    <span class="instructions">
      Some additional metadata is required for processing. Please fill in the fields below.
    </span>

    <!-- Table-like display -->
    <form class="form-wrapper" [formGroup]="formGroup" (submit)="onSubmit()">
      <table class="metadata-table outer">
        <tr>
          <th>File</th>
          <th>Metadata</th>
        </tr>
        <tr class="group" *ngFor="let group of properties.request.groups">
          <td class="file-name">{{ group.fileName }}</td>
          <td>
            <table class="metadata-table">
              <tr>
                <th>Name</th>
                <th>Input</th>
              </tr>
              <!-- Render the options that need updated -->
              <tr *ngFor="let option of group.options" [ngSwitch]="option.internalType">
                <td class="input-name">{{ option.name }}</td>
                <td class="input">
                  <ng-container *ngVar="processingTracker.get(option) ?? false as isProcessing">
                    <shared-div-progress-component [ready]="!isProcessing" [spinnerDiameter]="40">
                      <!-- Number -->
                      <mat-form-field class="option" color="accent" *ngSwitchCase="'number'">
                        <mat-label>Number Input</mat-label>
                        <input matInput type="number" [formControlName]="getFieldName(option, group.fileName)" />
                        <mat-hint *ngIf="option.help">{{ option.help }}</mat-hint>
                      </mat-form-field>

                      <!-- String -->
                      <ng-component *ngIf="!option.dropdownOptions">
                        <mat-form-field class="option" color="accent" *ngSwitchCase="'string'">
                          <mat-label>String Input</mat-label>
                          <input matInput type="string" [formControlName]="getFieldName(option, group.fileName)" />
                          <mat-hint *ngIf="option.help">{{ option.help }}</mat-hint>
                        </mat-form-field>
                      </ng-component>

                      <!-- Boolean -->
                      <div class="option boolean" *ngSwitchCase="'boolean'">
                        <mat-checkbox [formControlName]="getFieldName(option, group.fileName)" class="check">
                        </mat-checkbox>
                        <mat-hint *ngIf="option.help" class="hint">{{ option.help }}</mat-hint>
                      </div>

                      <!-- Date -->
                      <div *ngSwitchCase="'Date'" class="option date">
                        <shared-date-time-picker
                          [control]="$any(formGroup.get(getFieldName(option, group.fileName)))"
                        ></shared-date-time-picker>
                        <mat-hint class="hint" *ngIf="option.help">{{ option.help }}</mat-hint>
                      </div>

                      <!-- Dropdown type. Requires a string type + dropdowns to be specified. -->
                      <ng-container *ngSwitchCase="'dropdown'">
                        <shared-improved-select
                          #select
                          class="dropdown option"
                          [control]="$any(formGroup.get(getFieldName(option, group.fileName)))"
                          [dropdownOptions]="option.dropdownOptions?.options || []"
                          [dropdownName]="option.dropdownOptions?.placeholder ?? 'Metadata Options'"
                          [filterEnabled]="true"
                          [paginatorEnabled]="true"
                          [itemsPerPage]="5"
                          [clearable]="false"
                          [shouldCleanName]="false"
                          [allowMultipleSelections]="false"
                          [hint]="option.help"
                          appearance="standard"
                        >
                        </shared-improved-select>
                      </ng-container>

                      <!-- If audio is provided, render the small player for it at the end -->
                      <ng-container *ngVar="audio.get(option) as audioData">
                        <shared-audio-player
                          *ngIf="option.audio && audioData"
                          #audioPlayer
                          class="audio-player"
                          [src]="audioData"
                        >
                        </shared-audio-player>
                      </ng-container>
                    </shared-div-progress-component>
                  </ng-container>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </form>

    <!-- Submit button for the metadata -->
    <button
      mat-raised-button
      color="accent"
      class="submit-button"
      (click)="onSubmit()"
      *ngVar="validityError as validityMessage"
      [disabled]="validityMessage != null"
      [matTooltip]="validityMessage ?? ''"
    >
      Submit
    </button>
  </div>
</shared-dialog-wrapper>

import { TDMSBase } from "../../base";
import { DataStoreFile } from "./data.store";

/**
 * This request asks the backend to generate a download URL for a download of data store file(s).
 */
export class DownloadRequest extends TDMSBase {
  /**
   * An id to denote this download request.
   * This value is used to map progress events with the request, in the case multiple downloads are ongoing.
   */
  id!: string;

  /**
   * This is the list of file(s) to generate a download url for. If multiple are provided, the backend will generate a zip of the files.
   */
  files!: DataStoreFile[];
  /**
   * This is the user provided access reason for these file(s).
   */
  accessReason!: string;
  /**
   * Whether or not to remove compression from the files before downloading.
   */
  removeCompression?: boolean;
}

/**
 * Progress update for a bulk download operation.
 * The id is generated by the client and used to track the bulk download request.
 */
export class DownloadProgress extends TDMSBase {
  /**
   * The id of the download operation that is being updated.
   */
  id!: string;
  /**
   * The current progress (from 0 to 1).
   */
  progress!: number;
}

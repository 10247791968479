import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { SessionDomain } from "@tdms/common";
import { SessionDomainService } from "@tdms/frontend/modules/session-domain/services/session.domain.service";
import { selectAllDomainsFromState } from "@tdms/frontend/modules/session-domain/store/selector";
import { DomainState } from "@tdms/frontend/modules/session-domain/store/state";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { forbiddenValuesValidator } from "@tdms/frontend/modules/shared/validator";

/** Properties for the session domain dialog */
export interface DomainInfoDialogProperties {
  /** If given, the dialog will act as an edit. Else it will act as a new domain creation. */
  selectedDomain?: SessionDomain;
  /** Called after this dialog is closed successfully from an edit or a creation. If the domain was deleted, this will call with undefined. */
  callback?: { (domain?: SessionDomain): void };
}

/** This component provides you the ability to create/edit session domains */
@Component({
  selector: "session-domain-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class DomainInfoComponent extends SubscribingComponent {
  /** All currently available domains from the store */
  domains: SessionDomain[] = [];
  /** All currently available domains **names** from the store */
  domainNames: string[] = [];
  /** Form for controlling inputs within the domain info component */
  readonly form = new FormGroup({
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private store: Store<DomainState>,
    private domainService: SessionDomainService,
    @Inject(MAT_DIALOG_DATA) public properties: DomainInfoDialogProperties,
    public dialogRef: MatDialogRef<DomainInfoComponent>
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllDomainsFromState).subscribe((x) => {
        this.domains = x;
        this.domainNames = this.domains.map((x) => x.name);
        this.form.controls.name.setValidators([Validators.required, forbiddenValuesValidator(this.domainNames)]);
      })
    );
    if (this.properties.selectedDomain) this.form.controls.name.setValue(this.properties.selectedDomain.name);
  }

  /** Fires the completion capability, assuming the form is valid */
  async complete() {
    if (this.form.valid) {
      const name = this.form.controls.name.value!;
      let domain: SessionDomain;
      if (this.properties.selectedDomain == null) domain = await this.domainService.create(name);
      else {
        domain = this.properties.selectedDomain.clone();
        domain.name = name;
        domain = await this.domainService.update(domain);
      }
      this.dialogRef.close(domain);
      this.properties.callback?.call(this, domain);
    }
  }

  /** Returns if this domain can be deleted in edit mode */
  get canDelete() {
    return this.properties.selectedDomain?.name !== SessionDomain.defaults.medical.name;
  }

  /** Requests this domain to be deleted */
  delete() {
    this.domainService.openDomainDeleteDialog(this.properties.selectedDomain!, () => {
      this.dialogRef.close(this.properties.selectedDomain!);
      this.properties.callback?.call(this);
    });
  }
}

<div class="upload-step-container mat-card">
  <!-- A button to go back one step -->
  <button
    mat-flat-button
    class="back"
    color="accent"
    (click)="prevStep()"
    [disabled]="currentStep === 0 || !(steps[currentStep].allowPrevious ?? true)"
  >
    <mat-icon>arrow_back</mat-icon>
    <span class="text">Previous Step</span>
    <span></span>
  </button>

  <div class="stepper">
    <!-- Iterate over each step -->
    <div
      class="step-container"
      *ngFor="let step of steps; index as i"
      [ngClass]="{ 'with-trailing': i !== steps.length - 1 }"
    >
      <!-- Render the actual step -->
      <div class="step" [ngClass]="{ 'in-progress': i === currentStep, complete: currentStep > i }">
        <mat-icon class="icon">{{ step.icon }}</mat-icon>
        <div class="info">
          <div class="position">Step {{ i + 1 }}</div>
          <div class="title">{{ step.name }}</div>
          <div class="state">
            <!-- These are split up like this so the hidden display can be hidden but still allow us to consume the space to prevent shifting during steps -->
            <span *ngIf="i === currentStep">In Progress</span>
            <span *ngIf="currentStep > i">{{ step.completeText ?? "Complete" }}</span>
            <span *ngIf="currentStep < i" [ngStyle]="{ visibility: 'hidden' }">A</span>
          </div>
        </div>
      </div>

      <!-- Render the connecting line if applicable -->
      <div *ngIf="i !== steps.length - 1" class="trailing-line"></div>
    </div>
  </div>

  <!-- Render content for the stepper as given -->
  <ng-content></ng-content>
</div>
